var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import sharedMessages from 'translations/shared';
import styles from '../index.module.scss';
var Empty = function () {
    var formatMessage = useIntl().formatMessage;
    return (_jsx("div", __assign({ className: styles.entryRoot }, { children: _jsx("div", __assign({ className: styles.attachmentDetails }, { children: _jsx(Typography, { children: formatMessage(sharedMessages.invalidFile) }, void 0) }), void 0) }), void 0));
};
export default Empty;
