var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import sharedMessages from 'translations/shared';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import { useDrawer } from 'context/DrawerContext';
import editAppointmentMutation from './editAppointment.graphql';
import createAddressMutation from './createAddress.graphql';
import editAddressMutation from './editAddress.graphql';
import query from './query.graphql';
import View from './View';
var Container = function (props) {
    var onError = props.onError, onSuccess = props.onSuccess, id = props.id;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var closeDrawer = useDrawer().closeDrawer;
    var queryResponse = useQuery(query, {
        variables: {
            input: {
                id: id,
            },
            locationInput: {
                limit: 1000,
                offset: 0,
            },
        },
    });
    var _a = __read(useMutation(editAppointmentMutation, {
        onCompleted: function (data) {
            if (onSuccess)
                onSuccess(data.updateAppointment);
            track(SegmentTrackableEnum.APPOINTMENT_EDITED, __assign({}, props));
            enqueueSnackbar(formatMessage(sharedMessages.updatedAppointment), {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            if (onError)
                onError(error);
        },
    }), 2), updateAppointment = _a[0], updateAppointmentResult = _a[1];
    var _b = __read(useMutation(createAddressMutation, {
        onError: function (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            if (onError)
                onError(error);
        },
    }), 2), createCustomLocation = _b[0], createLocationResult = _b[1];
    var _c = __read(useMutation(editAddressMutation, {
        onError: function (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            if (onError)
                onError(error);
        },
    }), 2), updateCustomLocation = _c[0], updateLocationResult = _c[1];
    var onUpdate = function (input, inputLocationCreate, inputLocationUpdate) { return __awaiter(void 0, void 0, void 0, function () {
        var variables, appointment, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = __rest(input, []);
                    appointment = get(queryResponse, 'data.getAppointmentById');
                    if (!(input.isCustomLocation && !appointment.isCustomLocation)) return [3, 2];
                    return [4, createCustomLocation({
                            variables: {
                                input: inputLocationCreate,
                            },
                        })];
                case 1:
                    result = _a.sent();
                    variables.locationId = result.data.createAddress.id;
                    _a.label = 2;
                case 2:
                    if (!(input.isCustomLocation && appointment.isCustomLocation)) return [3, 4];
                    return [4, updateCustomLocation({
                            variables: {
                                input: inputLocationUpdate,
                            },
                        })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4, updateAppointment({
                        variables: {
                            input: variables,
                        },
                    })];
                case 5:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    return (_jsx(View, { employees: get(queryResponse, 'data.getEmployees.items', []), clients: get(queryResponse, 'data.getClients.items', []), pets: get(queryResponse, 'data.getPets.items', []), services: get(queryResponse, 'data.getServices.edges', []).map(function (_a) {
            var node = _a.node;
            return node;
        }), locations: get(queryResponse, 'data.getLocations.items', []), error: queryResponse.error, isProcessing: updateAppointmentResult.loading ||
            createLocationResult.loading ||
            updateLocationResult.loading, isLoading: queryResponse.loading, onUpdate: onUpdate, appointment: get(queryResponse, 'data.getAppointmentById') }, void 0));
};
export default Container;
