var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import EscapeOutside from 'react-escape-outside';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import messages from 'translations/shared';
import { Icon, Avatar, Typography } from '@petsly/shared-client';
import { useUser } from 'context/AuthContext';
var View = function () {
    var _a, _b, _c, _d, _e;
    var _f = __read(useState(false), 2), open = _f[0], setOpen = _f[1];
    var formatMessage = useIntl().formatMessage;
    var _g = useUser(), signOut = _g.signOut, userTenant = _g.userTenant, user = _g.user;
    var workspace = userTenant === null || userTenant === void 0 ? void 0 : userTenant.tenant;
    var role = (_a = userTenant === null || userTenant === void 0 ? void 0 : userTenant.role) !== null && _a !== void 0 ? _a : 'STAFF';
    return (_jsx(EscapeOutside, __assign({ onEscapeOutside: function () { return setOpen(false); } }, { children: _jsx(Root, { children: _jsxs(Wrapper, { children: [_jsxs(Button, __assign({ onClick: function () { return setOpen(!open); }, "aria-expanded": open }, { children: [_jsx(StyledAvatar, { photo: (_b = userTenant === null || userTenant === void 0 ? void 0 : userTenant.image) !== null && _b !== void 0 ? _b : undefined, name: (_c = userTenant === null || userTenant === void 0 ? void 0 : userTenant.name) !== null && _c !== void 0 ? _c : 'John Doe', onlyAvatar: true, size: "small" }, void 0), _jsx(Icon, { size: 12, name: "arrow-down" }, void 0)] }), void 0), open && (_jsx(Dialog, { children: _jsxs(DialogContent, { children: [_jsxs(UserSection, { children: [_jsx(Avatar, { photo: (_d = userTenant === null || userTenant === void 0 ? void 0 : userTenant.image) !== null && _d !== void 0 ? _d : undefined, name: (_e = userTenant === null || userTenant === void 0 ? void 0 : userTenant.name) !== null && _e !== void 0 ? _e : 'John Doe', onlyAvatar: true, size: "large" }, void 0), _jsx(Typography, __assign({ size: "h200" }, { children: userTenant === null || userTenant === void 0 ? void 0 : userTenant.name }), void 0), _jsx(Typography, __assign({ size: "bodyMedium", variant: "secondary" }, { children: user === null || user === void 0 ? void 0 : user.email }), void 0), _jsx(StyledLink, __assign({ to: "/account", onClick: function () { return setOpen(!open); } }, { children: formatMessage(messages.profile) }), void 0)] }, void 0), _jsx(Divider, {}, void 0), _jsxs(WorkspaceSection, { children: [_jsx(Typography, __assign({ size: "bodyMedium", variant: "secondary" }, { children: formatMessage(messages.selectedWorkspace, {
                                                name: workspace === null || workspace === void 0 ? void 0 : workspace.name,
                                            }) }), void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "secondary" }, { children: formatMessage(messages.freePlan) }), void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "secondary" }, { children: formatMessage(messages["TENANT_ROLE_" + role]) }), void 0), _jsx(StyledLink, __assign({ to: "/choose-account" }, { children: formatMessage(messages.changeWorkspace) }), void 0)] }, void 0), _jsx(Divider, {}, void 0), _jsx(MenuSection, { children: _jsxs(MenuItem, __assign({ to: "/account/preferences", onClick: function () { return setOpen(false); } }, { children: [_jsx(Icon, { size: 20, name: "globe" }, void 0), _jsx(Typography, { children: formatMessage(messages.language) }, void 0)] }), void 0) }, void 0), _jsx(Divider, {}, void 0), _jsx(MenuSection, { children: _jsxs(MenuItem, __assign({ to: "#", onClick: function () {
                                            track(SegmentTrackableEnum.SIGNED_OUT);
                                            signOut();
                                            setOpen(false);
                                        } }, { children: [_jsx(Icon, { size: 20, name: "turn-off" }, void 0), _jsx(Typography, { children: formatMessage(messages.signOut) }, void 0)] }), void 0) }, void 0)] }, void 0) }, void 0))] }, void 0) }, void 0) }), void 0));
};
export default View;
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Button = styled.div.attrs({
    role: 'button',
    tabindex: 0,
    'aria-haspopup': true,
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  svg {\n    margin-left: 8px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n\n  svg {\n    margin-left: 8px;\n  }\n"])));
var StyledAvatar = styled(Avatar)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  box-shadow: 0 0 0 2px #ebebeb;\n"], ["\n  width: 32px;\n  height: 32px;\n  box-shadow: 0 0 0 2px #ebebeb;\n"])));
var Dialog = styled.div.attrs({
    role: 'dialog',
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  z-index: 10;\n  min-height: 40px;\n  min-width: 200px;\n  box-shadow: rgb(67 90 111 / 30%) 0px 0px 1px,\n    rgb(67 90 111 / 47%) 0px 8px 10px -4px;\n  border-radius: 4px;\n  overflow-x: hidden;\n  overflow-y: hidden;\n  background-color: ", ";\n  position: absolute;\n  right: 8px;\n"], ["\n  z-index: 10;\n  min-height: 40px;\n  min-width: 200px;\n  box-shadow: rgb(67 90 111 / 30%) 0px 0px 1px,\n    rgb(67 90 111 / 47%) 0px 8px 10px -4px;\n  border-radius: 4px;\n  overflow-x: hidden;\n  overflow-y: hidden;\n  background-color: ", ";\n  position: absolute;\n  right: 8px;\n"])), function (props) { return props.theme.palette.white100; });
var DialogContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 280px;\n"], ["\n  width: 280px;\n"])));
var UserSection = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 24px 16px;\n  background-color: ", ";\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"], ["\n  padding: 24px 16px;\n  background-color: ", ";\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n"])), function (props) { return props.theme.palette.primary0; });
var WorkspaceSection = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 16px;\n"], ["\n  padding: 16px;\n"])));
var Divider = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n"], ["\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.palette.gray05; });
var MenuSection = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding-top: 8px;\n  padding-bottom: 8px;\n"], ["\n  padding-top: 8px;\n  padding-bottom: 8px;\n"])));
var MenuItem = styled(Link)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding-left: 16px;\n  padding-right: 16px;\n  outline: none;\n  text-decoration: none;\n  height: 40px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  svg {\n    margin-right: 8px;\n  }\n"], ["\n  padding-left: 16px;\n  padding-right: 16px;\n  outline: none;\n  text-decoration: none;\n  height: 40px;\n  position: relative;\n  display: flex;\n  align-items: center;\n  background-color: ", ";\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  svg {\n    margin-right: 8px;\n  }\n"])), function (props) { return props.theme.palette.white100; }, function (props) { return props.theme.palette.primary0; });
var StyledLink = styled(Link)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: none;\n  font-size: 12px;\n  line-height: 16px;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  text-decoration: none;\n  font-size: 12px;\n  line-height: 16px;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.palette.primary50; }, function (props) { return props.theme.palette.primary100; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
