var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState, useContext, createContext } from 'react';
var DrawerContext = createContext({
    currentDrawers: [],
    openDrawer: function () { },
    closeDrawer: function () { },
});
var DrawerConsumer = DrawerContext.Consumer;
var DrawerProvider = function (props) {
    var _a = __read(useState([]), 2), currentDrawers = _a[0], setCurrentDrawes = _a[1];
    console.log('DrawerProvider', currentDrawers);
    function openDrawer(drawerType, drawerProps) {
        setCurrentDrawes(function (state) {
            return state.concat({
                drawerType: drawerType,
                drawerProps: drawerProps,
            });
        });
    }
    function closeDrawer() {
        setCurrentDrawes(function (currentDrawers) {
            var updatedDrawers = currentDrawers.slice();
            updatedDrawers.pop();
            return updatedDrawers;
        });
    }
    var values = useMemo(function () { return ({ currentDrawers: currentDrawers, openDrawer: openDrawer, closeDrawer: closeDrawer }); }, [currentDrawers, openDrawer, closeDrawer]);
    return (_jsx(DrawerContext.Provider, __assign({ value: values }, { children: props.children }), void 0));
};
var useDrawer = function () {
    var context = useContext(DrawerContext);
    if (context === undefined) {
        throw new Error('useDrawer must be used within an DrawerProvider');
    }
    return context;
};
export { DrawerProvider, DrawerConsumer, useDrawer };
