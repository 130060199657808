var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from '../index.module.scss';
pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/" + pdfjs.version + "/pdf.worker.js";
var PDFViewer = function (_a) {
    var url = _a.url;
    var _b = __read(useState(null), 2), setNumPages = _b[1];
    var _c = __read(useState(1), 1), pageNumber = _c[0];
    var onDocumentLoadSuccess = function (_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    };
    return (_jsx("div", __assign({ className: styles.entryRoot }, { children: _jsx(Document, __assign({ file: url, onLoadSuccess: onDocumentLoadSuccess, onLoadError: console.error }, { children: _jsx(Page, { pageNumber: pageNumber }, void 0) }), void 0) }), void 0));
};
export default PDFViewer;
