var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState, useMemo } from 'react';
import Carousel from 'react-material-ui-carousel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from 'components/Dialog/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import useModal from 'hooks/useModal';
import { images, video } from './extensions';
import Image from './components/Image';
import Video from './components/Video';
import PDFViewer from './components/PDFViewer';
import Empty from './components/Empty';
var View = function (_a) {
    var attachmentsLinks = _a.attachmentsLinks, itemIndex = _a.itemIndex;
    var _b = __read(useState(itemIndex), 1), index = _b[0];
    var closeModal = useModal().closeModal;
    var anchorRef = useRef(null);
    var AttachmentsComponents = useMemo(function () {
        return attachmentsLinks.map(function (attachment, index) {
            if (images.includes(attachment.extension))
                return (_jsx(Image, { url: attachment.url }, index + "-" + attachment.url));
            if (attachment.extension === 'pdf')
                return (_jsx(PDFViewer, { url: attachment.url }, index + "-" + attachment.url));
            if (video.includes(attachment.extension)) {
                return (_jsx(Video, { extension: attachment.extension, url: attachment.url }, index + "-" + attachment.url));
            }
            return _jsx(Empty, {}, void 0);
        });
    }, [attachmentsLinks, itemIndex]);
    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ fullScreen: true, open: true, onClose: closeModal }, { children: [_jsx(DialogTitle, __assign({ onClose: closeModal }, { children: _jsx(ButtonGroup, { variant: "contained", color: "primary", ref: anchorRef, "aria-label": "split button" }, void 0) }), void 0), _jsx(DialogContent, { children: _jsx(Carousel, __assign({ index: index, autoPlay: false, navButtonsAlwaysVisible: true }, { children: AttachmentsComponents }), void 0) }, void 0)] }), void 0) }, void 0));
};
export default View;
