var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useReducer, useMemo } from 'react';
var initialState = {
    user: undefined,
    userPreference: {
        language: navigator.language.split(/[-_]/)[0].toUpperCase(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    layout: {
        isMobile: false,
        toggled: false,
        fluidContainer: false,
    },
    header: {
        title: '',
        Toolbar: null,
    },
    tenant: {
        plan: null,
    },
    currentModals: [],
};
export var GlobalContext = createContext(initialState);
function reducer(state, action) {
    switch (action.type) {
        case 'RESET':
            return __assign({}, initialState);
        case 'MODAL_OPEN': {
            return __assign(__assign({}, state), { currentModals: state.currentModals.concat({
                    modalType: action.payload.modalType,
                    modalProps: action.payload.modalProps,
                }) });
        }
        case 'MODAL_CLOSE': {
            var newState = __assign({}, state);
            var currentModals = state.currentModals.slice();
            currentModals.pop();
            newState.currentModals = currentModals;
            return newState;
        }
        case 'SET_USER': {
            return __assign(__assign({}, state), { user: action.payload });
        }
        case 'SET_USER_LANGUAGE': {
            return __assign(__assign({}, state), { userPreference: __assign(__assign({}, state.userPreference), { language: action.payload }) });
        }
        case 'SET_USER_TIMEZONE': {
            return __assign(__assign({}, state), { userPreference: __assign(__assign({}, state.userPreference), { timezone: action.payload }) });
        }
        case 'TOGGLE_SIDEBAR': {
            return __assign(__assign({}, state), { layout: __assign(__assign({}, state.layout), { toggled: !state.layout.toggled }) });
        }
        case 'SET_MOBILE': {
            return __assign(__assign({}, state), { layout: __assign(__assign({}, state.layout), { isMobile: action.payload }) });
        }
        case 'SET_FLUID_CONTAINER': {
            return __assign(__assign({}, state), { layout: __assign(__assign({}, state.layout), { fluidContainer: action.payload }) });
        }
        case 'SET_HEADER': {
            return __assign(__assign({}, state), { header: action.payload });
        }
        case 'SET_HEADER_TOOLBAR': {
            return __assign(__assign({}, state), { header: __assign(__assign({}, state.header), { title: action.Toolbar }) });
        }
        case 'CLEAR_USER': {
            return __assign(__assign({}, state), { user: undefined });
        }
        case 'SET_TENANT_PLAN': {
            return __assign(__assign({}, state), { tenant: __assign(__assign({}, state.tenant), { plan: action.payload }) });
        }
        default:
            return state;
    }
}
export default function RenderGlobalContext(props) {
    var _a = __read(useReducer(reducer, initialState), 2), state = _a[0], dispatch = _a[1];
    var children = props.children;
    var contextValue = useMemo(function () { return ({
        state: state,
        dispatch: dispatch,
    }); }, [state]);
    return (_jsx(GlobalContext.Provider, __assign({ value: contextValue }, { children: children }), void 0));
}
