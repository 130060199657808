var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, FormHelperText, } from '@petsly/shared-client';
import * as yup from 'yup';
import { useFormik } from 'formik';
import isNil from 'lodash/isNil';
import { addDays, parseISO } from 'date-fns';
import { useDrawer } from 'context/DrawerContext';
import { InvoiceStatusEnum, } from 'types/graphql';
import sharedMessages from 'translations/shared';
import displayAddress from 'utils/displayAddress';
import InvoiceNumberField from './components/InvoiceNumber';
import ClientField from './components/Client';
import RecipientDetailsField from './components/RecipientDetails';
import StatusField from './components/Status';
import DatesField from './components/Dates';
import ItemsField from './components/Items';
import Summary from './components/Summary';
var getRecipient = function (client) {
    var _a;
    var recipient = "" + client.name;
    if (((_a = displayAddress(client.address)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        recipient += "\n" + displayAddress(client.address);
    }
    if (client.taxId) {
        recipient += "\nVAT: " + client.taxId;
    }
    return recipient;
};
var View = function (_a) {
    var client = _a.client, clients = _a.clients, services = _a.services, taxRates = _a.taxRates, invoice = _a.invoice, isLoading = _a.isLoading, isProcessing = _a.isProcessing, error = _a.error, onUpdate = _a.onUpdate;
    var formatMessage = useIntl().formatMessage;
    var closeDrawer = useDrawer().closeDrawer;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            invoiceNumber: yup
                .string()
                .required(formatMessage(sharedMessages.required)),
            clientId: yup.string().optional().nullable(),
            recipient: yup
                .string()
                .required(formatMessage(sharedMessages.required)),
            issueDate: yup.date().required(formatMessage(sharedMessages.required)),
            saleDate: yup.date().required(formatMessage(sharedMessages.required)),
            paymentDate: yup
                .date()
                .required(formatMessage(sharedMessages.required)),
            status: yup
                .mixed()
                .oneOf(Object.values(InvoiceStatusEnum))
                .required(formatMessage(sharedMessages.required)),
            vatPercentage: yup
                .string()
                .required(formatMessage(sharedMessages.required)),
            items: yup
                .array()
                .of(yup.object().shape({
                quantity: yup
                    .number()
                    .min(1)
                    .required(formatMessage(sharedMessages.required)),
                serviceId: yup
                    .string()
                    .required(formatMessage(sharedMessages.required)),
            }))
                .min(1, formatMessage(sharedMessages.minItems, { count: 1 }))
                .required(formatMessage(sharedMessages.required)),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            invoiceNumber: '',
            clientId: '',
            recipient: '',
            status: InvoiceStatusEnum.Paid,
            items: [],
            issueDate: new Date(),
            saleDate: new Date(),
            paymentDate: addDays(new Date(), 14),
            vatPercentage: '',
        },
        onSubmit: function (values) {
            var _a, _b;
            onUpdate({
                invoiceId: invoice.id,
                invoiceNumber: values.invoiceNumber,
                recipient: values.recipient,
                issueDate: values.issueDate,
                paymentDate: values.paymentDate,
                saleDate: values.saleDate,
                status: values.status,
                vatPercentage: (_b = (_a = taxRates.find(function (t) { return t.id === values.vatPercentage; })) === null || _a === void 0 ? void 0 : _a.percentage) !== null && _b !== void 0 ? _b : 0,
                items: values.items.map(function (i) {
                    var _a, _b, _c, _d;
                    return ({
                        unitPrice: (_b = (_a = services.find(function (s) { return s.id === i.serviceId; })) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : 0,
                        quantity: Number(i.quantity),
                        name: (_d = (_c = services.find(function (s) { return s.id === i.serviceId; })) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
                    });
                }),
            });
        },
    });
    var submitForm = formik.submitForm, setValues = formik.setValues, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched, touched = formik.touched, errors = formik.errors, values = formik.values;
    useEffect(function () {
        var _a, _b, _c;
        if (!invoice)
            return;
        var varPercentage = taxRates.find(function (t) { return t.percentage === invoice.vatPercentage; });
        var items = invoice.items.map(function (i) {
            var _a, _b;
            return {
                serviceId: (_b = (_a = services.find(function (s) { return s.name === i.name; })) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                quantity: i.quantity,
            };
        });
        setValues({
            items: items,
            invoiceNumber: invoice.invoiceNumber,
            clientId: (_b = (_a = invoice.client) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
            recipient: invoice.recipient,
            status: invoice.status,
            issueDate: parseISO(invoice.issuedAt),
            saleDate: parseISO(invoice.soldAt),
            paymentDate: parseISO(invoice.dueAt),
            vatPercentage: (_c = varPercentage === null || varPercentage === void 0 ? void 0 : varPercentage.id) !== null && _c !== void 0 ? _c : '',
        });
    }, [invoice, taxRates, services, setFieldValue]);
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.invoice) }), void 0), _jsxs(DrawerContent, { children: [_jsx(InvoiceNumberField, { value: values.invoiceNumber, error: errors.invoiceNumber, touched: touched.invoiceNumber, onChange: function (value) { return setFieldValue('invoiceNumber', value); }, onBlur: function () { return setFieldTouched('invoiceNumber'); } }, void 0), _jsx(ClientField, { clients: clients, value: values.clientId, error: errors.clientId, touched: touched.clientId, onChange: function (value) {
                            setFieldValue('clientId', value);
                            if (value) {
                                var client_1 = clients.find(function (c) { return c.id === value; });
                                setFieldValue('recipient', getRecipient(client_1));
                            }
                        }, onBlur: function () { return setFieldTouched('clientId'); } }, void 0), _jsx(RecipientDetailsField, { value: values.recipient, error: errors.recipient, touched: touched.recipient, onChange: function (value) {
                            setFieldValue('recipient', value);
                        }, onBlur: function () { return setFieldTouched('recipient'); } }, void 0), _jsx(StatusField, { value: values.status, error: errors.status, touched: touched.status, onChange: function (value) {
                            setFieldValue('status', value);
                        }, onBlur: function () { return setFieldTouched('status'); } }, void 0), _jsx(DatesField, { values: {
                            issueDate: values.issueDate,
                            saleDate: values.saleDate,
                            paymentDate: values.paymentDate,
                        }, errors: {
                            issueDate: errors.issueDate,
                            saleDate: errors.saleDate,
                            paymentDate: errors.paymentDate,
                        }, touched: {
                            issueDate: touched.issueDate,
                            saleDate: touched.saleDate,
                            paymentDate: touched.paymentDate,
                        }, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); } }, void 0), _jsx(ItemsField, { services: services, values: values.items, errors: errors.items, touched: touched.items, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); }, push: function () {
                            return setFieldValue('items', __spreadArray(__spreadArray([], __read(values.items), false), [
                                { serviceId: '', quantity: 1 },
                            ], false));
                        }, remove: function (idx) {
                            setFieldValue('items', values.items.filter(function (_, index) { return index !== idx; }));
                        } }, void 0), touched.items &&
                        !isNil(errors.items) &&
                        typeof errors.items === 'string' && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.items }), void 0)), _jsx(Summary, { value: values.vatPercentage, touched: touched.vatPercentage, error: errors.vatPercentage, onChange: function (value) { return setFieldValue('vatPercentage', value); }, onBlur: function () { return setFieldTouched('vatPercentage'); }, taxRates: taxRates, services: services, items: values.items }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: formatMessage(sharedMessages.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
