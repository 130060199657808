var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import format from 'helpers/format';
import { useDrawer } from 'context/DrawerContext';
import DrawerTypeEnum from 'types/enums/DrawerTypeEnum';
import { Drawer, DrawerHeader, DrawerContent, FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormStateButton, } from '@petsly/shared-client';
import styled from 'styled-components';
import translations from 'translations/shared';
var View = function (_a) {
    var historicNotes = _a.historicNotes;
    var formatMessage = useIntl().formatMessage;
    var openDrawer = useDrawer().openDrawer;
    var closeDrawer = useDrawer().closeDrawer;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(translations.historicNotes) }), void 0), _jsx(DrawerContent, { children: historicNotes === null || historicNotes === void 0 ? void 0 : historicNotes.map(function (item) { return (_jsxs(FormGroup, { children: [_jsxs(FormGroupHeader, { children: [_jsx(FormSubHeading, { children: format(item.appointment.startAt, 'P') }, void 0), _jsx(FormStateButton, __assign({ onClick: function () {
                                        openDrawer(DrawerTypeEnum.APPOINTMENT, {
                                            appointmentId: item.appointment.id,
                                        });
                                    } }, { children: formatMessage(translations.appointment) }), void 0)] }, void 0), _jsx(FormGroupContentBreak, { children: item.notes }, void 0)] }, void 0)); }) }, void 0)] }), void 0));
};
export default View;
var FormGroupContentBreak = styled(FormGroupContent)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  white-space: pre-line;\n"], ["\n  white-space: pre-line;\n"])));
var templateObject_1;
