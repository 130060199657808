var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styles from '../index.module.scss';
var Image = function (_a) {
    var url = _a.url;
    return (_jsx("div", __assign({ className: styles.entryRoot }, { children: _jsx(TransformWrapper, __assign({ defaultScale: 1, defaultPositionX: 200, defaultPositionY: 100 }, { children: function (_a) {
                var zoomIn = _a.zoomIn, zoomOut = _a.zoomOut, resetTransform = _a.resetTransform, rest = __rest(_a, ["zoomIn", "zoomOut", "resetTransform"]);
                return (_jsx(_Fragment, { children: _jsx(TransformComponent, { children: _jsx("img", { className: styles.attachmentDetails, src: url }, void 0) }, void 0) }, void 0));
            } }), void 0) }), void 0));
};
export default Image;
