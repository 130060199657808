var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ModalManager from 'components/Modals';
import DrawerManager from 'components/Drawers';
import ScrollToTop from 'components/ScrollToTop';
import App from './components/App';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        success: {
            backgroundColor: theme.palette.success.main + " !important",
            fontWeight: 700,
        },
        error: {
            backgroundColor: theme.palette.error.main + " !important",
            fontWeight: 700,
        },
        info: {
            backgroundColor: theme.palette.info.main + " !important",
            fontWeight: 700,
        },
        warning: {
            backgroundColor: theme.palette.warning.main + " !important",
            fontWeight: 700,
        },
    });
});
export default (function () {
    var classes = useStyles();
    return (_jsxs(SnackbarProvider, __assign({ anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }, autoHideDuration: 3000, classes: {
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
        } }, { children: [_jsx(App, {}, void 0), _jsx(ModalManager, {}, void 0), _jsx(DrawerManager, {}, void 0), _jsx(ScrollToTop, {}, void 0)] }), void 0));
});
