var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import Select from 'components/Select';
var Option = function (props) {
    return (_jsx("div", { children: _jsx(components.Option, __assign({}, props, { children: _jsx("label", { children: props.label }, void 0) }), void 0) }, void 0));
};
var animatedComponents = makeAnimated();
var View = function (_a) {
    var options = _a.options, onUpdate = _a.onUpdate, props = __rest(_a, ["options", "onUpdate"]);
    var _b = __read(useState(props.selectedOptions), 2), optionSelected = _b[0], setOptionSelected = _b[1];
    var handleChange = function (selected) {
        setOptionSelected(selected);
        onUpdate(selected);
    };
    return (_jsx("div", __assign({ style: {
            width: '100%',
        } }, { children: _jsx(Select, __assign({ options: options, isMulti: true, closeMenuOnSelect: false, hideSelectedOptions: false, components: {
                Option: Option,
                animatedComponents: animatedComponents,
            }, onChange: handleChange, value: optionSelected }, props), void 0) }), void 0));
};
export default View;
