var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import NameField from './components/NameField';
var View = function (_a) {
    var isProcessing = _a.isProcessing, onCreate = _a.onCreate, error = _a.error;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            name: yup.string().required(formatMessage(sharedMessages.required)),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        isInitialValid: true,
        initialValues: {
            name: '',
        },
        onSubmit: function (values) {
            onCreate({
                name: values.name,
            });
        },
    });
    var submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.addServiceCategory) }), void 0), _jsx(DrawerContent, { children: _jsx(NameField, { value: values.name, error: errors.name, touched: touched.name, onChange: function (value) { return setFieldValue('name', value); }, onBlur: function () { return setFieldTouched('name'); } }, void 0) }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: isProcessing
                        ? formatMessage(sharedMessages.loading)
                        : formatMessage(sharedMessages.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
