var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import DisplayNameField from './components/DisplayNameField';
import DescriptionField from './components/DescriptionField';
import IsActiveField from './components/IsActiveField';
import PercentageField from './components/PercentageField';
var View = function (_a) {
    var isProcessing = _a.isProcessing, onCreate = _a.onCreate, error = _a.error;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            displayName: yup
                .string()
                .required(formatMessage(sharedMessages.required)),
            description: yup.string().nullable(),
            isActive: yup.boolean(),
            percentage: yup.number().max(100).nullable(),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        isInitialValid: true,
        initialValues: {
            displayName: '',
            description: null,
            isActive: false,
            percentage: null,
        },
        onSubmit: function (values) {
            var _a;
            onCreate({
                displayName: values.displayName,
                description: (_a = values.description) !== null && _a !== void 0 ? _a : null,
                percentage: Number(values.percentage),
                isActive: values.isActive,
            });
        },
    });
    var submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.addTaxRate) }), void 0), _jsxs(DrawerContent, { children: [_jsx(DisplayNameField, { value: values.displayName, error: errors.displayName, touched: touched.displayName, onChange: function (value) { return setFieldValue('displayName', value); }, onBlur: function () { return setFieldTouched('displayName'); } }, void 0), _jsx(DescriptionField, { value: values.description, error: errors.description, touched: touched.description, onChange: function (value) { return setFieldValue('description', value); }, onBlur: function () { return setFieldTouched('description'); } }, void 0), _jsx(IsActiveField, { value: values.isActive, error: errors.isActive, touched: touched.isActive, onChange: function (value) { return setFieldValue('isActive', value); } }, void 0), _jsx(PercentageField, { value: values.percentage, error: errors.percentage, touched: touched.percentage, onChange: function (value) { return setFieldValue('percentage', value); }, onBlur: function () { return setFieldTouched('percentage'); } }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: isProcessing
                        ? formatMessage(sharedMessages.loading)
                        : formatMessage(sharedMessages.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
