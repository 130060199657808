var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useDrawer } from 'context/DrawerContext';
import { useUser } from 'context/AuthContext';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import SendMessageMutation from './sendMessage.graphql';
import View from './View';
var Container = function (props) {
    var _a;
    var onError = props.onError, onSuccess = props.onSuccess, clients = props.clients;
    var closeDrawer = useDrawer().closeDrawer;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var userTenant = useUser().userTenant;
    var workspace = (_a = userTenant === null || userTenant === void 0 ? void 0 : userTenant.tenant) !== null && _a !== void 0 ? _a : undefined;
    var _b = __read(useMutation(SendMessageMutation, {
        onCompleted: function () {
            if (onSuccess)
                onSuccess();
            track(SegmentTrackableEnum.SEND_MESSAGE_SENT);
            enqueueSnackbar('OK', {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            track(SegmentTrackableEnum.SEND_MESSAGE_ERROR);
            if (onError)
                onError(error);
        },
    }), 2), sendMessage = _b[0], _c = _b[1], loading = _c.loading, error = _c.error;
    var onSend = function (input) {
        var _a;
        input.message += " - Wys\u0142ane przez " + ((_a = workspace === null || workspace === void 0 ? void 0 : workspace.name) !== null && _a !== void 0 ? _a : 'Petsly');
        sendMessage({
            variables: {
                input: input,
            },
        });
    };
    return (_jsx(View, { onSend: onSend, isLoading: loading, error: error, clients: clients }, void 0));
};
export default Container;
