var DrawerTypeEnum;
(function (DrawerTypeEnum) {
    DrawerTypeEnum["MANAGE_EMPLOYEE"] = "ManageEmployee";
    DrawerTypeEnum["APPOINTMENT"] = "Appointment";
    DrawerTypeEnum["CREATE_INVOICE"] = "CreateInvoice";
    DrawerTypeEnum["CREATE_SERVICE_CATEGORY"] = "CreateServiceCategory";
    DrawerTypeEnum["EDIT_SERVICE_CATEGORY"] = "EditServiceCategory";
    DrawerTypeEnum["EDIT_INVOICE"] = "EditInvoice";
    DrawerTypeEnum["CREATE_SERVICE"] = "CreateService";
    DrawerTypeEnum["EDIT_SERVICE"] = "EditService";
    DrawerTypeEnum["CREATE_TAX_RATE"] = "CreateTaxRate";
    DrawerTypeEnum["EDIT_TAX_RATE"] = "EditTaxRate";
    DrawerTypeEnum["CREATE_APPOINTMENT"] = "CreateAppointment";
    DrawerTypeEnum["EDIT_APPOINTMENT"] = "EditAppointment";
    DrawerTypeEnum["CREATE_CLIENT"] = "CreateClient";
    DrawerTypeEnum["CREATE_PET"] = "CreatePet";
    DrawerTypeEnum["RECORD_FIELD"] = "RecordField";
    DrawerTypeEnum["EDIT_WEBINAR"] = "EditWebinar";
    DrawerTypeEnum["CREATE_WEBINAR"] = "CreateWebinar";
    DrawerTypeEnum["SEND_MESSAGE"] = "SendMessage";
    DrawerTypeEnum["QUICK_ADD_CLIENT_AND_PET"] = "QuickAddClientAndPet";
    DrawerTypeEnum["CREATE_LOCATION"] = "CreateLocation";
    DrawerTypeEnum["EDIT_LOCATION"] = "EditLocation";
    DrawerTypeEnum["CREATE_VOUCHER"] = "CreateVoucher";
    DrawerTypeEnum["EDIT_VOUCHER"] = "EditVoucher";
    DrawerTypeEnum["HISTORIC_NOTES"] = "HistoricNotes";
    DrawerTypeEnum["MOBILE_MORE"] = "MobileMore";
    DrawerTypeEnum["CALENDAR_FILTERS"] = "CalendarFilters";
    DrawerTypeEnum["MOBILE_APPOINTMENTS_FILTER"] = "MobileAppointmentsFilter";
    DrawerTypeEnum["MOBILE_CLIENTS_FILTER"] = "MobileClientsFilter";
})(DrawerTypeEnum || (DrawerTypeEnum = {}));
export default DrawerTypeEnum;
