var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import NameField from './components/Name';
import PhoneField from './components/Phone';
import PetField from './components/Pet';
import GDPRField from './components/GDPR';
var View = function (_a) {
    var isProcessing = _a.isProcessing, onCreate = _a.onCreate;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            name: yup.string().required(formatMessage(sharedMessages.required)),
            directional: yup.string().required(),
            phoneNumber: yup.string().nullable(),
            petName: yup.string().required(formatMessage(sharedMessages.required)),
            gdprAgreed: yup
                .boolean()
                .required(formatMessage(sharedMessages.required))
                .oneOf([true], formatMessage(sharedMessages.required)),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            directional: '+48',
            phoneNumber: undefined,
            petName: '',
            gdprAgreed: false,
        },
        onSubmit: function (values) {
            var directional = values.directional, phoneNumber = values.phoneNumber, rest = __rest(values, ["directional", "phoneNumber"]);
            var input = rest;
            if (phoneNumber && phoneNumber.length > 1) {
                input.phoneNumber = directional + " " + phoneNumber;
            }
            onCreate(input);
        },
    });
    var submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.addClient) }), void 0), _jsxs(DrawerContent, { children: [_jsx(NameField, { value: values.name, error: errors.name, touched: touched.name, onChange: function (value) { return setFieldValue('name', value); }, onBlur: function () { return setFieldTouched('name'); } }, void 0), _jsx(PetField, { value: values.petName, error: errors.petName, touched: touched.petName, onChange: function (value) { return setFieldValue('petName', value); }, onBlur: function () { return setFieldTouched('petName'); } }, void 0), _jsx(PhoneField, { directional: values.directional, phoneNumber: values.phoneNumber, error: errors.phoneNumber, touched: touched.phoneNumber, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); } }, void 0), _jsx(GDPRField, { value: values.gdprAgreed, error: errors.gdprAgreed, touched: touched.gdprAgreed, onChange: function (value) { return setFieldValue('gdprAgreed', value); }, onBlur: function () { return setFieldTouched('gdprAgreed'); } }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: isProcessing
                        ? formatMessage(sharedMessages.loading)
                        : formatMessage(sharedMessages.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
