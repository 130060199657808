var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import sharedMessages from 'translations/shared';
import { useDrawer } from 'context/DrawerContext';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, } from '@petsly/shared-client';
import PetsField from './components/Pets';
import QueryField from './components/Query';
var View = function (_a) {
    var _b, _c;
    var filter = _a.filter, onFilterUpdate = _a.onFilterUpdate, pets = _a.pets, messages = _a.messages;
    var closeDrawer = useDrawer().closeDrawer;
    var onClose = function () {
        closeDrawer();
    };
    var formatMessage = useIntl().formatMessage;
    var _d = __read(useState({
        petIds: (_b = filter.petIds) !== null && _b !== void 0 ? _b : null,
        query: (_c = filter.query) !== null && _c !== void 0 ? _c : undefined,
    }), 2), filterOptions = _d[0], setFilterOptions = _d[1];
    var changeInput = function (name) { return function (value) {
        var _a;
        setFilterOptions(__assign(__assign({}, filterOptions), (_a = {}, _a[name] = value, _a)));
    }; };
    var saveFilters = function () {
        onFilterUpdate(filterOptions);
        closeDrawer();
    };
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: onClose }, { children: formatMessage(sharedMessages.filters) }), void 0), _jsxs(DrawerContent, { children: [_jsx(QueryField, { changeInput: changeInput('query'), filterOptions: filterOptions, messages: messages }, void 0), _jsx(PetsField, { changeInput: changeInput('petIds'), petOptions: pets, filterOptions: filterOptions }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: saveFilters, fullWidth: true }, { children: formatMessage(sharedMessages.applyFilter) }), void 0) }, void 0)] }), void 0));
};
export default View;
