var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Button, Icon } from '@petsly/shared-client';
import sharedMessages from 'translations/shared';
import { useDrawer } from 'context/DrawerContext';
import DrawerTypeEnum from 'types/enums/DrawerTypeEnum';
import UserMenu from './components/UserMenu';
var View = function () {
    var formatMessage = useIntl().formatMessage;
    var openDrawer = useDrawer().openDrawer;
    return (_jsxs(Root, { children: [_jsx(Left, {}, void 0), _jsx(Middle, {}, void 0), _jsxs(Right, { children: [_jsx(ChatButton, { children: _jsx(Icon, { size: 20, name: "chat" }, void 0) }, void 0), _jsx(Button, __assign({ size: "small", style: { marginRight: 16 }, onClick: function () {
                            openDrawer(DrawerTypeEnum.CREATE_APPOINTMENT, {});
                        } }, { children: formatMessage(sharedMessages.addAppointment) }), void 0), _jsx(UserMenu, {}, void 0)] }, void 0)] }, void 0));
};
View.displayName = 'LargeNavigation';
export default View;
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 8px 40px;\n  background-color: ", ";\n  height: 64px;\n  width: 100%;\n  display: flex;\n  box-shadow: rgb(67 90 111 / 42%) 0px 0px 1px 0px;\n"], ["\n  padding: 8px 40px;\n  background-color: ", ";\n  height: 64px;\n  width: 100%;\n  display: flex;\n  box-shadow: rgb(67 90 111 / 42%) 0px 0px 1px 0px;\n"])), function (props) { return props.theme.palette.primary0; });
var Left = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n"])));
var Middle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n  justify-content: center;\n"])));
var Right = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  flex: 1 10%;\n  align-items: center;\n  justify-content: flex-end;\n"])));
var ChatButton = styled.div.attrs({
    role: 'button',
    className: 'custom-intercom',
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 16px;\n  border-radius: 4px;\n  apperance: none;\n  width: 32px;\n  height: 32px;\n  background-color: transparent;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  margin-right: 16px;\n  border-radius: 4px;\n  apperance: none;\n  width: 32px;\n  height: 32px;\n  background-color: transparent;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.palette.primary05; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
