import isNil from 'lodash/isNil';
export default (function (address) {
    if (!address) {
        return '';
    }
    var line1 = address.line1, line2 = address.line2, city = address.city, postalCode = address.postalCode, state = address.state;
    var addressArr = [];
    if (!isNil(line1) && line1.length)
        addressArr.push(line1);
    if (!isNil(line2) && line2.length)
        addressArr.push(line2);
    if (!isNil(postalCode) && postalCode.length)
        addressArr.push(postalCode);
    if (!isNil(city) && city.length)
        addressArr.push(city);
    if (!isNil(state) && state.length)
        addressArr.push(state);
    return addressArr.join(', ');
});
