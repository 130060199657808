var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { ThemeProvider as MuiThemeProvider, StylesProvider, createGenerateClassName, jssPreset, } from '@material-ui/core/styles';
import { create } from 'jss';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import 'react-dates/initialize';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import { ThemeProvider as PetslyThemeProvider, GlobalCss, } from '@petsly/shared-client';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import RoutedApplication from 'components/Root';
import apolloClient from 'bootstrap/apollo';
import theme from 'themes/theme-default';
import messages_pl from 'translations/pl.json';
import messages_en from 'translations/en.json';
import GlobalContext from 'context/Global';
import { DrawerProvider } from 'context/DrawerContext';
import { WizardProvider } from 'context/WizardContext';
import { UserProvider } from 'context/AuthContext';
import { MobileProvider } from 'context/MobileContext';
import { useLocalStorage } from '@rehooks/local-storage';
import { load } from 'factory/SegmentFactory';
import './App.css';
registerLocale('enUS', enUS);
registerLocale('pl', pl);
if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/pl');
    require('@formatjs/intl-pluralrules/locale-data/en');
}
if (!Intl.RelativeTimeFormat) {
    require('@formatjs/intl-relativetimeformat/polyfill');
    require('@formatjs/intl-relativetimeformat/locale-data/pl');
    require('@formatjs/intl-relativetimeformat/locale-data/en');
}
if (!Intl.NumberFormat) {
    require('@formatjs/intl-numberformat/polyfill');
    require('@formatjs/intl-numberformat/locale-data/pl');
    require('@formatjs/intl-numberformat/locale-data/en');
}
var messages = {
    pl: messages_pl,
    en: messages_en,
};
var generateClassName = createGenerateClassName();
var jss = create(__assign(__assign({}, jssPreset()), { insertionPoint: document.getElementById('jss-insertion-point') }));
var getTimeLocale = function (locale) {
    if (['pl', 'enUS'].includes(locale)) {
        return locale;
    }
    return 'enUS';
};
var getLocale = function (locale) {
    if (locale === void 0) { locale = 'enUS'; }
    if (['pl', 'enUS'].includes(locale)) {
        return locale === 'enUS' ? 'en' : locale;
    }
    return 'en';
};
var App = function () {
    load();
    var _a = __read(useLocalStorage('Petsly.__localeId__', navigator.language.split(/[-_]/)[0]), 1), locale = _a[0];
    var transformedLocale = getLocale(locale);
    window.__localeId__ = getTimeLocale(locale);
    setDefaultLocale(getTimeLocale(locale));
    return (_jsx(Router, { children: _jsx(IntlProvider, __assign({ locale: transformedLocale, messages: messages[transformedLocale] }, { children: _jsx(ApolloProvider, __assign({ client: apolloClient }, { children: _jsx(StylesProvider, __assign({ jss: jss, generateClassName: generateClassName }, { children: _jsx(PetslyThemeProvider, { children: _jsx(MuiThemeProvider, __assign({ theme: theme }, { children: _jsx(UserProvider, { children: _jsx(WizardProvider, { children: _jsx(DrawerProvider, { children: _jsx(MobileProvider, { children: _jsx(_Fragment, { children: _jsxs(GlobalContext, { children: [_jsx(GlobalCss, {}, void 0), _jsx(RoutedApplication, {}, void 0)] }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }), void 0) }, void 0) }), void 0) }), void 0) }), void 0) }, void 0));
};
export default App;
