var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addMinutes from 'date-fns/addMinutes';
import 'react-datepicker/dist/react-datepicker.css';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, Icon, Button, Typography, } from '@petsly/shared-client';
var View = function (_a) {
    var values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur, push = _a.push, remove = _a.remove;
    var formatMessage = useIntl().formatMessage;
    var dayName = useMemo(function () { return ({
        0: formatMessage(messages.monday),
        1: formatMessage(messages.tuesday),
        2: formatMessage(messages.wednesday),
        3: formatMessage(messages.thursday),
        4: formatMessage(messages.friday),
        5: formatMessage(messages.saturday),
        6: formatMessage(messages.sunday),
    }); }, []);
    var renderDay = function (values, day) {
        var dayEntries = values.reduce(function (acc, entry, originalIndex) {
            if (entry.day !== day) {
                return acc;
            }
            return __spreadArray(__spreadArray([], __read(acc), false), [__assign(__assign({}, entry), { originalIndex: originalIndex })], false);
        }, []);
        return (_jsxs(DayEntry, { children: [_jsx(DayName, { children: dayName[day] }, void 0), _jsx(DayTimeSlots, { children: dayEntries.map(function (value, idx, arr) { return (_jsxs(DayTimeSlot, { children: [_jsxs(DayTimeSlotWrapper, { children: [_jsx(DatePickerWrapper, { children: _jsx(StyledDatePicker, { selected: value.start, onChange: function (date) {
                                                return onChange("workingHours[" + value.originalIndex + "].start", date);
                                            }, showPopperArrow: false, onBlur: function () {
                                                return onBlur("workingHours[" + value.originalIndex + "].start");
                                            }, error: touched["workingHours[" + value.originalIndex + "].start"] &&
                                                !isNil(errors["workingHours[" + value.originalIndex + "].start"]), dateFormat: "hh:mm aa", showTimeSelect: true, showTimeSelectOnly: true, timeFormat: "p", timeIntervals: 15, minTime: idx === 0
                                                ? setHours(setMinutes(new Date(), 0), 0)
                                                : arr[idx - 1].end, maxTime: value.end
                                                ? value.end
                                                : setHours(setMinutes(new Date(), 45), 23), placeholer: "09:00" }, void 0) }, void 0), touched["workingHours[" + value.originalIndex + "].start"] &&
                                        !isNil(errors["workingHours[" + value.originalIndex + "].start"]) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors["workingHours[" + value.originalIndex + "].start"] }), void 0))] }, void 0), _jsxs(DayTimeSlotWrapper, { children: [_jsx(DatePickerWrapper, { children: _jsx(StyledDatePicker, { selected: value.end, onChange: function (date) {
                                                return onChange("workingHours[" + value.originalIndex + "].end", date);
                                            }, showPopperArrow: false, onBlur: function () {
                                                return onBlur("workingHours[" + value.originalIndex + "].end");
                                            }, error: touched["workingHours[" + value.originalIndex + "].end"] &&
                                                !isNil(errors["workingHours[" + value.originalIndex + "].end"]), dateFormat: "hh:mm aa", showTimeSelect: true, showTimeSelectOnly: true, timeFormat: "p", timeIntervals: 15, placeholer: "17:00", minTime: addMinutes(new Date(value.start), 15), maxTime: arr[idx + 1]
                                                ? arr[idx + 1].start
                                                : setHours(setMinutes(new Date(), 45), 23) }, void 0) }, void 0), touched["workingHours[" + value.originalIndex + "].end"] &&
                                        !isNil(errors["workingHours[" + value.originalIndex + "].end"]) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors["workingHours[" + value.originalIndex + "].end"] }), void 0))] }, void 0), _jsx(Button, __assign({ icon: true, variant: "tertiary", style: {
                                    marginRight: 16,
                                    visibility: dayEntries.length - 1 === idx ? 'visible' : 'hidden',
                                }, onClick: function () { return push(day); } }, { children: _jsx(Icon, { size: 24, name: "plus" }, void 0) }), void 0), _jsx(Button, __assign({ icon: true, variant: "secondary", color: "alert", style: {
                                    visibility: dayEntries.length > 1 ? 'visible' : 'hidden',
                                }, onClick: function () { return remove(value.originalIndex); } }, { children: _jsx(Icon, { size: 24, name: "remove" }, void 0) }), void 0)] }, dayName[day] + "-" + value.originalIndex)); }) }, void 0)] }, void 0));
    };
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.workingHours) }, void 0) }, void 0), _jsx(FormGroupContent, { children: [0, 1, 2, 3, 4, 5, 6].map(function (day) { return renderDay(values, day); }) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
var RemoveButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 48px;\n\n  @media (max-width: 744px) {\n    position: absolute;\n    right: 0;\n    height: 48px;\n  }\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n\n    @media (max-width: 744px) {\n      height: 48px;\n    }\n  }\n"], ["\n  width: 48px;\n\n  @media (max-width: 744px) {\n    position: absolute;\n    right: 0;\n    height: 48px;\n  }\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n\n    @media (max-width: 744px) {\n      height: 48px;\n    }\n  }\n"])));
var DayEntry = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n\n  @media (min-width: 744px) {\n    flex-flow: row;\n  }\n"], ["\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n\n  @media (min-width: 744px) {\n    flex-flow: row;\n  }\n"])));
var DayName = styled(Typography)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 120px;\n  flex-shrink: 0;\n  margin-bottom: 12px;\n\n  @media (min-width: 744px) {\n    margin-top: 12px;\n    margin-bottom: 0;\n  }\n"], ["\n  width: 120px;\n  flex-shrink: 0;\n  margin-bottom: 12px;\n\n  @media (min-width: 744px) {\n    margin-top: 12px;\n    margin-bottom: 0;\n  }\n"])));
var DayTimeSlots = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  width: 100%;\n"])));
var DayTimeSlot = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n"])));
var DatePickerWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n\n  .react-datepicker-wrapper {\n    width: 100%;\n  }\n\n  svg {\n    position: absolute;\n    top: 12px;\n    right: 16px;\n  }\n"], ["\n  position: relative;\n\n  .react-datepicker-wrapper {\n    width: 100%;\n  }\n\n  svg {\n    position: absolute;\n    top: 12px;\n    right: 16px;\n  }\n"])));
var StyledDatePicker = styled(DatePicker)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 16px;\n  line-height: 24px;\n  color: rgba(26, 26, 26, 1);\n  padding: 11px 15px;\n  background: transparent;\n  border: 1px solid rgba(199, 199, 199, 1);\n  border-radius: 4px;\n  width: 100%;\n  outline: none;\n\n  ", "\n\n  :focus {\n    border-color: rgba(235, 114, 54, 1);\n    ", "\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  font-size: 16px;\n  line-height: 24px;\n  color: rgba(26, 26, 26, 1);\n  padding: 11px 15px;\n  background: transparent;\n  border: 1px solid rgba(199, 199, 199, 1);\n  border-radius: 4px;\n  width: 100%;\n  outline: none;\n\n  ", "\n\n  :focus {\n    border-color: rgba(235, 114, 54, 1);\n    ", "\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])), function (props) {
    return props.error ? 'border-color: #FF75A7; background: #FFEBE5;' : '';
}, function (props) { return (props.error ? 'border-color: #FF75A7;' : ''); });
var DayTimeSlotWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 16px;\n"], ["\n  margin-right: 16px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
