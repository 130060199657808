var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, FormLabel, Button, Icon, SingleSelect, } from '@petsly/shared-client';
import { UserStatusEnum } from 'types/graphql';
var View = function (_a) {
    var employees = _a.employees, services = _a.services, values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur, push = _a.push, remove = _a.remove;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsxs(FormGroupHeader, { children: [_jsx(FormSubHeading, { children: formatMessage(messages.services) }, void 0), _jsx(Button, __assign({ size: "small", onClick: function () {
                            push();
                        } }, { children: formatMessage(messages.add) }), void 0)] }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsxs(Entry, { children: [_jsx(ServiceInput, __assign({ fullWidth: employees.length === 1 }, { children: _jsxs(FormLabel, { children: [formatMessage(messages.service), " "] }, void 0) }), void 0), employees.length >= 2 && (_jsx(EmployeeInput, { children: _jsxs(FormLabel, { children: [formatMessage(messages.employee), " "] }, void 0) }, void 0)), values.length >= 2 && _jsx(Empty, {}, void 0)] }, void 0), values.map(function (entry, idx) {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                            return (_jsxs(Entry, { children: [_jsxs(ServiceInput, __assign({ fullWidth: employees.length === 1 }, { children: [_jsxs(SingleSelect, __assign({ name: "services[" + idx + "].serviceId", value: entry.serviceId, onChange: function (ev) {
                                                    onChange("services[" + idx + "].serviceId", ev.currentTarget.value);
                                                    if (employees.length === 1) {
                                                        onChange("services[" + idx + "].employeeId", employees[0].id);
                                                    }
                                                }, onBlur: function () {
                                                    onBlur("services[" + idx + "].serviceId");
                                                }, error: ((_a = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _a === void 0 ? void 0 : _a.serviceId) &&
                                                    !isNil((_b = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _b === void 0 ? void 0 : _b.serviceId) }, { children: [_jsx("option", __assign({ disabled: true, value: "" }, { children: formatMessage(messages.select) }), void 0), services.map(function (s) { return (_jsx("option", __assign({ value: s.id }, { children: s.name }), s.id)); })] }), void 0), ((_c = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _c === void 0 ? void 0 : _c.serviceId) &&
                                                !isNil((_d = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _d === void 0 ? void 0 : _d.serviceId) && (_jsx(FormHelperText, __assign({ error: true }, { children: (_e = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _e === void 0 ? void 0 : _e.serviceId }), void 0))] }), void 0), employees.length >= 2 && (_jsxs(EmployeeInput, { children: [_jsxs(SingleSelect, __assign({ name: "services[" + idx + "].employeeId", value: entry.employeeId, onChange: function (ev) {
                                                    onChange("services[" + idx + "].employeeId", ev.currentTarget.value);
                                                }, onBlur: function () {
                                                    onBlur("services[" + idx + "].employeeId");
                                                }, error: ((_f = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _f === void 0 ? void 0 : _f.employeeId) &&
                                                    !isNil((_g = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _g === void 0 ? void 0 : _g.employeeId) }, { children: [_jsx("option", __assign({ disabled: true, value: "" }, { children: formatMessage(messages.select) }), void 0), employees
                                                        .filter(function (e) { return e.status === UserStatusEnum.Active; })
                                                        .map(function (e) { return (_jsx("option", __assign({ value: e.id }, { children: e.name }), e.id)); })] }), void 0), ((_h = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _h === void 0 ? void 0 : _h.employeeId) &&
                                                !isNil((_j = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _j === void 0 ? void 0 : _j.employeeId) && (_jsx(FormHelperText, __assign({ error: true }, { children: (_k = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _k === void 0 ? void 0 : _k.employeeId }), void 0))] }, void 0)), values.length >= 2 && (_jsx(RemoveButton, { children: _jsx(Button, __assign({ variant: "secondary", color: "alert", onClick: function () { return remove(idx); } }, { children: _jsx(Icon, { size: 24, name: "remove" }, void 0) }), void 0) }, void 0))] }, idx));
                        })] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.employees, nextProps.employees) &&
        isEqual(prevProps.services, nextProps.services) &&
        isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
var Entry = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: flex;\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])));
var ServiceInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: ", "%;\n  margin-right: 16px;\n"], ["\n  width: ", "%;\n  margin-right: 16px;\n"])), function (props) { return (props.fullWidth ? '100' : '50'); });
var EmployeeInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 50%;\n\n  :not(:last-child) {\n    margin-right: 16px;\n  }\n"], ["\n  width: 50%;\n\n  :not(:last-child) {\n    margin-right: 16px;\n  }\n"])));
var RemoveButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 48px;\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"], ["\n  width: 48px;\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"])));
var Empty = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 48px;\n"], ["\n  width: 48px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
