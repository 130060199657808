var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import { FormGroup, FormHelperText, Checkbox, } from '@petsly/shared-client';
import messages from '../messages';
var NameField = function (_a) {
    var value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(Checkbox, { label: formatMessage(messages.sendViaEmail), checked: value, onChange: function () {
                    onBlur();
                    onChange(!value);
                }, error: touched && !isNil(error) }, void 0), _jsx(FormHelperText, __assign({ style: { marginLeft: 40 } }, { children: formatMessage(messages.info) }), void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.value === nextProps.value &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(NameField, shouldRerender);
