var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import sharedMessages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, SingleSelect, } from '@petsly/shared-client';
var EmployeesField = function (_a) {
    var setEmployee = _a.setEmployee, employee = _a.employee, employees = _a.employees, messages = _a.messages;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(sharedMessages.employee) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsx("div", __assign({ style: { marginBottom: 16 } }, { children: _jsxs(SingleSelect, __assign({ disabled: employees.length <= 1, variant: "medium", value: employee, onChange: function (ev) {
                            setEmployee(ev.currentTarget.value.split(','));
                        } }, { children: [_jsx("option", __assign({ value: employees.map(function (item) {
                                    return item.id;
                                }) }, { children: messages.allEmployees }), void 0), employees.map(function (o) { return (_jsx("option", __assign({ value: o.id }, { children: o.name }), o.id)); })] }), void 0) }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.employees === nextProps.employees &&
        prevProps.employee === nextProps.employee);
};
export default memo(EmployeesField, shouldRerender);
