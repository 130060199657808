import { createMuiTheme } from '@material-ui/core/styles';
var primaryMain = '#F59056';
var theme = createMuiTheme({
    palette: {
        primary: {
            main: primaryMain,
            light: '#FEF1DD',
            dark: '#B04B2B',
        },
        warning: {
            main: '#F7BA36',
            light: '#FFF9CC',
            dark: '#B78E00',
        },
        error: {
            main: '#FF75A7',
            light: '#FFEBE5',
            dark: '#B74064',
        },
        success: {
            main: '#3AE888',
            light: '#F2FDE1',
            dark: '#4EA233',
        },
        info: {
            main: '#1AA5EF',
            light: '#CFEEFF',
            dark: '#0C63DB',
        },
        text: {
            primary: '#1A1A1A',
            secondary: '#656565',
            disabled: '#A1A1A1',
        },
    },
    typography: {
        fontFamily: "'Inter', sans-serif",
        fontSize: 14,
        htmlFontSize: 14,
        h1: {
            fontWeight: 600,
            fontSize: '56px',
            lineHeight: 64 / 56,
            letterSpacing: '-1px',
        },
        h2: {
            fontWeight: 600,
            fontSize: '48px',
            lineHeight: 72 / 48,
            letterSpacing: '-1px',
        },
        h3: {
            fontWeight: 600,
            fontSize: '40px',
            lineHeight: 60 / 40,
            letterSpacing: '-1px',
        },
        h4: {
            fontWeight: 500,
            fontSize: '32px',
            lineHeight: 48 / 32,
        },
        h5: {
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: 32 / 24,
        },
        h6: {
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: 24 / 18,
        },
        subtitle1: {
            fontSize: '13px',
            fontWeight: 600,
            lineHeight: 18 / 13,
        },
        subtitle2: {
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: 16 / 12,
        },
        body1: {
            fontSize: '14px',
        },
        body2: {
            fontSize: '12px',
        },
    },
    overrides: {
        MuiAvatar: {
            root: {
                width: '24px',
                height: '24px',
                fontSize: '12px',
            },
        },
        MuiAppBar: {
            root: {
                backgroundColor: '#fff !important',
                borderBottom: '1px solid #e5e8ec',
                boxShadow: 'none',
            },
        },
        MuiBackdrop: {
            root: {
                background: '#rgba(27, 29, 33, .5)',
            },
        },
        MuiTab: {
            root: {
                borderRadius: '12px',
                fontWeight: 600,
                fontSize: 14,
                textTransform: 'none',
                transition: 'all .25s',
                padding: '6px 16px',
                border: 'none',
                minHeight: 40,
                '&:hover': {
                    background: primaryMain,
                    color: '#fff',
                    borderColor: primaryMain,
                    textDecoration: 'none',
                },
                '&:not(:last-child)': {
                    marginRight: 18,
                },
                '@media (min-width: 600px)': {
                    minWidth: 'auto',
                },
            },
            textColorPrimary: {
                '&$selected': {
                    border: 'none',
                    background: primaryMain,
                    color: '#fff',
                },
            },
        },
        MuiTabs: {
            indicator: {
                display: 'none',
            },
        },
        MuiButton: {
            root: {
                borderRadius: '12px',
                textTransform: 'none',
                fontSize: '12px',
            },
            outlined: {
                borderRadius: '12px',
            },
            outlinedPrimary: {
                border: '1px solid #D6DDDF',
                '&:hover': {
                    borderWidth: '1px',
                },
            },
            contained: {
                borderRadius: '12px',
                boxShadow: 'none',
                letterSpacing: '0.225px',
            },
            containedPrimary: {
                color: '#fff',
            },
            text: {
                minWidth: '120px',
                letterSpacing: '0.225px',
            },
            sizeLarge: {
                fontSize: '14px',
                fontWeight: 700,
                padding: '20px',
                height: '56px',
            },
        },
        MuiIconButton: {
            root: {
                padding: '8px',
            },
        },
        MuiButtonGroup: {
            contained: {
                boxShadow: 'none',
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: '#F1F5F6',
                color: 'rgba(0, 0, 0, 0.64)',
                border: '1px solid #D6DDDF',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            },
        },
        MuiTextField: {
            root: {
                marginTop: '8px',
            },
        },
        MuiInputBase: {
            root: {
                fontSize: 14,
                fontWeight: 600,
                color: '#393A3C',
                background: '#fff',
                height: '56px',
            },
        },
        MuiInput: {
            root: {
                borderRadius: '12px',
                '&:focus': {
                    border: 'none',
                },
                '&$focused': {
                    border: "2px solid",
                },
            },
            input: {
                padding: '16px 24px',
            },
            underline: {
                '&::before, &::after': {
                    display: 'none',
                },
            },
        },
        MuiFormLabel: {
            root: {
                marginBottom: 8,
                fontSize: 12,
                fontWeight: 500,
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(51, 51, 51, 0.04)',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '12px',
            },
            input: {
                padding: '16px 24px',
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: '24px',
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '32px',
            },
        },
        MuiDialogContent: {
            root: {
                padding: '16px 32px',
            },
        },
        MuiDialogActions: {
            root: {
                padding: 32,
            },
        },
        MuiBreadcrumbs: {
            root: {
                fontSize: '.75rem',
            },
        },
        MuiTableCell: {
            footer: {
                border: 'none',
            },
            head: {
                padding: 12,
                fontWeight: 400,
                color: '#596877',
                opacity: 0.7,
                fontSize: 14,
                '&:first-child': {
                    paddingLeft: 0,
                },
                '&:last-child': {
                    paddingRight: 0,
                },
            },
            body: {
                padding: 12,
                color: '#393A3C',
                fontSize: 14,
                '&:first-child': {
                    paddingLeft: 0,
                },
                '&:last-child': {
                    paddingRight: 0,
                },
            },
        },
        MuiTableRow: {
            root: {
                fontWeight: 400,
            },
        },
        MuiFormGroup: {
            root: {
                '&:not(:last-child)': {
                    marginBottom: 20,
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: '10px',
            },
        },
        MuiCard: {
            root: {
                boxShadow: '0px 2px 4px rgba(236, 245, 250, 0.7)',
                border: '1px solid #e5e8ec',
                borderRadius: '.25rem',
            },
        },
        MuiCardHeader: {
            root: {
                borderBottom: '1px solid #e5e8ec',
                padding: '1.5rem',
                background: '#fff',
            },
            title: {
                fontWeight: 500,
                letterSpacing: '0.225px',
                color: '#393a3c',
            },
        },
        MuiCardContent: {
            root: {
                padding: '1.5rem',
                background: '#F9FCFD',
            },
        },
        MuiCardActions: {
            root: {
                borderTop: '1px solid #e5e8ec',
                padding: '1.5rem',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'auto',
                marginRight: 16,
            },
        },
        MuiMenuItem: {
            root: {
                fontWeight: 600,
            },
        },
    },
});
export default theme;
