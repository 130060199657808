var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, ToggleButtonGroup, ToggleButton, } from '@petsly/shared-client';
import { AppointmentTypeEnum } from 'types/graphql';
var View = function (_a) {
    var value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.type) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsxs(ToggleButtonGroup, __assign({ fullWidth: true, size: "large" }, { children: [_jsx(ToggleButton, __assign({ active: value === AppointmentTypeEnum.IndividualClient, onClick: function () {
                                        onChange(AppointmentTypeEnum.IndividualClient);
                                        onBlur();
                                    } }, { children: formatMessage(messages.APPOINTMENT_TYPE_INDIVIDUAL_CLIENT) }), void 0), _jsx(ToggleButton, __assign({ active: value === AppointmentTypeEnum.GroupClient, onClick: function () {
                                        onChange(AppointmentTypeEnum.GroupClient);
                                        onBlur();
                                    } }, { children: formatMessage(messages.APPOINTMENT_TYPE_GROUP_CLIENT) }), void 0), _jsx(ToggleButton, __assign({ active: false, disabled: true, "data-tooltip": "Soon" }, { children: formatMessage(messages.APPOINTMENT_TYPE_ONLINE) }), void 0)] }), void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.value, nextProps.value) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.error, nextProps.error));
};
export default memo(View, shouldRerender);
