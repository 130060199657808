var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useMemo, useContext, createContext, useEffect, } from 'react';
import isNil from 'lodash/isNil';
var WIZARD_LOCAL_STORAGE_KEY = 'Petsly.WizardContext';
var WizardContext = createContext({
    setWizard: function () { },
    getWizard: function () { },
    clearWizard: function () { },
});
var WizardConsumer = WizardContext.Consumer;
var WizardProvider = function (props) {
    var _a = __read(useState({}), 2), wizard = _a[0], updateWizard = _a[1];
    useEffect(function () {
        var keys = Object.keys(window.localStorage);
        var wizardKeys = keys.filter(function (key) {
            return key.startsWith(WIZARD_LOCAL_STORAGE_KEY);
        });
        var initialState = wizardKeys.reduce(function (acc, key) {
            var _a;
            var wizard = key.replace(WIZARD_LOCAL_STORAGE_KEY + ".", '');
            var stringifiedPayload = localStorage.getItem(key);
            var payload = !isNil(stringifiedPayload)
                ? JSON.parse(stringifiedPayload)
                : {};
            return __assign(__assign({}, acc), (_a = {}, _a[wizard] = payload, _a));
        }, {});
        updateWizard(initialState);
    }, []);
    var setWizard = function (name, payload) {
        updateWizard(function (state) {
            var updatedState = __assign({}, state);
            if (!state[name]) {
                updatedState[name] = __assign({}, payload);
            }
            else {
                updatedState[name] = __assign(__assign({}, updatedState[name]), payload);
            }
            window.localStorage.setItem(WIZARD_LOCAL_STORAGE_KEY + "." + name, JSON.stringify(updatedState[name]));
            return updatedState;
        });
    };
    var getWizard = function (name) {
        var _a;
        return (_a = wizard[name]) !== null && _a !== void 0 ? _a : {};
    };
    var clearWizard = function (name) {
        updateWizard(function (state) {
            var updatedState = state;
            delete updatedState[name];
            window.localStorage.removeItem(WIZARD_LOCAL_STORAGE_KEY + "." + name);
            return updatedState;
        });
    };
    var values = useMemo(function () { return ({ setWizard: setWizard, clearWizard: clearWizard, getWizard: getWizard }); }, [setWizard, clearWizard, getWizard]);
    return (_jsx(WizardContext.Provider, __assign({ value: values }, { children: props.children }), void 0));
};
var useWizard = function () {
    var context = useContext(WizardContext);
    if (context === undefined) {
        throw new Error('useWizard must be used within an WizardProvider');
    }
    return context;
};
export { WizardProvider, WizardConsumer, useWizard };
