var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useDrawer } from 'context/DrawerContext';
import query from './query.graphql';
import getClientQuery from './getClient.graphql';
import createInvoiceMutation from './createInvoice.graphql';
import View from './View';
var Container = function (_a) {
    var clientId = _a.clientId, relationship = _a.relationship;
    var _b = useQuery(query), data = _b.data, loading = _b.loading, error = _b.error;
    var closeDrawer = useDrawer().closeDrawer;
    var history = useHistory();
    var _c = __read(useMutation(createInvoiceMutation, {
        onCompleted: function (data) {
            closeDrawer();
            history.push("/invoices/" + data.createInvoice.id);
        },
    }), 2), create = _c[0], createResult = _c[1];
    var _d = __read(useLazyQuery(getClientQuery), 2), getClient = _d[0], getClientResult = _d[1];
    var client = useMemo(function () { return get(getClientResult, 'data.getClientById', null); }, [getClientResult.data]);
    var clients = useMemo(function () { return get(data, 'getClients.items', []); }, [data]);
    var services = useMemo(function () { return get(data, 'getServices.edges', []).map(function (_a) {
        var node = _a.node;
        return node;
    }); }, [data]);
    var taxRates = useMemo(function () { return get(data, 'getTaxRates', []); }, [data]);
    var nextInvoiceNumber = useMemo(function () { return get(data, 'getNextInvoiceNumber.invoiceNumber', ''); }, [data]);
    useEffect(function () {
        if (clientId) {
            getClient({
                variables: {
                    id: clientId,
                },
            });
        }
    }, [clientId]);
    var onCreate = function (input) {
        create({
            variables: {
                input: __assign(__assign({}, input), { relationship: relationship }),
            },
        });
    };
    return (_jsx(View, { client: client, clients: clients, services: services, taxRates: taxRates, nextInvoiceNumber: nextInvoiceNumber, isLoading: loading || getClientResult.loading, isProcessing: createResult.loading, error: error || getClientResult.error, onCreate: onCreate }, void 0));
};
export default Container;
