var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, SingleSelect, Input, } from '@petsly/shared-client';
import { PetSpeciesEnum } from 'types/graphql';
function getBreeds(species) {
    if (species === void 0) { species = null; }
    switch (species) {
        case PetSpeciesEnum.Cat: {
            return require('constants/cats.json').data;
        }
        case PetSpeciesEnum.Dog: {
            return require('constants/dogs.json').data;
        }
        case PetSpeciesEnum.Rabbit: {
            return require('constants/rabbits.json').data;
        }
        case PetSpeciesEnum.Horse: {
            return require('constants/horses.json').data;
        }
        default:
            return [];
    }
}
var Field = function (_a) {
    var species = _a.species, disabled = _a.disabled, value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.species) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [species === PetSpeciesEnum.Other || !species ? (_jsx(Input, { name: "breed", value: value, onChange: function (ev) {
                                onChange(ev.currentTarget.value);
                            }, onBlur: function () {
                                onBlur();
                            }, disabled: disabled, error: touched && !isNil(error) }, void 0)) : (_jsx(SingleSelect, __assign({ name: "breed", value: value, onChange: function (ev) {
                                onChange(ev.currentTarget.value);
                            }, onBlur: function () {
                                onBlur();
                            }, disabled: disabled, error: touched && !isNil(error) }, { children: getBreeds(species).map(function (e) { return (_jsx("option", __assign({ value: e }, { children: e }), e)); }) }), void 0)), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.species === nextProps.species &&
        prevProps.disabled === nextProps.disabled &&
        prevProps.value === nextProps.value &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(Field, shouldRerender);
