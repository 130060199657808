var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, FormLabel, Input, Row, Col, CountrySelector, } from '@petsly/shared-client';
var NameField = function (_a) {
    var _b;
    var values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.address) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsx(Row, { children: _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.country), " "] }, void 0), _jsx(CountrySelector, { name: "address.country", variant: "large", value: (_b = values === null || values === void 0 ? void 0 : values.country) !== null && _b !== void 0 ? _b : 'PL', defaultValue: "PL", onChange: function (_a) {
                                            var value = _a.value;
                                            onChange('address.country', value);
                                        }, error: (touched === null || touched === void 0 ? void 0 : touched.country) && !isNil(errors === null || errors === void 0 ? void 0 : errors.country), language: window.__localeId__ === 'pl' ? 'pl' : 'en', placeholder: formatMessage(messages.select) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.country) && !isNil(errors === null || errors === void 0 ? void 0 : errors.country) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.country }), void 0))] }), void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.line1), " "] }, void 0), _jsx(Input, { name: "address.line1", value: values.line1, onChange: function (ev) {
                                            onChange(ev.currentTarget.name, ev.currentTarget.value);
                                        }, onBlur: function (ev) {
                                            onBlur(ev.currentTarget.name);
                                        }, error: (touched === null || touched === void 0 ? void 0 : touched.line1) && !isNil(errors === null || errors === void 0 ? void 0 : errors.line1) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.line1) && !isNil(errors === null || errors === void 0 ? void 0 : errors.line1) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.line1 }), void 0))] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.line2), " "] }, void 0), _jsx(Input, { name: "address.line2", value: values.line2, onChange: function (ev) {
                                            onChange(ev.currentTarget.name, ev.currentTarget.value);
                                        }, onBlur: function (ev) {
                                            onBlur(ev.currentTarget.name);
                                        }, error: (touched === null || touched === void 0 ? void 0 : touched.line2) && !isNil(errors === null || errors === void 0 ? void 0 : errors.line2) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.line2) && !isNil(errors === null || errors === void 0 ? void 0 : errors.line2) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.line2 }), void 0))] }, void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.city), " "] }, void 0), _jsx(Input, { name: "address.city", value: values.city, onChange: function (ev) {
                                                onChange(ev.currentTarget.name, ev.currentTarget.value);
                                            }, onBlur: function (ev) {
                                                onBlur(ev.currentTarget.name);
                                            }, error: (touched === null || touched === void 0 ? void 0 : touched.city) && !isNil(errors === null || errors === void 0 ? void 0 : errors.city) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.city) && !isNil(errors === null || errors === void 0 ? void 0 : errors.city) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.city }), void 0))] }), void 0), _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.state), " "] }, void 0), _jsx(Input, { name: "address.state", value: values.state, onChange: function (ev) {
                                                onChange(ev.currentTarget.name, ev.currentTarget.value);
                                            }, onBlur: function (ev) {
                                                onBlur(ev.currentTarget.name);
                                            }, error: (touched === null || touched === void 0 ? void 0 : touched.state) && !isNil(errors === null || errors === void 0 ? void 0 : errors.state) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.state) && !isNil(errors === null || errors === void 0 ? void 0 : errors.state) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.state }), void 0))] }), void 0)] }, void 0), _jsx(Row, { children: _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.postalCode), " "] }, void 0), _jsx(Input, { name: "address.postalCode", value: values.postalCode, onChange: function (ev) {
                                            onChange(ev.currentTarget.name, ev.currentTarget.value);
                                        }, onBlur: function (ev) {
                                            onBlur(ev.currentTarget.name);
                                        }, error: (touched === null || touched === void 0 ? void 0 : touched.postalCode) && !isNil(errors === null || errors === void 0 ? void 0 : errors.postalCode) }, void 0), (touched === null || touched === void 0 ? void 0 : touched.postalCode) && !isNil(errors === null || errors === void 0 ? void 0 : errors.postalCode) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.postalCode }), void 0))] }), void 0) }, void 0)] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.values === nextProps.values &&
        prevProps.touched === nextProps.touched &&
        prevProps.errors === nextProps.errors);
};
export default memo(NameField, shouldRerender);
