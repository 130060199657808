var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, } from '@petsly/shared-client';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { parseISO } from 'date-fns';
import slugify from 'slugify';
import { useDrawer } from 'context/DrawerContext';
import translations from 'translations/shared';
import DescriptionField from './components/Description';
import NameField from './components/Name';
import EventDatesField from './components/EventDates';
import LimitField from './components/Limit';
import PaidField from './components/Paid';
import PriceField from './components/Price';
var View = function (_a) {
    var isProcessing = _a.isProcessing, onUpdateState = _a.onUpdateState, onUpdateSessionState = _a.onUpdateSessionState, webinarSession = _a.webinarSession;
    var formatMessage = useIntl().formatMessage;
    var closeDrawer = useDrawer().closeDrawer;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            title: yup.string().required(formatMessage(translations.required)),
            description: yup.string().nullable(),
            limit: yup.number().required(formatMessage(translations.required)),
            paid: yup.boolean().required(formatMessage(translations.required)),
            price: yup.number().required(formatMessage(translations.required)),
            estimatedStartedAt: yup
                .date()
                .required(formatMessage(translations.required)),
        });
    }, []);
    var initialValues = {
        title: '',
        description: '',
        paid: false,
        price: 0,
        limit: 0,
        estimatedStartedAt: undefined,
    };
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: initialValues,
        onSubmit: function (values) {
            var id = webinarSession.webinar.id;
            var sessionId = webinarSession.id;
            var title = values.title;
            var description = values.description;
            var paid = values.paid === 'true' || values.paid === true;
            var price = parseInt(values.price);
            var limit = parseInt(values.limit);
            var slug = slugify(values.title);
            var estimatedStartedAt = values.estimatedStartedAt;
            var estimatedDuration = webinarSession.webinar.estimatedDuration;
            var timezone = webinarSession.timezone;
            var webinarData = {
                id: id,
                title: title,
                description: description,
                paid: paid,
                price: price,
                limit: limit,
                slug: slug,
                estimatedDuration: estimatedDuration,
            };
            var webinarSessionData = {
                webinarId: id,
                sessionId: sessionId,
                estimatedStartedAt: estimatedStartedAt,
                timezone: timezone,
            };
            onUpdateState(webinarData);
            onUpdateSessionState(webinarSessionData);
        },
    });
    var setFieldValue = formik.setFieldValue, setValues = formik.setValues, setFieldTouched = formik.setFieldTouched, submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (!webinarSession) {
            return;
        }
        setValues({
            title: (_b = (_a = webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.webinar) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : '',
            description: (_d = (_c = webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.webinar) === null || _c === void 0 ? void 0 : _c.description) !== null && _d !== void 0 ? _d : '',
            paid: (_f = (_e = webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.webinar) === null || _e === void 0 ? void 0 : _e.paid) !== null && _f !== void 0 ? _f : false,
            price: (_h = (_g = webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.webinar) === null || _g === void 0 ? void 0 : _g.price) !== null && _h !== void 0 ? _h : 0,
            limit: (_k = (_j = webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.webinar) === null || _j === void 0 ? void 0 : _j.limit) !== null && _k !== void 0 ? _k : 0,
            estimatedStartedAt: (_l = parseISO(webinarSession === null || webinarSession === void 0 ? void 0 : webinarSession.estimatedStartedAt)) !== null && _l !== void 0 ? _l : undefined,
        });
    }, [webinarSession, setValues]);
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(translations.editWebinar) }), void 0), _jsxs(DrawerContent, { children: [_jsx(NameField, { value: values.title, touched: touched.title, error: errors.title, onBlur: function () { return setFieldTouched('title'); }, onChange: function (value) { return setFieldValue('title', value); } }, void 0), _jsx(DescriptionField, { value: values.description, touched: touched.description, error: errors.description, onBlur: function () { return setFieldTouched('description'); }, onChange: function (value) { return setFieldValue('description', value); } }, void 0), _jsx(EventDatesField, { values: {
                            value: values.estimatedStartedAt,
                        }, errors: {
                            value: errors.estimatedStartedAt,
                        }, touched: {
                            value: touched.estimatedStartedAt,
                        }, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); } }, void 0), _jsx(LimitField, { value: values.limit, touched: touched.limit, error: errors.limit, onBlur: function () { return setFieldTouched('limit'); }, onChange: function (value) { return setFieldValue('limit', value); } }, void 0), _jsx(PaidField, { value: values.paid, error: errors.paid, touched: touched.paid, onChange: function (value) { return setFieldValue('paid', value); }, onBlur: function () { return setFieldTouched('paid'); } }, void 0), _jsx(PriceField, { value: values.price, touched: touched.price, error: errors.price, onBlur: function () { return setFieldTouched('price'); }, onChange: function (value) { return setFieldValue('price', value); } }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: formatMessage(translations.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
