var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useIntl } from 'react-intl';
import { createStyles, withStyles, } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import sharedMessages from 'translations/shared';
var styles = function (theme) {
    return createStyles({
        heading: {
            fontWeight: 500,
        },
        icon: {
            width: 20,
            height: 20,
        },
        actions: {},
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeBtn: {
            zIndex: 3,
        },
        closeBtnWhite: {
            color: '#fff',
        },
    });
};
var View = withStyles(styles)(function (props) {
    var _a;
    var children = props.children, classes = props.classes, onClose = props.onClose, actions = props.actions, other = __rest(props, ["children", "classes", "onClose", "actions"]);
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(DialogTitle, __assign({ disableTypography: true }, other, { className: clsx(classes.root, props.className) }, { children: [_jsx(Typography, __assign({ variant: "h6", className: classes.heading }, { children: children }), void 0), _jsxs("div", __assign({ className: classes.actions }, { children: [actions !== null && actions !== void 0 ? actions : null, onClose ? (_jsx(Tooltip, __assign({ title: formatMessage(sharedMessages.close) }, { children: _jsx(IconButton, __assign({ "aria-label": "close", onClick: onClose, className: clsx(classes.closeBtn, (_a = {},
                                _a[classes.closeBtnWhite] = other.whiteClose,
                                _a)) }, { children: _jsx(FontAwesomeIcon, { icon: faTimes, className: classes.icon }, void 0) }), void 0) }), void 0)) : null] }), void 0)] }), void 0));
});
export default View;
