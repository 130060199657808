var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState, useContext, createContext, useEffect, } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useInterval } from 'hooks/useInterval';
var MobileContext = createContext({
    isMobile: false,
    isNative: false,
    hasNotch: false,
    forceNavigationVisibility: null,
    onForceUpdateNavigationVisibility: function () { },
});
var MobileConsumer = MobileContext.Consumer;
var MobileProvider = function (props) {
    var _a = __read(useState(null), 2), forceNavigationVisibility = _a[0], setForceNavigationVisibility = _a[1];
    var _b = __read(useState(false), 2), isNative = _b[0], setIsNative = _b[1];
    var _c = __read(useState(false), 2), hasNotch = _c[0], setHasNotch = _c[1];
    var isMobile = useMediaQuery({ query: '(max-width: 743px)' });
    useInterval(function () {
        var currNative = window.isNative;
        var prevNative = isNative;
        if (currNative !== prevNative) {
            setIsNative(!!currNative);
        }
    }, 5000);
    useEffect(function () {
        if (window.isNative) {
            setIsNative(true);
        }
    }, []);
    function onForceUpdateNavigationVisibility(visible) {
        setForceNavigationVisibility(visible);
    }
    var values = useMemo(function () { return ({
        isMobile: isMobile,
        isNative: isNative,
        hasNotch: hasNotch,
        forceNavigationVisibility: forceNavigationVisibility,
        onForceUpdateNavigationVisibility: onForceUpdateNavigationVisibility,
    }); }, [forceNavigationVisibility, isMobile, isNative, hasNotch]);
    return (_jsx(MobileContext.Provider, __assign({ value: values }, { children: props.children }), void 0));
};
var useMobile = function () {
    var context = useContext(MobileContext);
    if (context === undefined) {
        throw new Error('useMobile must be used within an MobileProvider');
    }
    return context;
};
export { MobileProvider, MobileConsumer, useMobile };
