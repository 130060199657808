var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React from 'react';
import useModal from 'hooks/useModal';
import Confirm from './Confirm';
import AttachmentView from './AttachmentView';
var modalComponentLookupTable = {
    Confirm: Confirm,
    AttachmentView: AttachmentView,
};
export var ModalTypeEnum;
(function (ModalTypeEnum) {
    ModalTypeEnum["CONFIRM"] = "Confirm";
    ModalTypeEnum["APPOINTMENT"] = "Appointment";
    ModalTypeEnum["ATTACHMENT_VIEW"] = "AttachmentView";
})(ModalTypeEnum || (ModalTypeEnum = {}));
var ModalManager = function () {
    var currentModals = useModal().currentModals;
    var renderModals = currentModals.map(function (modalDescription, index) {
        var modalType = modalDescription.modalType, modalProps = modalDescription.modalProps;
        var ModalComponent = modalComponentLookupTable[modalType];
        return _createElement(ModalComponent, __assign({}, modalProps, { key: modalType + index }));
    });
    return _jsx(_Fragment, { children: renderModals }, void 0);
};
export default ModalManager;
