var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import React from 'react';
import { useDrawer } from 'context/DrawerContext';
import Appointment from './Appointment';
import ManageEmployee from './ManageEmployee';
import CreateInvoice from './CreateInvoice';
import EditInvoice from './EditInvoice';
import CreateServiceCategory from './CreateServiceCategory';
import EditServiceCategory from './EditServiceCategory';
import CreateService from './CreateService';
import EditService from './EditService';
import CreateTaxRate from './CreateTaxRate';
import EditTaxRate from './EditTaxRate';
import CreateAppointment from './CreateAppointment';
import EditAppointment from './EditAppointment';
import CreateClient from './CreateClient';
import CreatePet from './CreatePet';
import CreateWebinar from './CreateWebinar';
import EditWebinar from './EditWebinar';
import RecordField from './RecordField';
import SendMessage from './SendMessage';
import QuickAddClientAndPet from './QuickAddClientAndPet';
import CreateLocation from './CreateLocation';
import EditLocation from './EditLocation';
import CreateVoucher from './CreateVoucher';
import EditVoucher from './EditVoucher';
import HistoricNotes from './HistoricNotes';
import CalendarFilters from './CalendarFilters';
import MobileAppointmentsFilter from './MobileAppointmentsFilter';
import MobileClientsFilter from './MobileClientsFilter';
import MobileMore from './MobileMore';
var lookupTable = {
    Appointment: Appointment,
    ManageEmployee: ManageEmployee,
    CreateInvoice: CreateInvoice,
    CreateServiceCategory: CreateServiceCategory,
    EditServiceCategory: EditServiceCategory,
    EditInvoice: EditInvoice,
    CreateService: CreateService,
    EditService: EditService,
    CreateTaxRate: CreateTaxRate,
    EditTaxRate: EditTaxRate,
    CreateAppointment: CreateAppointment,
    EditAppointment: EditAppointment,
    CreateClient: CreateClient,
    CreatePet: CreatePet,
    CreateWebinar: CreateWebinar,
    EditWebinar: EditWebinar,
    RecordField: RecordField,
    SendMessage: SendMessage,
    QuickAddClientAndPet: QuickAddClientAndPet,
    CreateLocation: CreateLocation,
    EditLocation: EditLocation,
    CreateVoucher: CreateVoucher,
    EditVoucher: EditVoucher,
    HistoricNotes: HistoricNotes,
    MobileMore: MobileMore,
    CalendarFilters: CalendarFilters,
    MobileAppointmentsFilter: MobileAppointmentsFilter,
    MobileClientsFilter: MobileClientsFilter,
};
var DrawerManager = function () {
    var currentDrawers = useDrawer().currentDrawers;
    if (currentDrawers.length >= 1) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        document.getElementsByTagName('body')[0].classList.add('drawer-open');
    }
    else {
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
        document.getElementsByTagName('body')[0].classList.remove('drawer-open');
    }
    var renderDrawers = currentDrawers.map(function (drawerDescription, index) {
        var drawerType = drawerDescription.drawerType, drawerProps = drawerDescription.drawerProps;
        var DrawerComponent = lookupTable[drawerType];
        return _createElement(DrawerComponent, __assign({}, drawerProps, { key: drawerType + index }));
    });
    return (_jsx("div", __assign({ style: { position: 'absolute', left: 0, top: 0 } }, { children: renderDrawers }), void 0));
};
export default DrawerManager;
