import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import reportWebVitals from './reportWebVitals';
import './fonts/inter.css';
import './index.css';
import App from './App';
import config from './config';
Sentry.init({
    environment: config.env,
    dsn: config.sentryDns,
    release: "FRONTEND-" + config.version,
});
ReactDOM.render(_jsx(App, {}, void 0), document.getElementById('root'));
reportWebVitals();
