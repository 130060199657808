import Keycloak from 'keycloak-js';
import config from 'config';
var keycloakConfig = {
    realm: config.keycloakRealm,
    'auth-server-url': config.keycloakAuthUrl,
    'ssl-required': 'none',
    'public-client': true,
    'confidential-port': 0,
    url: config.keycloakUrl,
    clientId: config.keycloakClientId,
    'enable-cors': false,
};
var keycloak = Keycloak(keycloakConfig);
export default keycloak;
