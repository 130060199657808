var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation, withRouter, } from 'react-router-dom';
import styled from 'styled-components';
import { page } from 'factory/SegmentFactory';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../Layout';
import AdminRoute from '../AdminRoute';
var Business = lazy(function () { return import('scenes/Business'); });
var BusinessProfile = lazy(function () { return import('scenes/Business/scenes/Profile'); });
var BusinessNotifications = lazy(function () {
    return import('scenes/Business/scenes/Notifications');
});
var BusinessLocations = lazy(function () { return import('scenes/Business/scenes/Locations'); });
var BusinessVouchers = lazy(function () { return import('scenes/Business/scenes/Vouchers'); });
var BusinessSales = lazy(function () { return import('scenes/Business/scenes/Sales'); });
var BusinessServices = lazy(function () { return import('scenes/Business/scenes/Services'); });
var BusinessTemplates = lazy(function () { return import('scenes/Business/scenes/Templates'); });
var Dashboard = lazy(function () { return import('scenes/Dashboard'); });
var Onboard = lazy(function () { return import('scenes/OnBoarding'); });
var Employees = lazy(function () { return import('scenes/Employees'); });
var Inbox = lazy(function () { return import('scenes/Inbox'); });
var Webinars = lazy(function () { return import('scenes/Webinars'); });
var Webinar = lazy(function () { return import('scenes/Webinar'); });
var Appointments = lazy(function () { return import('scenes/Appointments'); });
var Calendar = lazy(function () { return import('scenes/Calendar'); });
var Clients = lazy(function () { return import('scenes/Clients'); });
var Client = lazy(function () { return import('scenes/Client'); });
var DocumentView = lazy(function () { return import('scenes/Documents/scenes/DocumentView'); });
var CreateDocument = lazy(function () { return import('scenes/Documents/scenes/Create'); });
var EditDocument = lazy(function () { return import('scenes/Documents/scenes/Edit'); });
var Invoices = lazy(function () { return import('scenes/Invoices'); });
var InvoiceView = lazy(function () { return import('scenes/Invoices/scenes/View'); });
var Pet = lazy(function () { return import('scenes/Pet'); });
var ChooseAccount = lazy(function () { return import('scenes/ChooseAccount'); });
var NotFound = lazy(function () { return import('scenes/NotFound'); });
var NoPermissions = lazy(function () { return import('scenes/NoPermissions'); });
var Account = lazy(function () { return import('scenes/Account'); });
var AccountPersonalInfo = lazy(function () {
    return import('scenes/Account/scenes/PersonalInfo');
});
var AccountPreferences = lazy(function () {
    return import('scenes/Account/scenes/Preferences');
});
var AccountIntegratios = lazy(function () {
    return import('scenes/Account/scenes/Integrations');
});
var FallbackWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 64px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 64px);\n"], ["\n  position: absolute;\n  top: 64px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 64px);\n"])));
var Fallback = (_jsx(FallbackWrapper, { children: _jsx(CircularProgress, { size: 64 }, void 0) }, void 0));
var routes = [
    {
        isAdmin: false,
        path: '/choose-account',
        exact: true,
        component: ChooseAccount,
    },
    {
        isAdmin: false,
        path: '/onboard',
        exact: false,
        component: Onboard,
    },
    {
        isAdmin: false,
        path: '/dashboard',
        exact: true,
        component: Dashboard,
    },
    {
        isAdmin: false,
        path: '/pets/:id/documents/new',
        exact: true,
        component: CreateDocument,
    },
    {
        isAdmin: false,
        path: '/pets/:id',
        exact: false,
        component: Pet,
    },
    {
        isAdmin: false,
        path: '/clients',
        exact: true,
        component: Clients,
    },
    {
        isAdmin: false,
        path: '/clients/:id',
        exact: false,
        component: Client,
    },
    {
        isAdmin: false,
        path: '/documents/:id',
        exact: true,
        component: DocumentView,
    },
    {
        isAdmin: false,
        path: '/documents/:id/edit',
        exact: true,
        component: EditDocument,
    },
    {
        isAdmin: false,
        path: '/invoices',
        exact: true,
        component: Invoices,
    },
    {
        isAdmin: false,
        path: '/invoices/:id',
        exact: true,
        component: InvoiceView,
    },
    {
        isAdmin: false,
        path: '/inbox',
        exact: true,
        component: Inbox,
    },
    {
        isAdmin: false,
        path: '/webinars',
        exact: false,
        component: Webinars,
    },
    {
        isAdmin: false,
        path: '/webinars/sessions/:id',
        exact: false,
        component: Webinar,
    },
    {
        isAdmin: false,
        path: '/account',
        exact: true,
        component: Account,
    },
    {
        isAdmin: false,
        path: '/account/personal-info',
        exact: true,
        component: AccountPersonalInfo,
    },
    {
        isAdmin: false,
        path: '/account/preferences',
        exact: true,
        component: AccountPreferences,
    },
    {
        isAdmin: false,
        path: '/account/integrations',
        exact: true,
        component: AccountIntegratios,
    },
    {
        isAdmin: false,
        path: '/appointments',
        exact: false,
        component: Appointments,
    },
    {
        isAdmin: false,
        path: '/calendar',
        exact: true,
        component: Calendar,
    },
    {
        isAdmin: false,
        path: '/no-permissions',
        exact: true,
        component: NoPermissions,
    },
    {
        isAdmin: true,
        path: '/business',
        exact: true,
        component: Business,
    },
    {
        isAdmin: true,
        path: '/business/profile',
        exact: true,
        component: BusinessProfile,
    },
    {
        isAdmin: true,
        path: '/business/employees',
        exact: true,
        component: Employees,
    },
    {
        isAdmin: true,
        path: '/business/notifications',
        exact: true,
        component: BusinessNotifications,
    },
    {
        isAdmin: true,
        path: '/business/locations',
        exact: true,
        component: BusinessLocations,
    },
    {
        isAdmin: true,
        path: '/business/vouchers',
        exact: true,
        component: BusinessVouchers,
    },
    {
        isAdmin: true,
        path: '/business/sales',
        exact: false,
        component: BusinessSales,
    },
    {
        isAdmin: true,
        path: '/business/templates',
        exact: false,
        component: BusinessTemplates,
    },
    {
        isAdmin: true,
        path: '/business/services',
        exact: false,
        component: BusinessServices,
    },
];
var Routes = function () {
    var history = useHistory();
    var location = useLocation();
    useEffect(function () {
        page();
    }, [history.location.pathname]);
    return (_jsx(Layout, { children: _jsx(Suspense, __assign({ fallback: Fallback }, { children: _jsxs(Switch, { children: [routes.map(function (_a, i) {
                        var isAdmin = _a.isAdmin, route = __rest(_a, ["isAdmin"]);
                        return isAdmin ? (_jsx(AdminRoute, { render: route.component, exact: route.exact, path: route.path }, i)) : (_jsx(Route, { exact: route.exact, component: route.component, path: route.path }, i));
                    }), _jsx(Redirect, { from: "/", to: "/dashboard" }, void 0), _jsx(Route, { path: "/*", component: NotFound }, void 0)] }, void 0) }), void 0) }, void 0));
};
export default withRouter(Routes);
var templateObject_1;
