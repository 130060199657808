var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useLocation } from 'react-router-dom';
import DesktopSideNavigation from 'components/DesktopSideNavigation';
import DesktopTopNavigation from 'components/DesktopTopNavigation';
import MobileNavigation from 'components/Navigation';
import { useMobile } from 'context/MobileContext';
var turnOffNavigation = [
    '/choose-account',
    '/onboard',
    '/onboard/invoice-profile',
    '/onboard/opening-hours',
    '/onboard/team',
    '/onboard/services',
];
export var Layout = function (_a) {
    var children = _a.children;
    var location = useLocation();
    var displayNavigation = !turnOffNavigation.includes(location.pathname);
    var _b = useMobile(), isMobile = _b.isMobile, isNative = _b.isNative, hasNotch = _b.hasNotch;
    if (!displayNavigation) {
        return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}, void 0), children] }, void 0));
    }
    if (isMobile) {
        return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}, void 0), _jsx(Content, __assign({ "data-native": isNative, "data-notch": hasNotch }, { children: children }), void 0), _jsx(MobileNavigation, {}, void 0)] }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}, void 0), _jsxs(Root, { children: [_jsx(DesktopSideNavigation, {}, void 0), _jsxs(Main, { children: [_jsx(DesktopTopNavigation, {}, void 0), _jsx(Content, { children: children }, void 0)] }, void 0)] }, void 0)] }, void 0));
};
export default Layout;
var Root = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-grow: 1;\n"], ["\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-grow: 1;\n"])));
var Main = styled.main(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: calc(100vw - 216px);\n  display: flex;\n  flex-flow: column;\n"], ["\n  width: calc(100vw - 216px);\n  display: flex;\n  flex-flow: column;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-bottom: 60px;\n\n  @media (min-width: 744px) {\n    padding-bottom: 0;\n  }\n"], ["\n  padding-bottom: 60px;\n\n  @media (min-width: 744px) {\n    padding-bottom: 0;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
