import environment from './environment';
export var supportedLocales = ['en-US', 'pl'];
export default {
    env: environment.env,
    graphqlUrl: environment.graphqlUrl,
    commitHash: environment.commitHash,
    maxAttachmentSize: environment.maxAttachmentSize,
    version: environment.version,
    tinymc: environment.tinymc,
    sentryDns: environment.sentryDns,
    segmentWriteKey: environment.segmentWriteKey,
    intercomApp: environment.intercomApp,
    stripePublishableKey: environment.stripePublishableKey,
    keycloakClientId: environment.keycloakClientId,
    keycloakUrl: environment.keycloakUrl,
    keycloakAuthUrl: environment.keycloakAuthUrl,
    keycloakRealm: environment.keycloakRealm,
};
