import { defineMessages } from 'react-intl';
export default defineMessages({
    statusUpdated: {
        id: 'app.components.Modals.ShowAppointmentDetails.statusUpdated',
        defaultMessage: 'Status has been updated!',
    },
    confirmCancelTitle: {
        id: 'app.components.Modals.ShowAppointmentDetails.confirmCancelTitle',
        defaultMessage: 'Cancel appointment',
    },
    confirmCancelDescription: {
        id: 'app.components.Modals.ShowAppointmentDetails.confirmCancelDescription',
        defaultMessage: 'Are you sure that you want to cancel appointment?',
    },
});
