var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { withFormik } from 'formik';
import get from 'lodash/get';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import { RecordTemplateFieldTypeEnum } from 'types/graphql';
import sharedMessages from 'translations/shared';
import messages from './messages';
import NameField from './components/NameField';
import TypeField from './components/TypeField';
import RequiredField from './components/RequiredField';
import SendField from './components/SendField';
import OptionsField from './components/OptionsField';
var isOption = function (type) {
    return [
        RecordTemplateFieldTypeEnum.Checkbox,
        RecordTemplateFieldTypeEnum.Radio,
        RecordTemplateFieldTypeEnum.Select,
    ].includes(type);
};
var validationSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup
        .mixed()
        .oneOf(Object.values(RecordTemplateFieldTypeEnum))
        .default(RecordTemplateFieldTypeEnum.ShortText)
        .required(),
    isRequired: yup.boolean().default(false),
    sendViaEmail: yup.boolean().default(false),
    options: yup
        .array()
        .of(yup.string())
        .when('type', {
        is: isOption,
        then: yup.array().of(yup.string().required()),
    }),
});
var View = function (_a) {
    var setFieldValue = _a.setFieldValue, setFieldTouched = _a.setFieldTouched, submitForm = _a.submitForm, values = _a.values, isValid = _a.isValid, errors = _a.errors, touched = _a.touched;
    var formatMessage = useIntl().formatMessage;
    var closeDrawer = useDrawer().closeDrawer;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(messages.header) }), void 0), _jsxs(DrawerContent, { children: [_jsx(NameField, { value: values.name, error: errors.name, touched: touched.name, onChange: function (value) { return setFieldValue('name', value); }, onBlur: function () { return setFieldTouched('name'); } }, void 0), _jsx(RequiredField, { value: values.isRequired, error: errors.isRequired, touched: touched.isRequired, onChange: function (value) { return setFieldValue('isRequired', value); }, onBlur: function () { return setFieldTouched('isRequired'); } }, void 0), _jsx(SendField, { value: values.sendViaEmail, error: errors.sendViaEmail, touched: touched.sendViaEmail, onChange: function (value) { return setFieldValue('sendViaEmail', value); }, onBlur: function () { return setFieldTouched('sendViaEmail'); } }, void 0), _jsx(TypeField, { value: values.type, error: errors.type, touched: touched.type, onChange: function (value) {
                            setFieldValue('type', value);
                            if (isOption(value) &&
                                !values.options.length) {
                                setFieldValue('options', ['']);
                            }
                        }, onBlur: function () { return setFieldTouched('type'); } }, void 0), isOption(values.type) && (_jsx(_Fragment, { children: _jsx(OptionsField, { value: values.options, error: errors.options, touched: touched.options, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); }, push: function () { return setFieldValue('options', __spreadArray(__spreadArray([], __read(values.options), false), [''], false)); }, remove: function (idx) {
                                setFieldValue('options', values.options.filter(function (_, index) { return index !== idx; }));
                            } }, void 0) }, void 0))] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ color: "primary", onClick: submitForm, disabled: !isValid, fullWidth: true }, { children: formatMessage(sharedMessages.update) }), void 0) }, void 0)] }), void 0));
};
var Validated = withFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: function (props) { return ({
        name: get(props, 'field.name', ''),
        type: get(props, 'field.type', RecordTemplateFieldTypeEnum.ShortText),
        isRequired: get(props, 'field.isRequired', false),
        sendViaEmail: get(props, 'field.sendViaEmail', true),
        options: get(props, 'field.options', ['']),
    }); },
    handleSubmit: function (values, _a) {
        var setSubmitting = _a.setSubmitting, props = _a.props;
        return __awaiter(void 0, void 0, void 0, function () {
            var payload;
            return __generator(this, function (_b) {
                setSubmitting(true);
                payload = __assign(__assign({}, values), { options: values.type === RecordTemplateFieldTypeEnum.ShortText ||
                        values.type === RecordTemplateFieldTypeEnum.LongText
                        ? []
                        : values.options });
                props.onUpdate(payload);
                return [2];
            });
        });
    },
})(View);
export default Validated;
