var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { NavLink as NavLinkRaw, useHistory } from 'react-router-dom';
import { Icon, Typography } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import { useUser } from 'context/AuthContext';
import sharedMessages from 'translations/shared';
import DrawerTypeEnum from 'types/enums/DrawerTypeEnum';
var View = function () {
    var formatMessage = useIntl().formatMessage;
    var _a = __read(useState(false), 2), open = _a[0], setOpen = _a[1];
    var history = useHistory();
    var openDrawer = useDrawer().openDrawer;
    var _b = useUser(), signOut = _b.signOut, userTenant = _b.userTenant;
    return (_jsx(_Fragment, { children: _jsx(Nav, { children: _jsxs(NavContainer, { children: [_jsxs(NavLink, __assign({ to: "/", activeClassName: "active" }, { children: [_jsx(Icon, { size: 24, name: "dashboard" }, void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "inherit" }, { children: formatMessage(sharedMessages.dashboardMobile) }), void 0)] }), void 0), _jsxs(NavLink, __assign({ to: "/calendar", activeClassName: "active" }, { children: [_jsx(Icon, { size: 24, name: "calendar-days" }, void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "inherit" }, { children: formatMessage(sharedMessages.appointments) }), void 0)] }), void 0), _jsxs(NavLink, __assign({ to: "#", onClick: function () {
                            openDrawer(DrawerTypeEnum.CREATE_APPOINTMENT);
                        } }, { children: [_jsx(Icon, { size: 24, name: "plus" }, void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "inherit" }, { children: formatMessage(sharedMessages.add) }), void 0)] }), void 0), _jsxs(NavLink, __assign({ to: "/clients", activeClassName: "active" }, { children: [_jsx(Icon, { size: 24, name: "users" }, void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "inherit" }, { children: formatMessage(sharedMessages.clients) }), void 0)] }), void 0), _jsxs(NavLink, __assign({ to: "#", onClick: function () {
                            openDrawer(DrawerTypeEnum.MOBILE_MORE);
                        } }, { children: [_jsx(Icon, { size: 24, name: "menu" }, void 0), _jsx(Typography, __assign({ size: "bodySmall", variant: "inherit" }, { children: formatMessage(sharedMessages.more) }), void 0)] }), void 0)] }, void 0) }, void 0) }, void 0));
};
View.displayName = 'MobileNavigation';
export default View;
var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  height: 125px;\n  bottom: -60px;\n  left: 0;\n  right: 0;\n  padding-bottom: 60px;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  position: fixed;\n  z-index: 0;\n"], ["\n  border-top: 1px solid ", ";\n  height: 125px;\n  bottom: -60px;\n  left: 0;\n  right: 0;\n  padding-bottom: 60px;\n  background: ", ";\n  display: flex;\n  align-items: center;\n  position: fixed;\n  z-index: 0;\n"])), function (props) { return props.theme.palette.gray05; }, function (props) { return props.theme.palette.white100; });
var NavContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1 0 auto;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 100%;\n"], ["\n  flex: 1 0 auto;\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 100%;\n"])));
var NavLink = styled(NavLinkRaw)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  hyphens: auto;\n  padding: 0 2px;\n  overflow-wrap: break-word;\n  min-width: 0px;\n  max-width: 20%;\n  border: 0;\n  text-decoration: none;\n  text-align: center;\n  background-color: transparent;\n  cursor: pointer;\n  align-items: center;\n  flex-flow: column;\n  flex: 1 1 0;\n  display: flex;\n  color: ", ";\n\n  svg {\n    fill: ", ";\n  }\n\n  &:hover {\n    color: ", ";\n\n    svg {\n      fill: ", ";\n    }\n  }\n\n  &.active {\n    color: ", ";\n\n    // svg {\n    //   fill: ", ";\n    // }\n  }\n\n  ", " {\n    margin-top: 4px;\n    font-size: 10px;\n  }\n"], ["\n  hyphens: auto;\n  padding: 0 2px;\n  overflow-wrap: break-word;\n  min-width: 0px;\n  max-width: 20%;\n  border: 0;\n  text-decoration: none;\n  text-align: center;\n  background-color: transparent;\n  cursor: pointer;\n  align-items: center;\n  flex-flow: column;\n  flex: 1 1 0;\n  display: flex;\n  color: ", ";\n\n  svg {\n    fill: ", ";\n  }\n\n  &:hover {\n    color: ", ";\n\n    svg {\n      fill: ", ";\n    }\n  }\n\n  &.active {\n    color: ", ";\n\n    // svg {\n    //   fill: ", ";\n    // }\n  }\n\n  ", " {\n    margin-top: 4px;\n    font-size: 10px;\n  }\n"])), function (props) { return props.theme.palette.gray80; }, function (props) { return props.theme.palette.gray80; }, function (props) { return props.theme.palette.gray100; }, function (props) { return props.theme.palette.gray100; }, function (props) { return props.theme.palette.gray100; }, function (props) { return props.theme.palette.primary50; }, Typography);
var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var VisibleContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 64px;\n  position: relative;\n  z-index: 13;\n  background: #fff;\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 64px;\n  position: relative;\n  z-index: 13;\n  background: #fff;\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;\n"])));
var BrandWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding-left: 24px;\n  display: flex;\n  align-items: center;\n  height: 64px;\n"], ["\n  padding-left: 24px;\n  display: flex;\n  align-items: center;\n  height: 64px;\n"])));
var BrandIcon = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 32px;\n  cursor: pointer;\n"], ["\n  height: 32px;\n  cursor: pointer;\n"])));
var MenuWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;\n  top: 64px;\n  overflow: hidden;\n  position: absolute;\n  bottom: 0px;\n  transform: ", ";\n  transition: transform 0.4s ease 0s;\n  width: 100%;\n  z-index: 12;\n  background-color: rgb(255, 255, 255);\n  pointer-events: all;\n"], ["\n  box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;\n  top: 64px;\n  overflow: hidden;\n  position: absolute;\n  bottom: 0px;\n  transform: ", ";\n  transition: transform 0.4s ease 0s;\n  width: 100%;\n  z-index: 12;\n  background-color: rgb(255, 255, 255);\n  pointer-events: all;\n"])), function (props) {
    return props.open ? 'translateY(0px)' : 'translateY(-100%)';
});
var Dialog = styled.div.attrs({
    role: 'dialog',
})(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 100%;\n  overflow-y: auto;\n"], ["\n  height: 100%;\n  overflow-y: auto;\n"])));
var Menu = styled.ul(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 18px 24px;\n  background: #fff;\n  list-style: none;\n  margin: 0px;\n"], ["\n  padding: 18px 24px;\n  background: #fff;\n  list-style: none;\n  margin: 0px;\n"])));
var MenuItem = styled.li(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin: 0px;\n  overflow-wrap: break-word;\n  font-size: 16px;\n  font-weight: 400;\n  color: #1a1a1a;\n"], ["\n  margin: 0px;\n  overflow-wrap: break-word;\n  font-size: 16px;\n  font-weight: 400;\n  color: #1a1a1a;\n"])));
var MenuLink = styled(NavLink)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: inherit;\n  padding: 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  position: relative;\n  pointer-events: ", ";\n  opacity: ", ";\n"], ["\n  color: inherit;\n  padding: 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  text-decoration: none;\n  position: relative;\n  pointer-events: ", ";\n  opacity: ", ";\n"])), function (props) { return (props.disabled ? 'none' : 'all'); }, function (props) { return (props.disabled ? 0.3 : 1); });
var Divider = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  margin: 16px 0;\n  border-bottom: 1px solid rgb(235, 235, 235);\n"], ["\n  margin: 16px 0;\n  border-bottom: 1px solid rgb(235, 235, 235);\n"])));
var Right = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var MobileButton = styled.button(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  position: relative;\n  background: transparent;\n  cursor: pointer;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  height: 24px;\n  width: 24px;\n  font-size: 24px;\n  margin-right: 24px;\n  outline: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: relative;\n  background: transparent;\n  cursor: pointer;\n  border: 0;\n  margin: 0;\n  padding: 0;\n  height: 24px;\n  width: 24px;\n  font-size: 24px;\n  margin-right: 24px;\n  outline: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
