var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import TextField from './components/Text';
import ClientField from './components/Client';
var View = function (_a) {
    var isLoading = _a.isLoading, onSend = _a.onSend, clients = _a.clients;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            to: yup.string().required(formatMessage(sharedMessages.required)),
            message: yup.string().required(formatMessage(sharedMessages.required)),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        isInitialValid: true,
        initialValues: {
            to: clients[0].phoneNumber,
            message: '',
        },
        onSubmit: function (values) {
            onSend({
                to: values.to,
                message: values.message,
            });
        },
    });
    var submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.sendSMSMessage) }), void 0), _jsxs(DrawerContent, { children: [_jsx(ClientField, { clients: clients, value: values.to, error: errors.to, touched: touched.to, onChange: function (value) { return setFieldValue('to', value); }, onBlur: function () { return setFieldTouched('to'); } }, void 0), _jsx(TextField, { value: values.message, error: errors.message, touched: touched.message, onChange: function (value) { return setFieldValue('message', value); }, onBlur: function () { return setFieldTouched('message'); } }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isLoading, fullWidth: true }, { children: isLoading
                        ? formatMessage(sharedMessages.loading)
                        : formatMessage(sharedMessages.send) }), void 0) }, void 0)] }), void 0));
};
export default View;
