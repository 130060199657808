var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import createLocationMutation from './mutation.graphql';
import View from './View';
var Container = function (_a) {
    var onError = _a.onError, onSuccess = _a.onSuccess;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _b = __read(useMutation(createLocationMutation, {
        onCompleted: function (data) {
            if (onSuccess)
                onSuccess(data.addLocation);
            track(SegmentTrackableEnum.LOCATION_CREATED, __assign({}, data));
            enqueueSnackbar(formatMessage(sharedMessages.addedLocation), {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            if (onError)
                onError(error);
        },
    }), 2), createLocation = _b[0], createResult = _b[1];
    var onCreate = function (input) {
        createLocation({
            variables: {
                input: input,
            },
        });
    };
    return _jsx(View, { onCreate: onCreate, isProcessing: createResult.loading }, void 0);
};
export default Container;
