var LocalStorage;
(function (LocalStorage) {
    LocalStorage["ACCESS_TOKEN"] = "Petsly.accessToken";
    LocalStorage["TENANT_ID"] = "Petsly.tenantId";
})(LocalStorage || (LocalStorage = {}));
var UserDataSource = (function () {
    function UserDataSource() {
    }
    UserDataSource.setToken = function (token) {
        localStorage.setItem(LocalStorage.ACCESS_TOKEN, token);
        if (!token) {
            return undefined;
        }
        return token;
    };
    UserDataSource.getTenantId = function () {
        var token = localStorage.getItem(LocalStorage.TENANT_ID);
        if (!token) {
            return undefined;
        }
        return token;
    };
    UserDataSource.setTenantId = function (tenantId) {
        localStorage.setItem(LocalStorage.TENANT_ID, tenantId);
        if (!tenantId) {
            localStorage.removeItem(LocalStorage.TENANT_ID);
            return undefined;
        }
        return tenantId;
    };
    UserDataSource.clearStorage = function () {
        localStorage.clear();
    };
    UserDataSource.getToken = function () {
        var token = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
        if (!token) {
            return undefined;
        }
        return token;
    };
    return UserDataSource;
}());
export { UserDataSource };
export default UserDataSource;
