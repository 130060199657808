var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, Button, FormGroupHeader, FormGroupContent, FormHelperText, SingleSelect, Input, Icon, } from '@petsly/shared-client';
var View = function (_a) {
    var services = _a.services, values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur, push = _a.push, remove = _a.remove;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsxs(FormGroupHeader, { children: [_jsx(FormSubHeading, { children: formatMessage(messages.items) }, void 0), _jsx(Button, __assign({ size: "small", onClick: function () {
                            push();
                        } }, { children: formatMessage(messages.add) }), void 0)] }, void 0), _jsx(FormGroupContent, { children: _jsx("div", __assign({ style: { marginBottom: 16 } }, { children: values.map(function (entry, idx) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
                        return (_jsxs(Entry, { children: [_jsxs(ServiceInput, { children: [_jsxs(SingleSelect, __assign({ name: "items[" + idx + "].serviceId", value: values[idx].serviceId, onChange: function (ev) {
                                                onChange("items[" + idx + "].serviceId", ev.currentTarget.value);
                                            }, onBlur: function () {
                                                onBlur("items[" + idx + "].serviceId");
                                            }, error: ((_a = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _a === void 0 ? void 0 : _a.serviceId) &&
                                                !isNil((_b = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _b === void 0 ? void 0 : _b.serviceId) }, { children: [_jsx("option", __assign({ disabled: true, value: "" }, { children: formatMessage(messages.select) }), void 0), services.map(function (service) { return (_jsxs("option", __assign({ value: service.id }, { children: [service.name, " - ", service.amount / 100] }), service.id)); })] }), void 0), ((_c = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _c === void 0 ? void 0 : _c.serviceId) &&
                                            !isNil((_d = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _d === void 0 ? void 0 : _d.serviceId) && (_jsx(FormHelperText, __assign({ error: true }, { children: (_e = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _e === void 0 ? void 0 : _e.serviceId }), void 0))] }, void 0), _jsxs(QuantityInput, { children: [_jsx(Input, { name: "items[" + idx + "].quantity", type: "number", value: values[idx].quantity, onChange: function (ev) {
                                                onChange("items[" + idx + "].quantity", ev.currentTarget.value);
                                            }, onBlur: function () {
                                                onBlur("items[" + idx + "].quantity");
                                            }, error: ((_f = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _f === void 0 ? void 0 : _f.quantity) && !isNil((_g = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _g === void 0 ? void 0 : _g.quantity) }, void 0), ((_h = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _h === void 0 ? void 0 : _h.quantity) &&
                                            !isNil((_j = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _j === void 0 ? void 0 : _j.quantity) && (_jsx(FormHelperText, __assign({ error: true }, { children: (_k = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _k === void 0 ? void 0 : _k.quantity }), void 0))] }, void 0), values.length >= 2 && (_jsx(RemoveButton, { children: _jsx(Button, __assign({ variant: "secondary", color: "alert", onClick: function () { return remove(idx); } }, { children: _jsx(Icon, { size: 24, name: "remove" }, void 0) }), void 0) }, void 0))] }, idx));
                    }) }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
var Entry = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: flex;\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])));
var ServiceInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-grow: 1;\n  margin-right: 16px;\n"], ["\n  flex-grow: 1;\n  margin-right: 16px;\n"])));
var QuantityInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 70px;\n\n  :not(:last-child) {\n    margin-right: 16px;\n  }\n"], ["\n  width: 70px;\n\n  :not(:last-child) {\n    margin-right: 16px;\n  }\n"])));
var RemoveButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 48px;\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"], ["\n  width: 48px;\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
