var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import getUserQuery from './query.graphql';
import createUserMutation from './addUser.graphql';
import updateUserMutation from './updateUser.graphql';
import View from './View';
var Container = function (props) {
    var onError = props.onError, onSuccess = props.onSuccess, type = props.type;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _a = __read(useLazyQuery(getUserQuery), 2), getUser = _a[0], getResult = _a[1];
    var _b = __read(useMutation(createUserMutation, {
        onCompleted: function (data) {
            if (onSuccess)
                onSuccess(data.addUser);
            track(SegmentTrackableEnum.USER_CREATED, __assign({}, data));
            enqueueSnackbar(formatMessage(sharedMessages.addedEmployee), {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            if (onError)
                onError(error);
        },
    }), 2), createUser = _b[0], createResult = _b[1];
    var _c = __read(useMutation(updateUserMutation, {
        onCompleted: function (data) {
            if (onSuccess)
                onSuccess(data.updateUser);
            track(SegmentTrackableEnum.USER_EDITED, __assign({}, data));
            enqueueSnackbar(formatMessage(sharedMessages.updatedEmployee), {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            if (onError)
                onError(error);
        },
    }), 2), updateUser = _c[0], updateResult = _c[1];
    useEffect(function () {
        if (props.type === 'edit') {
            getUser({
                variables: {
                    input: {
                        id: props.id,
                    },
                },
            });
        }
    }, []);
    var onCreate = function (input) {
        createUser({
            variables: {
                input: input,
            },
        });
    };
    var onUpdate = function (input) {
        updateUser({
            variables: {
                input: input,
            },
        });
    };
    return (_jsx(View, { type: type, onCreate: onCreate, onUpdate: onUpdate, error: createResult.error || updateResult.error || getResult.error, isProcessing: createResult.loading || updateResult.loading, isLoading: getResult.loading, user: get(getResult, 'data.getEmployeeById') }, void 0));
};
export default Container;
