var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { ApolloLink, ApolloClient, InMemoryCache, from as fromLink, } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import isNil from 'lodash/isNil';
import { createApolloFetch } from 'apollo-fetch';
import { createUploadLink } from 'apollo-upload-client';
import userDataSource from 'dataSources/userDataSource';
import config from '../config';
var OPTS = {
    uri: config.graphqlUrl,
};
var httpLink = ApolloLink.split(function (operation) { return operation.getContext().hasUpload; }, createUploadLink(OPTS), new BatchHttpLink(OPTS));
var errorLink = onError(function (_a) {
    var e_1, _b;
    var _c;
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, operation = _a.operation, forward = _a.forward;
    if (graphQLErrors) {
        try {
            for (var graphQLErrors_1 = __values(graphQLErrors), graphQLErrors_1_1 = graphQLErrors_1.next(); !graphQLErrors_1_1.done; graphQLErrors_1_1 = graphQLErrors_1.next()) {
                var err = graphQLErrors_1_1.value;
                if (!err.extensions || !err.extensions.code) {
                    return forward(operation);
                }
                if ((_c = err === null || err === void 0 ? void 0 : err.message) === null || _c === void 0 ? void 0 : _c.startsWith('Access denied!')) {
                    console.error('Access denied!');
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (graphQLErrors_1_1 && !graphQLErrors_1_1.done && (_b = graphQLErrors_1.return)) _b.call(graphQLErrors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    if (networkError) {
        console.log("[Network error]: " + networkError);
    }
});
var fetch = createApolloFetch({
    uri: config.graphqlUrl,
});
fetch.use(function (_a, next) {
    var options = _a.options;
    var token = userDataSource.getToken();
    if (!options.headers) {
        options.headers = {};
    }
    ;
    options.headers.authorization = !isNil(token) ? token : '';
    next();
});
var authMiddleware = setContext(function (_req, _a) {
    var headers = _a.headers;
    return __awaiter(void 0, void 0, void 0, function () {
        var token, tenantId;
        return __generator(this, function (_b) {
            token = userDataSource.getToken();
            tenantId = userDataSource.getTenantId();
            return [2, {
                    headers: __assign(__assign({}, headers), { authorization: !isNil(token) ? token : '', 'x-tenant-id': tenantId }),
                }];
        });
    });
});
var stripTypenamesFromVariablesLink = new ApolloLink(function (operation, forward) {
    if (operation.variables.hasOwnProperty('file'))
        return forward(operation);
    if (operation.variables) {
        var omitTypename = function (key, value) {
            return key === '__typename' ? undefined : value;
        };
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
});
var client = new ApolloClient({
    link: fromLink([
        errorLink,
        authMiddleware,
        stripTypenamesFromVariablesLink,
        httpLink,
    ]),
    cache: new InMemoryCache(),
    assumeImmutableResults: false,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
        query: {
            fetchPolicy: 'network-only',
        },
    },
});
export default client;
export { fetch };
