var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, FormLabel, Input, SingleSelect, Checkbox, Row, Col, CountrySelector, } from '@petsly/shared-client';
var View = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var locations = _a.locations, value = _a.value, isCustom = _a.isCustom, customLocation = _a.customLocation, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.location) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsxs(SingleSelect, __assign({ name: "locationId", id: "locationId", value: value, onChange: function (ev) {
                                            onChange(ev.currentTarget.name, ev.currentTarget.value);
                                        }, onBlur: function (ev) {
                                            onBlur(ev.currentTarget.name);
                                        }, error: touched.locationId && !isNil(errors.locationId), disabled: isCustom }, { children: [_jsx("option", __assign({ value: "" }, { children: formatMessage(messages.select) }), void 0), locations.map(function (l) {
                                                var _a;
                                                return (_jsx("option", __assign({ value: l.id }, { children: (_a = l.name) !== null && _a !== void 0 ? _a : formatMessage(messages.notProvided) }), l.id));
                                            })] }), void 0) }), void 0), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Checkbox, { label: formatMessage(messages.isCustomLocation), checked: isCustom, onChange: function () {
                                            onChange('isCustomLocation', !isCustom);
                                        } }, void 0) }), void 0)] }, void 0), isCustom && (_jsxs(_Fragment, { children: [_jsx(Row, { children: _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.country), " "] }, void 0), _jsx(CountrySelector, { name: "customLocation.country", variant: "large", value: (_b = customLocation === null || customLocation === void 0 ? void 0 : customLocation.country) !== null && _b !== void 0 ? _b : 'PL', defaultValue: "PL", onChange: function (_a) {
                                                    var value = _a.value;
                                                    onChange('customLocation.country', value);
                                                }, error: touched.country && !isNil(errors.country), language: window.__localeId__ === 'pl' ? 'pl' : 'en', placeholder: formatMessage(messages.select) }, void 0)] }), void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.line1), " "] }, void 0), _jsx(Input, { name: "customLocation.line1", value: (_c = customLocation.line1) !== null && _c !== void 0 ? _c : undefined, onChange: function (ev) {
                                                    onChange(ev.currentTarget.name, ev.currentTarget.value);
                                                }, onBlur: function (ev) {
                                                    onBlur(ev.currentTarget.name);
                                                }, error: touched.line1 && !isNil(errors.line1) }, void 0), touched.line1 && !isNil(errors.line1) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.line1 }), void 0))] }, void 0) }, void 0), _jsx(Row, { children: _jsxs(Col, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.line2), " "] }, void 0), _jsx(Input, { name: "line2", value: (_d = customLocation.line2) !== null && _d !== void 0 ? _d : undefined, onChange: function (ev) {
                                                    onChange(ev.currentTarget.name, ev.currentTarget.value);
                                                }, onBlur: function (ev) {
                                                    onBlur(ev.currentTarget.name);
                                                }, error: touched.line2 && !isNil(errors.line2) }, void 0), touched.line2 && !isNil(errors.line2) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.line2 }), void 0))] }, void 0) }, void 0), _jsxs(Row, { children: [_jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.city), " "] }, void 0), _jsx(Input, { name: "customLocation.city", value: (_e = customLocation.city) !== null && _e !== void 0 ? _e : undefined, onChange: function (ev) {
                                                        onChange(ev.currentTarget.name, ev.currentTarget.value);
                                                    }, onBlur: function (ev) {
                                                        onBlur(ev.currentTarget.name);
                                                    }, error: touched.city && !isNil(errors.city) }, void 0), touched.city && !isNil(errors.city) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.city }), void 0))] }), void 0), _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.state), " "] }, void 0), _jsx(Input, { name: "customLocation.state", value: (_f = customLocation.state) !== null && _f !== void 0 ? _f : undefined, onChange: function (ev) {
                                                        onChange(ev.currentTarget.name, ev.currentTarget.value);
                                                    }, onBlur: function (ev) {
                                                        onBlur(ev.currentTarget.name);
                                                    }, error: touched.state && !isNil(errors.state) }, void 0), touched.state && !isNil(errors.state) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.state }), void 0))] }), void 0)] }, void 0), _jsx(Row, { children: _jsxs(Col, __assign({ md: 6 }, { children: [_jsxs(FormLabel, { children: [formatMessage(messages.postalCode), " "] }, void 0), _jsx(Input, { name: "customLocation.postalCode", value: (_g = customLocation.postalCode) !== null && _g !== void 0 ? _g : undefined, onChange: function (ev) {
                                                    onChange(ev.currentTarget.name, ev.currentTarget.value);
                                                }, onBlur: function (ev) {
                                                    onBlur(ev.currentTarget.name);
                                                }, error: touched.postalCode && !isNil(errors.postalCode) }, void 0), touched.postalCode && !isNil(errors.postalCode) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.postalCode }), void 0))] }), void 0) }, void 0)] }, void 0))] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.locations, nextProps.locations) &&
        isEqual(prevProps.isCustom, nextProps.isCustom) &&
        isEqual(prevProps.customLocation, nextProps.customLocation) &&
        isEqual(prevProps.value, nextProps.value) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
