var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useDrawer } from 'context/DrawerContext';
import query from './query.graphql';
import updateInvoiceMutation from './updateInvoice.graphql';
import View from './View';
var Container = function (_a) {
    var id = _a.id;
    var _b = useQuery(query, {
        variables: {
            input: {
                id: id,
            },
        },
    }), data = _b.data, loading = _b.loading, error = _b.error;
    var closeDrawer = useDrawer().closeDrawer;
    var history = useHistory();
    var _c = __read(useMutation(updateInvoiceMutation, {
        onCompleted: function (data) {
            closeDrawer();
            history.push("/invoices/" + data.updateInvoice.id);
        },
    }), 2), update = _c[0], updateResult = _c[1];
    var client = useMemo(function () { return get(data, 'getInvoiceById.client', null); }, [data]);
    var clients = useMemo(function () { return get(data, 'getClients.items', []); }, [data]);
    var services = useMemo(function () { return get(data, 'getServices.edges', []).map(function (_a) {
        var node = _a.node;
        return node;
    }); }, [data]);
    var taxRates = useMemo(function () { return get(data, 'getTaxRates', []); }, [data]);
    var invoice = useMemo(function () { return get(data, 'getInvoiceById', null); }, [data]);
    var onUpdate = function (input) {
        update({
            variables: {
                input: __assign({}, input),
            },
        });
    };
    return (_jsx(View, { client: client, clients: clients, services: services, taxRates: taxRates, invoice: invoice, isLoading: loading, isProcessing: updateResult.loading, error: error || updateResult.error, onUpdate: onUpdate }, void 0));
};
export default Container;
