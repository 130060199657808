var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import get from 'lodash/get';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import useModal from 'hooks/useModal';
import sharedMessages from 'translations/shared';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import query from './query.graphql';
import removeMutation from './remove.graphql';
import updateStatusMutation from './updateStatus.graphql';
import View from './View';
import messages from './messages';
var Container = function (_a) {
    var onError = _a.onError, onSuccess = _a.onSuccess, appointmentId = _a.appointmentId, rest = __rest(_a, ["onError", "onSuccess", "appointmentId"]);
    var closeModal = useModal().closeModal;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _b = useQuery(query, {
        variables: {
            input: {
                id: appointmentId,
            },
        },
    }), data = _b.data, loading = _b.loading, error = _b.error, refetch = _b.refetch;
    var _c = __read(useMutation(removeMutation, {
        variables: {
            input: {
                id: appointmentId,
            },
        },
        onCompleted: function () {
            enqueueSnackbar(formatMessage(sharedMessages.removedAppointment), {
                variant: 'success',
            });
            if (onSuccess)
                onSuccess();
            if (rest.onRemove)
                rest.onRemove();
            closeModal();
        },
        onError: function (err) {
            enqueueSnackbar(err.message, { variant: 'error' });
        },
    }), 2), removeAppointment = _c[0], onRemoveProps = _c[1];
    var _d = __read(useMutation(updateStatusMutation, {
        onCompleted: function () {
            enqueueSnackbar(formatMessage(messages.statusUpdated), {
                variant: 'success',
            });
            track(SegmentTrackableEnum.APPOINTMENT_STATUS_UPDATED, {
                id: appointmentId,
            });
            if (onSuccess)
                onSuccess();
            refetch();
        },
        onError: function (err) {
            enqueueSnackbar(err.message, { variant: 'error' });
            if (onError)
                onError(err);
        },
    }), 2), updateStatus = _d[0], updateStatusProps = _d[1];
    var onRemove = function () {
        removeAppointment();
    };
    var onStatusUpdate = function (status) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4, updateStatus({
                        variables: {
                            input: {
                                id: appointmentId,
                                status: status,
                            },
                        },
                    })];
                case 1:
                    _a.sent();
                    return [2];
            }
        });
    }); };
    return (_jsx(View, { appointment: get(data, 'getAppointmentById'), error: error, isLoading: loading, refetch: refetch, onStatusUpdate: onStatusUpdate, onStatusUpdateProps: updateStatusProps, onUpdated: rest.onUpdated, onRemove: onRemove, onRemoveProps: onRemoveProps, currency: get(data, 'getBusinessInvoiceProfile.currency'), calendarPrefix: get(data, 'currentUser.preferences.calendarPrefix', '') }, void 0));
};
export default Container;
