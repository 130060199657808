import config from 'config';
export var load = function () {
    var _a, _b;
    if ((_b = (_a = window === null || window === void 0 ? void 0 : window.analytics) === null || _a === void 0 ? void 0 : _a.load) !== null && _b !== void 0 ? _b : false) {
        window.analytics.load(config.segmentWriteKey);
    }
};
export var page = function () {
    window.analytics.page();
};
export var track = function (name, properties) {
    window.analytics.track(name, properties);
};
export var identify = function (userId, properties) {
    window.analytics.identify(userId, properties);
};
export var SegmentTrackableEnum;
(function (SegmentTrackableEnum) {
    SegmentTrackableEnum["SIGNED_IN"] = "Signed In";
    SegmentTrackableEnum["SIGNED_UP"] = "Signed Up";
    SegmentTrackableEnum["SIGNED_OUT"] = "Signed Out";
    SegmentTrackableEnum["ONBOARD"] = "Onboard";
    SegmentTrackableEnum["FORGOT_PASSWORD"] = "Forgot Password";
    SegmentTrackableEnum["PREMIUM_BANNER_CLICKED"] = "Premium Banner Clicked";
    SegmentTrackableEnum["PROFILE_CLICKED"] = "Profile Clicked";
    SegmentTrackableEnum["ACCOUNT_UPDATED"] = "Account Updated";
    SegmentTrackableEnum["ACCOUNT_SMS_NOTIFICATIONS_UPDATED"] = "Account SMS Notifications Updated";
    SegmentTrackableEnum["ACCOUNT_EMAIL_NOTIFICATIONS_UPDATED"] = "Account Email Notifications Updated";
    SegmentTrackableEnum["ACCOUNT_REMINDER_TIME_UPDATED"] = "Account Reminder Timne Updated";
    SegmentTrackableEnum["SMS_PACKAGE_CLICKED"] = "SMS Package Clicked";
    SegmentTrackableEnum["APPOINTMENT_CLICKED"] = "Appointment Clicked";
    SegmentTrackableEnum["APPOINTMENT_VIEWED"] = "Appointment Viewed";
    SegmentTrackableEnum["APPOINTMENT_CREATION_BY_CLIENT_CLICKED"] = "Appointment Creation by Client Clicked";
    SegmentTrackableEnum["APPOINTMENT_CREATED_BY_CLIENT"] = "Appointment Created by Client";
    SegmentTrackableEnum["APPOINTMENT_CREATION_CLICKED"] = "Appointment Creation Clicked";
    SegmentTrackableEnum["APPOINTMENT_CREATED"] = "Appointment Created";
    SegmentTrackableEnum["APPOINTMENT_EDIT_CLICKED"] = "Appointment Edit Clicked";
    SegmentTrackableEnum["APPOINTMENT_EDITED"] = "Appointment Edited";
    SegmentTrackableEnum["APPOINTMENT_PARTICIPANT_ADDED"] = "Appointment Participant Added";
    SegmentTrackableEnum["APPOINTMENT_PARTICIPANT_REMOVED"] = "Appointment Participant Removed";
    SegmentTrackableEnum["APPOINTMENT_SERVICE_ADDED"] = "Appointment Service Added";
    SegmentTrackableEnum["APPOINTMENT_REMOVED_ADDED"] = "Appointment Service Removed";
    SegmentTrackableEnum["APPOINTMENT_REMOVE_CLICKED"] = "Appointment Remove Clicked";
    SegmentTrackableEnum["APPOINTMENT_REMOVED"] = "Appointment Removed";
    SegmentTrackableEnum["APPOINTMENT_STATUS_UPDATED"] = "Appointment Status Updated";
    SegmentTrackableEnum["PET_CLICKED"] = "Pet Clicked";
    SegmentTrackableEnum["PET_VIEWED"] = "Pet Viewed";
    SegmentTrackableEnum["PET_CREATION_CLICKED"] = "Pet Creation Clicked";
    SegmentTrackableEnum["PET_CREATED"] = "Pet Created";
    SegmentTrackableEnum["LOCATION_CREATED"] = "Location Created";
    SegmentTrackableEnum["LOCATION_EDITED"] = "Location Edited";
    SegmentTrackableEnum["PET_EDIT_CLICKED"] = "Pet Edit Clicked";
    SegmentTrackableEnum["PET_EDITED"] = "Pet Edited";
    SegmentTrackableEnum["PET_REMOVE_CLICKED"] = "Pet Remove Clicked";
    SegmentTrackableEnum["PET_REMOVED"] = "Pet Removed";
    SegmentTrackableEnum["CLIENT_CLICKED"] = "Client Clicked";
    SegmentTrackableEnum["CLIENT_VIEWED"] = "Client Viewed";
    SegmentTrackableEnum["CLIENT_CREATION_CLICKED"] = "Client Creation Clicked";
    SegmentTrackableEnum["CLIENT_CREATED"] = "Client Created";
    SegmentTrackableEnum["CLIENT_EDIT_CLICKED"] = "Client Edit Clicked";
    SegmentTrackableEnum["CLIENT_EDITED"] = "Client Edited";
    SegmentTrackableEnum["CLIENT_REMOVE_CLICKED"] = "Client Remove Clicked";
    SegmentTrackableEnum["CLIENT_REMOVED"] = "Client Removed";
    SegmentTrackableEnum["CLIENT_SMS_NOTIFICATIONS_UPDATED"] = "Client SMS Notifactions Updated";
    SegmentTrackableEnum["CLIENT_EMAIL_NOTIFICATIONS_UPDATED"] = "Client Email Notifactions Updated";
    SegmentTrackableEnum["INVOICE_CLICKED"] = "Invoice Clicked";
    SegmentTrackableEnum["INVOICE_VIEWED"] = "Invoice Viewed";
    SegmentTrackableEnum["INVOICE_CREATION_CLICKED"] = "Invoice Creation Clicked";
    SegmentTrackableEnum["INVOICE_CREATED"] = "Invoice Created";
    SegmentTrackableEnum["INVOICE_EDIT_CLICKED"] = "Invoice Edit Clicked";
    SegmentTrackableEnum["INVOICE_EDITED"] = "Invoice Edited";
    SegmentTrackableEnum["INVOICE_REMOVE_CLICKED"] = "Invoice Remove Clicked";
    SegmentTrackableEnum["INVOICE_REMOVED"] = "Invoice Removed";
    SegmentTrackableEnum["INVOICE_PRINTED"] = "Invoice Printed";
    SegmentTrackableEnum["INVOICE_DOWNLOADED"] = "Invoice Downloaded";
    SegmentTrackableEnum["DOCUMENT_CLICKED"] = "Document Clicked";
    SegmentTrackableEnum["DOCUMENT_VIEWED"] = "Document Viewed";
    SegmentTrackableEnum["DOCUMENT_CREATION_CLICKED"] = "Document Creation Clicked";
    SegmentTrackableEnum["DOCUMENT_CREATED"] = "Document Created";
    SegmentTrackableEnum["DOCUMENT_EDIT_CLICKED"] = "Document Edit Clicked";
    SegmentTrackableEnum["DOCUMENT_EDITED"] = "Document Edited";
    SegmentTrackableEnum["DOCUMENT_REMOVE_CLICKED"] = "Document Remove Clicked";
    SegmentTrackableEnum["DOCUMENT_REMOVED"] = "Document Removed";
    SegmentTrackableEnum["DOCUMENT_EMAILED"] = "Document Emailed";
    SegmentTrackableEnum["DOCUMENT_PRINTED"] = "Document Printed";
    SegmentTrackableEnum["SERVICE_CREATION_CLICKED"] = "Service Creation Clicked";
    SegmentTrackableEnum["SERVICE_CREATED"] = "Service Created";
    SegmentTrackableEnum["SERVICE_EDIT_CLICKED"] = "Service Edit Clicked";
    SegmentTrackableEnum["SERVICE_EDITED"] = "Service Edited";
    SegmentTrackableEnum["SERVICE_REMOVE_CLICKED"] = "Service Remove Clicked";
    SegmentTrackableEnum["SERVICE_REMOVED"] = "Service Removed";
    SegmentTrackableEnum["SERVICE_CATEGORY_CREATION_CLICKED"] = "Service Category Creation Clicked";
    SegmentTrackableEnum["SERVICE_CATEGORY_CREATED"] = "Service Category Created";
    SegmentTrackableEnum["SERVICE_CATEGORY_EDIT_CLICKED"] = "Service Category Edit Clicked";
    SegmentTrackableEnum["SERVICE_CATEGORY_EDITED"] = "Service Category Edited";
    SegmentTrackableEnum["SERVICE_CATEGORY_REMOVE_CLICKED"] = "Service Category Remove Clicked";
    SegmentTrackableEnum["SERVICE_CATEGORY_REMOVED"] = "Service Category Removed";
    SegmentTrackableEnum["TAX_RATE_CREATION_CLICKED"] = "Tax Rate Creation Clicked";
    SegmentTrackableEnum["TAX_RATE_CREATED"] = "Tax Rate Created";
    SegmentTrackableEnum["TAX_RATE_EDIT_CLICKED"] = "Tax Rate Edit Clicked";
    SegmentTrackableEnum["TAX_RATE_EDITED"] = "Tax Rate Edited";
    SegmentTrackableEnum["TAX_RATE_REMOVE_CLICKED"] = "Tax Rate Remove Clicked";
    SegmentTrackableEnum["TAX_RATE_REMOVED"] = "Tax Rate Removed";
    SegmentTrackableEnum["USER_CREATION_CLICKED"] = "User Creation Clicked";
    SegmentTrackableEnum["USER_CREATED"] = "User Created";
    SegmentTrackableEnum["USER_EDIT_CLICKED"] = "User Edit Clicked";
    SegmentTrackableEnum["USER_EDITED"] = "User Edited";
    SegmentTrackableEnum["USER_REMOVE_CLICKED"] = "User Remove Clicked";
    SegmentTrackableEnum["USER_REMOVED"] = "User Removed";
    SegmentTrackableEnum["USER_ACTIVATED"] = "User Activated";
    SegmentTrackableEnum["USER_DEACTIVATED"] = "User Deactivated";
    SegmentTrackableEnum["TEMPLATE_CREATION_CLICKED"] = "Template Creation Clicked";
    SegmentTrackableEnum["TEMPLATE_CREATED"] = "Template Created";
    SegmentTrackableEnum["TEMPLATE_EDIT_CLICKED"] = "Template Edit Clicked";
    SegmentTrackableEnum["TEMPLATE_EDITED"] = "Template Edited";
    SegmentTrackableEnum["TEMPLATE_REMOVE_CLICKED"] = "Template Remove Clicked";
    SegmentTrackableEnum["TEMPLATE_REMOVED"] = "Template Removed";
    SegmentTrackableEnum["TEMPLATE_FIELD_ADDED"] = "Template Field Added";
    SegmentTrackableEnum["TEMPLATE_FIELD_REMOVED"] = "Template Field Removed";
    SegmentTrackableEnum["WEBINAR_EDIT_CLICKED"] = "Webinar Edit Clicked";
    SegmentTrackableEnum["WEBINAR_REMOVED"] = "Webinar Removed";
    SegmentTrackableEnum["WEBINAR_REMOVE_CLICKED"] = "Webinar Remove Clicked";
    SegmentTrackableEnum["WEBINAR_VIEWED"] = "Webinar Viewed";
    SegmentTrackableEnum["WEBINAR_CREATED"] = "Webinar Created";
    SegmentTrackableEnum["WEBINAR_UPDATED"] = "Webinar Updated";
    SegmentTrackableEnum["WEBINAR_CLICKED"] = "Webinar Clicked";
    SegmentTrackableEnum["VOUCHER_CREATED"] = "Voucher Created";
    SegmentTrackableEnum["VOUCHER_EDITED"] = "Voucher Updated";
    SegmentTrackableEnum["SEND_MESSAGE_CLICKED"] = "Send Message Clicked";
    SegmentTrackableEnum["SEND_MESSAGE_CLOSED"] = "Send Message Closed";
    SegmentTrackableEnum["SEND_MESSAGE_SENT"] = "Send Message Sent";
    SegmentTrackableEnum["SEND_MESSAGE_ERROR"] = "Send Message Error";
    SegmentTrackableEnum["HISTORIC_NOTES_CLICKED"] = "Historic Notes Clicked";
    SegmentTrackableEnum["CLS"] = "CLS";
    SegmentTrackableEnum["FID"] = "FID";
    SegmentTrackableEnum["FCP"] = "FCP";
    SegmentTrackableEnum["LCP"] = "LCP";
    SegmentTrackableEnum["getTTFB"] = "LCP";
})(SegmentTrackableEnum || (SegmentTrackableEnum = {}));
export default window.analytics;
