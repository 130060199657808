var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { default as ReactSelect, components } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import messages from 'translations/shared';
var View = function (props) {
    var formatMessage = useIntl().formatMessage;
    var theme = useTheme();
    var sharedStyles = {
        control: function (provided, state) { return (__assign(__assign({}, provided), { minHeight: 48, borderRadius: 4, cursor: 'pointer', borderColor: state.isFocused
                ? theme.palette.primary.main
                : provided.borderColor, boxShadow: state.isFocused
                ? "0 0 0 0px " + theme.palette.primary.main
                : provided.boxShadow, '&:hover': {
                borderColor: theme.palette.primary.main,
                boxShadow: state.isFocused
                    ? "0 0 0 0px " + theme.palette.primary.main
                    : provided.boxShadow,
            } })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isSelected ? '#fff' : provided.color, background: state.isSelected
                ? theme.palette.primary.main
                : state.isFocused
                    ? theme.palette.primary.light
                    : provided.background })); },
        valueContainer: function (provided, _state) { return (__assign(__assign({}, provided), { padding: '8px 12px' })); },
        multiValue: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: 25, backgroundColor: theme.palette.primary.main, border: 'none' })); },
        multiValueLabel: function (provided, _state) { return (__assign(__assign({}, provided), { paddingLeft: 8, color: '#fff', fontWeight: 500 })); },
        multiValueRemove: function (provided, _state) { return (__assign(__assign({}, provided), { borderTopRightRadius: 25, borderBottomRightRadius: 25, cursor: 'pointer', opacity: 0.6, marginTop: 1, color: '#fff', fontWeight: 500, '&:hover': {
                opacity: 1,
                backgroundColor: 'inherit',
                color: '#fff',
            } })); },
    };
    var MultiValueRemove = function (props) {
        return (_jsx(components.MultiValueRemove, __assign({}, props, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle }, void 0) }), void 0));
    };
    var CrossIcon = function (props) {
        return (_jsx(components.CrossIcon, __assign({}, props, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle }, void 0) }), void 0));
    };
    var sharedComponents = {
        MultiValueRemove: MultiValueRemove,
        CrossIcon: CrossIcon,
    };
    return (_jsx(ReactSelect, __assign({ components: sharedComponents, styles: __assign({ multiValue: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: 25, backgroundColor: 'transparent', border: '1px solid rgba(0, 0, 0, 0.23)', '& > div': {
                    padding: '3px 6px 3px 3px',
                } })); }, multiValueLabel: function (provided, _state) { return (__assign(__assign({}, provided), { padding: 3 })); } }, sharedStyles), hideSelectedOptions: true, noOptinoOptionsMessage: function () { return formatMessage(messages.noOptions); }, placeholder: formatMessage(messages.select) }, props), void 0));
};
export default View;
