import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'querystring';
import { useUser } from 'context/AuthContext';
import { identify } from 'factory/SegmentFactory';
import Routes from '../Routes';
var View = function () {
    var location = useLocation();
    var history = useHistory();
    var _a = useUser(), setUser = _a.setUser, user = _a.user, userTenant = _a.userTenant;
    useEffect(function () {
        if (userTenant) {
            setUser(user);
            identify(user.id, {
                name: userTenant.name,
                email: user.email,
            });
        }
    }, [userTenant]);
    function onUserSignIn() {
        var search = location.search;
        setUser(user);
        if (search) {
            search = search.slice(1);
            var query = qs.parse(search);
            if (!query.redirect)
                return;
            history.push(query.redirect);
        }
        else {
            history.push('/');
        }
    }
    var childProps = {
        onUserSignIn: onUserSignIn,
    };
    return _jsx(Routes, { childProps: childProps }, void 0);
};
export default View;
