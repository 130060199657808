var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import sharedMessages from 'translations/shared';
import { Typography } from '@petsly/shared-client';
import SimpleSelect from '../SimpleSelect';
var View = function (_a) {
    var filterOptions = _a.filterOptions, timeframeOptions = _a.timeframeOptions, changeInput = _a.changeInput;
    var formatMessage = useIntl().formatMessage;
    var selected = useMemo(function () {
        return timeframeOptions.filter(function (item) { var _a; return (_a = filterOptions.timeframe) === null || _a === void 0 ? void 0 : _a.includes(item.value); });
    }, [filterOptions]);
    return (_jsxs(Item, { children: [_jsx(Heading, { children: formatMessage(sharedMessages.timeframe) }, void 0), _jsx(SimpleSelect, { options: timeframeOptions, onUpdate: function (values) {
                    changeInput(values === null || values === void 0 ? void 0 : values.value);
                }, selectedOptions: selected, placeholder: "", isMulti: false, isClearable: true, allowSelectAll: false }, void 0)] }, void 0));
};
export default View;
var Item = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n"], ["\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n"])));
var Heading = styled(Typography).attrs({
    size: 'h100',
    variant: 'secondary',
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 24px;\n"], ["\n  margin-bottom: 24px;\n"])));
var templateObject_1, templateObject_2;
