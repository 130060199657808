var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import getServiceCategoryQuery from './query.graphql';
import updateServiceCategoryMutation from './updateServiceCategory.graphql';
import View from './View';
var Container = function (props) {
    var onError = props.onError, onSuccess = props.onSuccess, serviceCategoryId = props.serviceCategoryId;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _a = __read(useLazyQuery(getServiceCategoryQuery), 2), getServiceCategory = _a[0], getResult = _a[1];
    var _b = __read(useMutation(updateServiceCategoryMutation, {
        onCompleted: function (data) {
            if (onSuccess)
                onSuccess(data.updateServiceCategory);
            track(SegmentTrackableEnum.SERVICE_CATEGORY_EDITED, __assign({}, props));
            enqueueSnackbar(formatMessage(sharedMessages.updatedServiceCategory), {
                variant: 'success',
            });
            closeDrawer();
        },
        onError: function (error) {
            enqueueSnackbar(error.message, {
                variant: 'error',
            });
            if (onError)
                onError(error);
        },
    }), 2), updateServiceCategory = _b[0], updateResult = _b[1];
    useEffect(function () {
        getServiceCategory({
            variables: {
                id: serviceCategoryId,
            },
        });
    }, []);
    var onUpdate = function (input) {
        updateServiceCategory({
            variables: {
                input: input,
            },
        });
    };
    return (_jsx(View, { onEdit: onUpdate, isLoading: getResult.loading, isProcessing: updateResult.loading, error: updateResult.error || getResult.error, serviceCategory: get(getResult, 'data.getServiceCategoryById') }, void 0));
};
export default Container;
