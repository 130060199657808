var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useState, useEffect, useContext, createContext, useCallback, useRef, } from 'react';
import styled from 'styled-components';
import keycloakInit from 'config/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useMutation } from '@apollo/client';
import userDataSource from 'dataSources/userDataSource';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import CurrentUserQuery from './mutation.graphql';
var keycloakProviderInitConfig = {
    onLoad: 'login-required',
    'ssl-required': 'none',
    checkLoginIframe: false,
};
var UserContext = createContext({
    user: undefined,
    userTenant: undefined,
    refreshUser: function () { },
    signOut: function () { },
    changeTenant: function () { },
    setUser: function () { },
    setUserTenant: function () { },
    hasRole: function () { return false; },
    tenant: undefined,
});
var UserConsumer = UserContext.Consumer;
var UserProvider = function (props) {
    var history = useHistory();
    var _a = __read(useState(), 2), user = _a[0], setUser = _a[1];
    var _b = __read(useState(), 2), userTenant = _b[0], setUserTenant = _b[1];
    var _c = __read(useState(), 2), tenant = _c[0], setTenant = _c[1];
    var _d = __read(useState(false), 2), isTokenLoaded = _d[0], setIsTokenLoaded = _d[1];
    var _e = __read(useMutation(CurrentUserQuery), 2), getUser = _e[0], userData = _e[1].data;
    var keycloak = useRef(null);
    var signOut = function () {
        if (!keycloak || !keycloak.current)
            return;
        keycloak.current.props.authClient.logout();
        userDataSource.clearStorage();
        if (window.Intercom) {
            ;
            window.Intercom('shutdown');
        }
    };
    var hasRole = function (role) {
        var tenantId = userDataSource.getTenantId();
        if (!user || !tenantId)
            return false;
        return user.tenantsObj[tenantId].role === role;
    };
    var onKeycloakTokens = useCallback(function (tokens) {
        userDataSource.setToken(tokens.token);
        setIsTokenLoaded(true);
    }, []);
    var changeTenant = useCallback(function (tenantId) {
        setTenant(tenantId);
        userDataSource.setTenantId(tenantId);
    }, []);
    useEffect(function () {
        if (isTokenLoaded)
            getUser();
    }, [isTokenLoaded]);
    useEffect(function () {
        var _a, _b, _c;
        if (!isTokenLoaded || !(userData === null || userData === void 0 ? void 0 : userData.bootstrapUser))
            return;
        var loggedUser = userData.bootstrapUser;
        var user = {
            id: loggedUser.id,
            email: loggedUser.email,
            tenantsObj: loggedUser.tenants.reduce(function (obj, tenant) {
                var _a;
                return Object.assign(obj, (_a = {}, _a[tenant.tenant.id] = tenant, _a));
            }, {}),
            tenants: loggedUser.tenants,
        };
        var tenantId = userDataSource.getTenantId();
        setUser(user);
        var language = (_c = (_b = (_a = loggedUser === null || loggedUser === void 0 ? void 0 : loggedUser.preferences) === null || _a === void 0 ? void 0 : _a.language) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== null && _c !== void 0 ? _c : 'en';
        window.__localeId__ = language;
        localStorage.setItem('Petsly.__localeId__', language);
        if (tenantId)
            return;
        if (user.tenants.length === 1) {
            var tenantId_1 = user.tenants[0].tenant.id;
            userDataSource.setTenantId(tenantId_1);
            setTenant(tenantId_1);
        }
        else {
            history.push('/choose-account');
        }
    }, [isTokenLoaded, userData, tenant]);
    useEffect(function () {
        var tenantId = userDataSource.getTenantId();
        setTenant(tenantId);
    }, []);
    useEffect(function () {
        if (!user || !tenant)
            return;
        var userTenant = user.tenantsObj[tenant];
        if (!userTenant) {
            history.push('/choose-account');
            setTenant(null);
            return;
        }
        if (window && window.Intercom) {
            var action = 'update';
            var attributes = {
                email: user.email,
                user_id: user.id,
                name: userTenant.name,
            };
            window.Intercom(action, attributes);
        }
        setUserTenant(userTenant);
    }, [tenant, user]);
    function refreshUser() {
        getUser();
    }
    var values = useMemo(function () { return ({
        user: user,
        userTenant: userTenant,
        signOut: signOut,
        setUser: setUser,
        setUserTenant: setUserTenant,
        tenant: tenant,
        changeTenant: changeTenant,
        hasRole: hasRole,
        refreshUser: refreshUser,
    }); }, [user, userTenant, tenant, changeTenant, hasRole]);
    return (_jsx(ReactKeycloakProvider, __assign({ ref: keycloak, authClient: keycloakInit, initOptions: keycloakProviderInitConfig, onTokens: onKeycloakTokens }, { children: isTokenLoaded && user ? (_jsx(UserContext.Provider, __assign({ value: values }, { children: props.children }), void 0)) : (_jsx(Fallback, {}, void 0)) }), void 0));
};
var useUser = function () {
    var context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within an UserProvider');
    }
    return context;
};
export { UserProvider, UserConsumer, useUser };
var FallbackWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 64px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 64px);\n"], ["\n  position: absolute;\n  top: 64px;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 64px);\n"])));
var Fallback = function () { return (_jsx(FallbackWrapper, { children: _jsx(CircularProgress, { size: 64 }, void 0) }, void 0)); };
var templateObject_1;
