import { useEffect, useRef } from 'react';
export function useInterval(callback, delay) {
    if (delay === void 0) { delay = 1000; }
    var savedCallback = useRef();
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        function tick() {
            if (savedCallback) {
                savedCallback.current();
            }
        }
        if (delay !== null) {
            var id_1 = setInterval(tick, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
}
