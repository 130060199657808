var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, ToggleButton, ToggleButtonGroup, } from '@petsly/shared-client';
var DateViewField = function (_a) {
    var handleView = _a.handleView, view = _a.view, messages = _a.messages;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: messages.dateRange }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsx("div", __assign({ style: { marginBottom: 16 } }, { children: _jsxs(ToggleButtonGroup, __assign({ size: "medium" }, { children: [_jsx(ToggleButton, __assign({ active: view === 'month', onClick: function () {
                                    handleView('month');
                                } }, { children: messages.month }), void 0), _jsx(ToggleButton, __assign({ active: view === 'week', onClick: function () {
                                    handleView('week');
                                } }, { children: messages.week }), void 0), _jsx(ToggleButton, __assign({ active: view === 'day', onClick: function () {
                                    handleView('day');
                                } }, { children: messages.day }), void 0)] }), void 0) }), void 0) }, void 0)] }), void 0));
};
export default DateViewField;
