var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import sharedMessages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, SingleSelect, } from '@petsly/shared-client';
import { RecordTemplateFieldTypeEnum } from 'types/graphql';
import messages from './../messages';
var NameField = function (_a) {
    var value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsxs(FormSubHeading, { children: [formatMessage(messages.type), "*"] }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsx(SingleSelect, __assign({ name: "type", value: value, onChange: function (ev) {
                                onChange(ev.currentTarget.value);
                            }, onBlur: function () {
                                onBlur();
                            }, error: touched && !isNil(error) }, { children: Object.keys(RecordTemplateFieldTypeEnum).map(function (key) { return (_jsx("option", __assign({ value: RecordTemplateFieldTypeEnum[key] }, { children: formatMessage(sharedMessages["TEMPLATE_TYPE_" + RecordTemplateFieldTypeEnum[key]]) }), key)); }) }), void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.value === nextProps.value &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(NameField, shouldRerender);
