var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, Button, Icon, } from '@petsly/shared-client';
import { AppointmentTypeEnum } from 'types/graphql';
import SingleSelect from 'components/SingleSelect';
import { useDrawer } from 'context/DrawerContext';
import DrawerTypeEnum from 'types/enums/DrawerTypeEnum';
var View = function (_a) {
    var pets = _a.pets, type = _a.type, values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur, push = _a.push, remove = _a.remove, refetch = _a.refetch;
    var formatMessage = useIntl().formatMessage;
    var openDrawer = useDrawer().openDrawer;
    var options = useMemo(function () {
        return pets.map(function (p) {
            var _a, _b, _c;
            return ({
                value: p.id,
                label: p.name + " (" + ((_a = p === null || p === void 0 ? void 0 : p.owner) === null || _a === void 0 ? void 0 : _a.name) + ")",
                ownerId: (_c = (_b = p === null || p === void 0 ? void 0 : p.owner) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : '',
            });
        });
    }, [pets]);
    var valueFromId = function (opts, id) { return opts.find(function (o) { return o.value === id; }); };
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsxs(FormGroupHeader, { children: [_jsx(FormSubHeading, { children: formatMessage(type === AppointmentTypeEnum.IndividualClient
                            ? messages.participant
                            : messages.participants) }, void 0), _jsxs("div", { children: [_jsx(Button, __assign({ size: "small", variant: "link", onClick: function () {
                                    openDrawer(DrawerTypeEnum.QUICK_ADD_CLIENT_AND_PET, {
                                        onSuccess: function (pet) {
                                            refetch();
                                        },
                                    });
                                } }, { children: formatMessage(messages.create) }), void 0), type === AppointmentTypeEnum.GroupClient && (_jsx(Button, __assign({ size: "small", onClick: function () {
                                    push();
                                } }, { children: formatMessage(messages.add) }), void 0))] }, void 0)] }, void 0), _jsx(FormGroupContent, { children: _jsx("div", __assign({ style: { marginBottom: 16 } }, { children: values.map(function (entry, idx) {
                        var _a, _b, _c;
                        return (_jsxs(Entry, { children: [_jsxs(PetInput, __assign({ hasRemove: values.length >= 2 }, { children: [_jsx(SingleSelect, { options: options, onChange: function (selected) {
                                                if (!selected)
                                                    return;
                                                onChange("participants[" + idx + "].petId", selected.value);
                                                onChange("participants[" + idx + "].clientId", selected.ownerId);
                                            }, onBlur: function () {
                                                onBlur("participants[" + idx + "].petId");
                                                onBlur("participants[" + idx + "].clientId");
                                            }, value: valueFromId(options, values[idx].petId) }, void 0), ((_a = touched === null || touched === void 0 ? void 0 : touched[idx]) === null || _a === void 0 ? void 0 : _a.petId) && !isNil((_b = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _b === void 0 ? void 0 : _b.petId) && (_jsx(FormHelperText, __assign({ error: true }, { children: (_c = errors === null || errors === void 0 ? void 0 : errors[idx]) === null || _c === void 0 ? void 0 : _c.petId }), void 0))] }), void 0), values.length >= 2 && (_jsx(RemoveButton, { children: _jsx(Button, __assign({ variant: "secondary", color: "alert", onClick: function () { return remove(idx); } }, { children: _jsx(Icon, { size: 24, name: "remove" }, void 0) }), void 0) }, void 0))] }, idx));
                    }) }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.clients, nextProps.clients) &&
        isEqual(prevProps.type, nextProps.type) &&
        isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
var Entry = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  position: relative;\n\n  @media (max-width: 744px) {\n    flex-direction: column;\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  display: ", ";\n  position: relative;\n\n  @media (max-width: 744px) {\n    flex-direction: column;\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])), function (props) { return (props.hide ? 'none' : 'flex'); });
var PetInput = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n\n  @media (max-width: 744px) {\n    width: ", ";\n    margin-right: 0;\n  }\n\n  :not(:last-child) {\n    margin-right: 16px;\n\n    @media (max-width: 744px) {\n      margin-right: 0;\n    }\n  }\n"], ["\n  width: 100%;\n\n  @media (max-width: 744px) {\n    width: ", ";\n    margin-right: 0;\n  }\n\n  :not(:last-child) {\n    margin-right: 16px;\n\n    @media (max-width: 744px) {\n      margin-right: 0;\n    }\n  }\n"])), function (props) { return (props.hasRemove ? 'calc(100% - 56px)' : '100%'); });
var RemoveButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 48px;\n\n  @media (max-width: 744px) {\n    position: absolute;\n    right: 0;\n    height: 48px;\n  }\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n\n    @media (max-width: 744px) {\n      height: 48px;\n    }\n  }\n"], ["\n  width: 48px;\n\n  @media (max-width: 744px) {\n    position: absolute;\n    right: 0;\n    height: 48px;\n  }\n\n  & > button {\n    width: 48px;\n    padding: 11px;\n\n    @media (max-width: 744px) {\n      height: 48px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
