var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import sharedMessages from 'translations/shared';
import { useDrawer } from 'context/DrawerContext';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, } from '@petsly/shared-client';
import { AppointmentResultTypeEnum } from 'types/graphql';
import ClientsField from './components/Clients';
import PetsField from './components/Pets';
import UsersField from './components/Users';
import ServicesField from './components/Services';
import TimeFramesField from './components/TimeFrames';
import QueryField from './components/Query';
var View = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var filter = _a.filter, onFilterUpdate = _a.onFilterUpdate, clients = _a.clients, pets = _a.pets, users = _a.users, services = _a.services, timeframes = _a.timeframes, messages = _a.messages;
    var closeDrawer = useDrawer().closeDrawer;
    var onClose = function () {
        closeDrawer();
    };
    var formatMessage = useIntl().formatMessage;
    var _h = __read(useState({
        clientIds: (_b = filter.clientIds) !== null && _b !== void 0 ? _b : null,
        petIds: (_c = filter.petIds) !== null && _c !== void 0 ? _c : null,
        userIds: (_d = filter.userIds) !== null && _d !== void 0 ? _d : null,
        serviceIds: (_e = filter.serviceIds) !== null && _e !== void 0 ? _e : null,
        timeframe: (_f = filter.timeframe) !== null && _f !== void 0 ? _f : AppointmentResultTypeEnum.All,
        query: (_g = filter.query) !== null && _g !== void 0 ? _g : undefined,
    }), 2), filterOptions = _h[0], setFilterOptions = _h[1];
    var petOptions = useMemo(function () {
        var _a;
        if (((_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.clientIds) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return pets
                .filter(function (_a) {
                var owner = _a.owner;
                return filterOptions.clientIds.includes(owner === null || owner === void 0 ? void 0 : owner.id);
            })
                .map(function (_a) {
                var name = _a.name, id = _a.id;
                return ({
                    label: name,
                    value: id,
                });
            });
        }
        return pets.map(function (_a) {
            var name = _a.name, id = _a.id;
            return ({
                label: name,
                value: id,
            });
        });
    }, [pets, filterOptions.clientIds]);
    var changeInput = function (name) { return function (value) {
        var _a;
        setFilterOptions(__assign(__assign({}, filterOptions), (_a = {}, _a[name] = value, _a)));
    }; };
    var saveFilters = function () {
        onFilterUpdate(filterOptions);
        closeDrawer();
    };
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: onClose }, { children: formatMessage(sharedMessages.filters) }), void 0), _jsxs(DrawerContent, { children: [_jsx(QueryField, { changeInput: changeInput('query'), filterOptions: filterOptions, messages: messages }, void 0), _jsx(ClientsField, { changeInput: changeInput('clientIds'), clientOptions: clients, filterOptions: filterOptions }, void 0), _jsx(PetsField, { changeInput: changeInput('petIds'), petOptions: petOptions, filterOptions: filterOptions }, void 0), _jsx(UsersField, { changeInput: changeInput('userIds'), userOptions: users, filterOptions: filterOptions }, void 0), _jsx(ServicesField, { changeInput: changeInput('serviceIds'), servicesOptions: services, filterOptions: filterOptions }, void 0), _jsx(TimeFramesField, { changeInput: changeInput('timeframe'), timeframeOptions: timeframes, filterOptions: filterOptions }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: saveFilters, fullWidth: true }, { children: formatMessage(sharedMessages.applyFilter) }), void 0) }, void 0)] }), void 0));
};
export default View;
