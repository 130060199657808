var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import NumberFormat from 'react-number-format';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, Typography, SingleSelect, } from '@petsly/shared-client';
var View = function (_a) {
    var services = _a.services, items = _a.items, taxRates = _a.taxRates, value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    var total = useMemo(function () {
        return items.reduce(function (acc, i) {
            var service = services.find(function (s) { return s.id === i.serviceId; });
            if (!service) {
                return acc;
            }
            return (acc += i.quantity * service.amount);
        }, 0);
    }, [items]);
    var taxPercentage = useMemo(function () {
        var _a;
        var taxRate = !value
            ? null
            : taxRates.find(function (t) { return t.id === value; });
        return !taxRate ? 0 : (_a = taxRate === null || taxRate === void 0 ? void 0 : taxRate.percentage) !== null && _a !== void 0 ? _a : 0;
    }, [value]);
    var subtotal = useMemo(function () {
        return total / (1 + taxPercentage / 100);
    }, [taxPercentage, total]);
    var tax = useMemo(function () {
        return total - subtotal;
    }, [total, subtotal]);
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.summary) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsxs(Entry, { children: [_jsx(Typography, __assign({ size: "bodyLarge" }, { children: formatMessage(messages.subtotal) }), void 0), _jsx(Typography, __assign({ size: "bodyLarge" }, { children: _jsx(NumberFormat, { value: subtotal / 100, decimalScale: 2, fixedDecimalScale: true, displayType: "text", thousandSeparator: true }, void 0) }), void 0)] }, void 0), _jsxs(Entry, { children: [_jsxs(Tax, { children: [_jsxs(Wrapper, { children: [_jsx(Typography, __assign({ size: "bodyLarge" }, { children: formatMessage(messages.tax) }), void 0), _jsxs(SingleSelect, __assign({ value: value, name: "vatPercentage", onChange: function (ev) { return onChange(ev.currentTarget.value); }, onBlur: function () { return onBlur(); }, error: touched && !isNil(error) }, { children: [_jsx("option", __assign({ disabled: true, value: "" }, { children: formatMessage(messages.select) }), void 0), JSON.parse(JSON.stringify(taxRates))
                                                            .sort(function (a, b) {
                                                            if (a.percentage && b.percentage) {
                                                                return a.percentage - b.percentage;
                                                            }
                                                            if (!a.percentage) {
                                                                return -1;
                                                            }
                                                            return 1;
                                                        })
                                                            .map(function (taxRate) { return (_jsx("option", __assign({ value: taxRate.id }, { children: taxRate.displayName }), taxRate.id)); })] }), void 0)] }, void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }, void 0), _jsx(Typography, __assign({ size: "bodyLarge" }, { children: _jsx(NumberFormat, { value: tax / 100, decimalScale: 2, fixedDecimalScale: true, displayType: "text", thousandSeparator: true }, void 0) }), void 0)] }, void 0), _jsxs(Entry, { children: [_jsx(Typography, __assign({ size: "bodyLarge" }, { children: formatMessage(messages.total) }), void 0), _jsx(Typography, __assign({ size: "bodyLarge" }, { children: _jsx(NumberFormat, { value: total / 100, decimalScale: 2, fixedDecimalScale: true, displayType: "text", thousandSeparator: true }, void 0) }), void 0)] }, void 0)] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.value, nextProps.value) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.error, nextProps.error) &&
        isEqual(prevProps.items, nextProps.items));
};
export default memo(View, shouldRerender);
var Entry = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  :not(:last-child) {\n    margin-bottom: 16px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  :not(:last-child) {\n    margin-bottom: 16px;\n  }\n"])));
var Tax = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  div {\n    margin-right: 8px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  div {\n    margin-right: 8px;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
