var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import query from './query.graphql';
import View from './View';
var Container = function (_a) {
    var clientId = _a.clientId, petId = _a.petId;
    var _b = __read(useLazyQuery(query), 2), getHistoricNotes = _b[0], getResult = _b[1];
    useEffect(function () {
        getHistoricNotes({
            variables: {
                input: {
                    clientId: clientId,
                    petId: petId,
                },
            },
        });
    }, []);
    var historicNotes = useMemo(function () { return get(getResult, 'data.getParticipantNotes.notes', []); }, [getResult]);
    return (_jsx(View, { error: getResult.error, isLoading: getResult.loading, historicNotes: historicNotes }, void 0));
};
export default Container;
