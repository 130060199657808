var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState, useEffect } from 'react';
import EscapeOutside from 'react-escape-outside';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { isAfter, parseISO } from 'date-fns';
import { useHistory, Link } from 'react-router-dom';
import isNil from 'lodash/isNil';
import NumberFormat from 'react-number-format';
import { track, SegmentTrackableEnum } from 'factory/SegmentFactory';
import format from 'helpers/format';
import displayAddress from 'utils/displayAddress';
import sharedMessages from 'translations/shared';
import { useDrawer } from 'context/DrawerContext';
import DrawerTypeEnum from 'types/enums/DrawerTypeEnum';
import useModal from 'hooks/useModal';
import { currencies } from 'constants/currencies';
import { ModalTypeEnum } from 'components/Modals';
import { AppointmentStatusEnum, } from 'types/graphql';
import formatDuration from 'helpers/formatDuration';
import SimpleSelect from 'components/Select';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, Typography, Badge, Icon, } from '@petsly/shared-client';
var getClientContactDetails = function (client) {
    if (client.phoneNumber && client.email) {
        return client.phoneNumber + " | " + client.email;
    }
    if (client.phoneNumber) {
        return client.phoneNumber;
    }
    if (client.email) {
        return client.email;
    }
    return '-';
};
var statusColor = function (status) {
    var _a;
    var colors = (_a = {},
        _a[AppointmentStatusEnum.Cancelled] = 'alert',
        _a[AppointmentStatusEnum.Unconfirmed] = 'gray',
        _a[AppointmentStatusEnum.NoShow] = 'alert',
        _a[AppointmentStatusEnum.Completed] = 'success',
        _a[AppointmentStatusEnum.Confirmed] = 'info',
        _a);
    return colors[status] || colors[AppointmentStatusEnum.Unconfirmed];
};
var View = function (_a) {
    var _b, _c;
    var appointment = _a.appointment, onUpdated = _a.onUpdated, onRemove = _a.onRemove, onRemoveProps = _a.onRemoveProps, onStatusUpdate = _a.onStatusUpdate, onStatusUpdateProps = _a.onStatusUpdateProps, isLoading = _a.isLoading, currency = _a.currency, calendarPrefix = _a.calendarPrefix;
    useEffect(function () {
        track(SegmentTrackableEnum.APPOINTMENT_VIEWED);
    }, []);
    var history = useHistory();
    var _d = useDrawer(), openDrawer = _d.openDrawer, closeDrawer = _d.closeDrawer;
    var _e = __read(useState({}), 2), open = _e[0], setOpen = _e[1];
    var _f = __read(useState(false), 2), editStatus = _f[0], setEditStatus = _f[1];
    var openModal = useModal().openModal;
    var formatMessage = useIntl().formatMessage;
    var statuses = useMemo(function () {
        return Object.keys(AppointmentStatusEnum).map(function (key) { return ({
            label: formatMessage(sharedMessages["APPOINTMENT_STATUS_" + AppointmentStatusEnum[key]]),
            value: AppointmentStatusEnum[key],
        }); });
    }, []);
    useEffect(function () {
        if (!appointment)
            return;
        setOpen(appointment.participants.reduce(function (acc, p) {
            var _a;
            return (__assign(__assign({}, acc), (_a = {}, _a[p.id] = false, _a)));
        }, {}));
    }, [appointment]);
    var participants = useMemo(function () {
        if (!appointment)
            return null;
        return appointment.participants.map(function (p) {
            var _a, _b;
            var hasRecord = !!(p.record && p.record.id);
            var hasInvoice = !isNil(p.invoice);
            return (_jsxs(Participant, { children: [_jsxs(ParticipantLeft, { children: [_jsxs(Typography, __assign({ size: "bodyLarge" }, { children: [_jsx(Link, __assign({ to: "/clients/" + p.client.id, onClick: function () { return closeDrawer(); } }, { children: p.client.name }), void 0), ' ', "(", _jsx(Link, __assign({ to: "/pets/" + p.pet.id, onClick: function () { return closeDrawer(); } }, { children: p.pet.name }), void 0), ")"] }), void 0), _jsx(Typography, __assign({ size: "bodySmall", color: "gray80" }, { children: getClientContactDetails(p.client) }), void 0)] }, void 0), _jsx(ParticipantRight, { children: _jsx(Actions, { children: _jsxs(EscapeOutside, __assign({ onEscapeOutside: function () {
                                    var _a;
                                    return setOpen(__assign(__assign({}, open), (_a = {}, _a[p.id] = false, _a)));
                                } }, { children: [_jsx(ActionButton, __assign({ onClick: function () {
                                            var _a;
                                            return setOpen(__assign(__assign({}, open), (_a = {}, _a[p.id] = !open[p.id], _a)));
                                        } }, { children: _jsx(Icon, { size: 24, name: "more-ver" }, void 0) }), void 0), open[p.id] && (_jsxs(Dialog, { children: [console.log('p.clientId', p), _jsxs(Menu, { children: [_jsx(MenuItem, __assign({ onClick: function () {
                                                            var _a, _b;
                                                            if (hasInvoice) {
                                                                track(SegmentTrackableEnum.INVOICE_CLICKED, {
                                                                    invoiceId: (_b = (_a = p.invoice) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
                                                                    appointmentId: appointment.id,
                                                                });
                                                                closeDrawer();
                                                                history.push("/invoices/" + p.invoice.id);
                                                            }
                                                            else {
                                                                track(SegmentTrackableEnum.INVOICE_CREATION_CLICKED, {
                                                                    appointmentId: appointment.id,
                                                                    client: p.client.id,
                                                                });
                                                                closeDrawer();
                                                                openDrawer(DrawerTypeEnum.CREATE_INVOICE, {
                                                                    clientId: p.client.id,
                                                                    relationship: {
                                                                        participantId: p.id,
                                                                    },
                                                                    items: appointment.appointmentToServices.map(function (ats) { return ({
                                                                        service: ats.service,
                                                                        quantity: 1,
                                                                    }); }),
                                                                });
                                                            }
                                                        } }, { children: !hasInvoice
                                                            ? formatMessage(sharedMessages.addInvoice)
                                                            : formatMessage(sharedMessages.invoice) + " - " + ((_b = (_a = p.invoice) === null || _a === void 0 ? void 0 : _a.invoiceNumber) !== null && _b !== void 0 ? _b : '') }), void 0), _jsx(MenuItem, __assign({ onClick: function () {
                                                            var _a, _b, _c;
                                                            if (hasRecord) {
                                                                track(SegmentTrackableEnum.DOCUMENT_CLICKED, {
                                                                    petId: p.pet.id,
                                                                    documentId: (_a = p.record) === null || _a === void 0 ? void 0 : _a.id,
                                                                });
                                                                closeDrawer();
                                                                history.push("/documents/" + ((_b = p.record) === null || _b === void 0 ? void 0 : _b.id));
                                                            }
                                                            else {
                                                                track(SegmentTrackableEnum.DOCUMENT_CREATION_CLICKED, {
                                                                    petId: p.pet.id,
                                                                    recordId: (_c = p.record) === null || _c === void 0 ? void 0 : _c.id,
                                                                });
                                                                closeDrawer();
                                                                history.push("/pets/" + p.pet.id + "/documents/new/?participantId=" + p.id);
                                                            }
                                                        } }, { children: !hasRecord
                                                            ? formatMessage(sharedMessages.addRecord)
                                                            : formatMessage(sharedMessages.viewRecord) }), void 0), _jsx(MenuItem, __assign({ disabled: !p.client.phoneNumber, onClick: function () {
                                                            track(SegmentTrackableEnum.SEND_MESSAGE_CLICKED, {
                                                                client: p.client,
                                                            });
                                                            openDrawer(DrawerTypeEnum.SEND_MESSAGE, {
                                                                clients: [p.client],
                                                            });
                                                        } }, { children: formatMessage(sharedMessages.sendSMSMessage) }), void 0), _jsx(MenuItem, __assign({ onClick: function () {
                                                            track(SegmentTrackableEnum.HISTORIC_NOTES_CLICKED, {
                                                                client: p.client,
                                                            });
                                                            openDrawer(DrawerTypeEnum.HISTORIC_NOTES, {
                                                                clientId: p.client.id,
                                                                petId: p.pet.id,
                                                            });
                                                        } }, { children: formatMessage(sharedMessages.historicNotes) }), void 0)] }, void 0)] }, void 0))] }), void 0) }, void 0) }, void 0)] }, p.id));
        });
    }, [appointment, open]);
    if (isLoading)
        return null;
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.appointment) }), void 0), _jsxs(DrawerContent, { children: [_jsxs(Field, { children: [_jsxs(Header, { children: [_jsx(Heading, { children: formatMessage(sharedMessages.status) }, void 0), _jsx(LinkButton, __assign({ onClick: function () { return setEditStatus(!editStatus); } }, { children: formatMessage(editStatus ? sharedMessages.cancel : sharedMessages.change) }), void 0)] }, void 0), _jsx(Body, { children: editStatus ? (_jsx(SimpleSelect, { options: statuses, onChange: function (option) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4, onStatusUpdate(option.value)];
                                                case 1:
                                                    _a.sent();
                                                    setEditStatus(false);
                                                    return [2];
                                            }
                                        });
                                    }); }, value: statuses.find(function (s) { return s.value === appointment.status; }), disabled: onStatusUpdateProps.loading }, void 0)) : (_jsx(Badge, { label: formatMessage(sharedMessages["APPOINTMENT_STATUS_" + appointment.status]), size: "medium", color: statusColor(appointment.status) }, void 0)) }, void 0)] }, void 0), _jsxs(Field, { children: [_jsx(Header, { children: _jsx(Heading, { children: formatMessage(sharedMessages.participants) }, void 0) }, void 0), _jsx(Body, { children: participants }, void 0)] }, void 0), _jsxs(Field, { children: [_jsx(Header, { children: _jsx(Heading, { children: formatMessage(sharedMessages.location) }, void 0) }, void 0), _jsx(Body, { children: _jsx(Location, { children: appointment.location ? (_jsxs(_Fragment, { children: [displayAddress(appointment.location), ' ', _jsx(Icon, { size: 24, name: "map" }, void 0)] }, void 0)) : ('-') }, void 0) }, void 0)] }, void 0), _jsxs(Field, { children: [_jsxs(Header, { children: [_jsx(Heading, { children: formatMessage(sharedMessages.date) }, void 0), _jsx(LinkButton, __assign({ disabled: !isAfter(parseISO(appointment.startAt), new Date()), onClick: function () {
                                            var _a, _b;
                                            Object.assign(document.createElement('a'), {
                                                target: '_blank',
                                                href: "https://calendar.google.com/calendar/r/eventedit?text=" + encodeURI("" + (calendarPrefix ? calendarPrefix + " " : '') + ((_a = appointment === null || appointment === void 0 ? void 0 : appointment.name) !== null && _a !== void 0 ? _a : '').replaceAll('&', '|')) + "&dates=" + format(appointment.startAt, "yyyyMMdd'T'HHmmss") + "/" + format(appointment.endAt, "yyyyMMdd'T'HHmmss") + "&details=" + encodeURI((_b = appointment === null || appointment === void 0 ? void 0 : appointment.sharableNotes) !== null && _b !== void 0 ? _b : '') + "&ctz=" + 'Europe/Warsaw',
                                            }).click();
                                        } }, { children: formatMessage(sharedMessages.addToGoogleCalendar) }), void 0)] }, void 0), _jsx(Body, { children: _jsxs(Typography, __assign({ size: "bodyLarge" }, { children: [format(appointment.startAt, 'PPPP'), ' ', format(appointment.startAt, 'p'), " -", ' ', format(appointment.endAt, 'p')] }), void 0) }, void 0)] }, void 0), _jsxs(Field, { children: [_jsx(Header, { children: _jsx(Heading, { children: formatMessage(sharedMessages.services) }, void 0) }, void 0), _jsx(Body, { children: appointment.appointmentToServices.map(function (as) { return (_jsxs(Service, { children: [_jsxs(ServiceLine, { children: [_jsx(Typography, __assign({ size: "bodyLarge" }, { children: as.service.name + " " }), void 0), _jsxs(Typography, __assign({ size: "bodySmall", color: "secondary" }, { children: ["(", formatDuration({
                                                            hours: Math.floor(as.service.duration / 60),
                                                            minutes: as.service.duration % 60,
                                                        }, { short: true, zero: false }), ")"] }), void 0)] }, void 0), _jsx(ServiceLine, { children: _jsxs(Typography, __assign({ size: "bodySmall", color: "secondary" }, { children: [_jsx(NumberFormat, { value: as.service.amount / 100, decimalScale: currencies[currency].decimal_digits, fixedDecimalScale: true, displayType: "text", thousandSeparator: true }, void 0), currency, " | ", as.employee.name] }), void 0) }, void 0)] }, as.id)); }) }, void 0)] }, void 0), _jsxs(Field, { children: [_jsx(Header, { children: _jsx(Heading, { children: formatMessage(sharedMessages.sharableNotes) }, void 0) }, void 0), _jsx(Body, { children: _jsx(Typography, __assign({ size: "bodyLarge" }, { children: (_b = appointment === null || appointment === void 0 ? void 0 : appointment.sharableNotes) !== null && _b !== void 0 ? _b : '-' }), void 0) }, void 0)] }, void 0), _jsxs(Field, { children: [_jsx(Header, { children: _jsx(Heading, { children: formatMessage(sharedMessages.notes) }, void 0) }, void 0), _jsx(Body, { children: _jsx(BreakLineTypo, __assign({ size: "bodyLarge" }, { children: (_c = appointment === null || appointment === void 0 ? void 0 : appointment.notes) !== null && _c !== void 0 ? _c : '-' }), void 0) }, void 0)] }, void 0)] }, void 0), _jsxs(StyledDrawerActions, { children: [_jsx(Button, __assign({ onClick: function () {
                            track(SegmentTrackableEnum.APPOINTMENT_REMOVE_CLICKED, {
                                id: appointment.id,
                            });
                            openModal(ModalTypeEnum.CONFIRM, {
                                onConfirm: function () { return onRemove(); },
                                onSuccess: function () {
                                    track(SegmentTrackableEnum.APPOINTMENT_REMOVED, {
                                        id: appointment.id,
                                    });
                                    closeDrawer();
                                },
                                isLoading: onRemoveProps.loading,
                                error: onRemoveProps.error,
                                title: formatMessage(sharedMessages.removeAppointment),
                                description: formatMessage(sharedMessages.confirmRemoveAppointment),
                                successMessage: formatMessage(sharedMessages.removedAppointment),
                            });
                        }, color: "alert" }, { children: formatMessage(sharedMessages.remove) }), void 0), _jsx(Button, __assign({ onClick: function () {
                            track(SegmentTrackableEnum.APPOINTMENT_EDIT_CLICKED, {
                                id: appointment.id,
                            });
                            closeDrawer();
                            openDrawer(DrawerTypeEnum.EDIT_APPOINTMENT, {
                                id: appointment.id,
                                onSuccess: function () {
                                    if (onUpdated) {
                                        onUpdated();
                                    }
                                },
                            });
                        } }, { children: formatMessage(sharedMessages.edit) }), void 0)] }, void 0)] }), void 0));
};
export default View;
var Field = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  :not(:first-child) {\n    margin-top: 24px;\n  }\n"], ["\n  :not(:first-child) {\n    margin-top: 24px;\n  }\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 12px;\n"])));
var Heading = styled(Typography).attrs({
    size: 'h300',
})(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
var Body = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var Service = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var ServiceLine = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Participant = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var ParticipantLeft = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
var ParticipantRight = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject([""], [""])));
var Actions = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var ActionButton = styled.button.attrs({
    type: 'button',
})(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  border: 1px solid #ddd;\n  background: transparent;\n  border-radius: 4px;\n  padding: 8px;\n  cursor: pointer;\n  outline: none;\n  font-size: 18px;\n  :hover {\n    background: rgba(26, 26, 26, 0.05);\n  }\n"], ["\n  border: 1px solid #ddd;\n  background: transparent;\n  border-radius: 4px;\n  padding: 8px;\n  cursor: pointer;\n  outline: none;\n  font-size: 18px;\n  :hover {\n    background: rgba(26, 26, 26, 0.05);\n  }\n"])));
var Dialog = styled.div.attrs({
    role: 'dialog',
})(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background: rgb(255, 255, 255);\n  margin-top: -1px;\n  position: absolute;\n  right: 0px;\n  z-index: 2000;\n"], ["\n  background: rgb(255, 255, 255);\n  margin-top: -1px;\n  position: absolute;\n  right: 0px;\n  z-index: 2000;\n"])));
var Menu = styled.ul(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;\n  border-radius: 4px;\n  border: 1px solid #ddd;\n  list-style: none;\n  margin: 0px;\n  padding: 0;\n  background: rgb(255, 255, 255);\n  width: 200px;\n"], ["\n  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 36px 2px !important;\n  border-radius: 4px;\n  border: 1px solid #ddd;\n  list-style: none;\n  margin: 0px;\n  padding: 0;\n  background: rgb(255, 255, 255);\n  width: 200px;\n"])));
var MenuItem = styled.li(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  padding: 12px 16px;\n  cursor: pointer;\n  opacity: ", ";\n\n  :hover {\n    background: rgba(26, 26, 26, 0.05);\n  }\n\n  :not(:last-child) {\n    border-bottom: 1px solid #ddd;\n  }\n"], ["\n  padding: 12px 16px;\n  cursor: pointer;\n  opacity: ", ";\n\n  :hover {\n    background: rgba(26, 26, 26, 0.05);\n  }\n\n  :not(:last-child) {\n    border-bottom: 1px solid #ddd;\n  }\n"])), function (props) { return (props.disabled ? '.6' : '1'); });
var Location = styled(Typography).attrs({
    size: 'bodyLarge',
})(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  svg {\n    margin-left: 8px;\n  }\n"], ["\n  display: flex;\n  svg {\n    margin-left: 8px;\n  }\n"])));
var StyledDrawerActions = styled(DrawerActions)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  justify-content: flex-end;\n\n  button {\n    :not(:last-child) {\n      margin-right: 24px;\n    }\n  }\n"], ["\n  justify-content: flex-end;\n\n  button {\n    :not(:last-child) {\n      margin-right: 24px;\n    }\n  }\n"])));
var LinkButton = styled(Button).attrs({
    variant: 'link',
})(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  padding: 0;\n"], ["\n  padding: 0;\n"])));
var BreakLineTypo = styled(Typography)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  white-space: pre-line;\n"], ["\n  white-space: pre-line;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
