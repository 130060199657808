var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import messages from 'translations/shared';
import { FormGroup, FormLabel, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, Icon, Row, Col, } from '@petsly/shared-client';
var View = function (_a) {
    var values = _a.values, errors = _a.errors, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.eventDate) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsx("div", __assign({ style: { marginBottom: 16 } }, { children: _jsxs(Row, { children: [_jsxs(Col, __assign({ md: 6 }, { children: [_jsx(FormLabel, { children: formatMessage(messages.date) }, void 0), _jsxs(DatePickerWrapper, { children: [_jsx(StyledDatePicker, { minDate: new Date(), selected: values.value, onChange: function (date) { return onChange('estimatedStartedAt', date); }, showPopperArrow: false, onBlur: function () { return onBlur('estimatedStartedAt'); }, error: (touched === null || touched === void 0 ? void 0 : touched.value) && !isNil(errors.value), dateFormat: "dd/MM/yyyy" }, void 0), _jsx(Icon, { size: 24, name: "calendar" }, void 0)] }, void 0), (touched === null || touched === void 0 ? void 0 : touched.value) && !isNil(errors.value) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.value }), void 0))] }), void 0), _jsxs(Col, __assign({ md: 6 }, { children: [_jsx(FormLabel, { children: formatMessage(messages.time) }, void 0), _jsxs(DatePickerWrapper, { children: [_jsx(StyledDatePicker, { selected: values.value, onChange: function (date) { return onChange('estimatedStartedAt', date); }, showPopperArrow: false, onBlur: function () { return onBlur('estimatedStartedAt'); }, error: (touched === null || touched === void 0 ? void 0 : touched.value) && !isNil(errors.value), showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 15, timeCaption: "Time", dateFormat: "H:mm" }, void 0), _jsx(Icon, { size: 24, name: "calendar" }, void 0)] }, void 0), (touched === null || touched === void 0 ? void 0 : touched.value) && !isNil(errors.value) && (_jsx(FormHelperText, __assign({ error: true }, { children: errors.value }), void 0))] }), void 0)] }, void 0) }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (isEqual(prevProps.values, nextProps.values) &&
        isEqual(prevProps.touched, nextProps.touched) &&
        isEqual(prevProps.errors, nextProps.errors));
};
export default memo(View, shouldRerender);
var DatePickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n\n  .react-datepicker-wrapper {\n    width: 100%;\n  }\n\n  svg {\n    position: absolute;\n    top: 12px;\n    right: 16px;\n  }\n"], ["\n  position: relative;\n\n  .react-datepicker-wrapper {\n    width: 100%;\n  }\n\n  svg {\n    position: absolute;\n    top: 12px;\n    right: 16px;\n  }\n"])));
var StyledDatePicker = styled(DatePicker)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 16px;\n  line-height: 24px;\n  color: rgba(26, 26, 26, 1);\n  padding: 11px 15px;\n  background: transparent;\n  border: 1px solid rgba(199, 199, 199, 1);\n  border-radius: 4px;\n  width: 100%;\n  outline: none;\n\n  ", "\n\n  :focus {\n    border-color: rgba(235, 114, 54, 1);\n    ", "\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"], ["\n  font-size: 16px;\n  line-height: 24px;\n  color: rgba(26, 26, 26, 1);\n  padding: 11px 15px;\n  background: transparent;\n  border: 1px solid rgba(199, 199, 199, 1);\n  border-radius: 4px;\n  width: 100%;\n  outline: none;\n\n  ", "\n\n  :focus {\n    border-color: rgba(235, 114, 54, 1);\n    ", "\n  }\n\n  :not(:last-child) {\n    margin-bottom: 8px;\n  }\n"])), function (props) {
    return props.error ? 'border-color: #FF75A7; background: #FFEBE5;' : '';
}, function (props) { return (props.error ? 'border-color: #FF75A7;' : ''); });
var templateObject_1, templateObject_2;
