import { useContext } from 'react';
import { GlobalContext } from '../context/Global';
export default (function () {
    var _a = useContext(GlobalContext), state = _a.state, dispatch = _a.dispatch;
    function openModal(modalType, modalProps) {
        return dispatch({
            type: 'MODAL_OPEN',
            payload: {
                modalType: modalType,
                modalProps: modalProps,
            },
        });
    }
    function closeModal() {
        return dispatch({ type: 'MODAL_CLOSE' });
    }
    return {
        closeModal: closeModal,
        openModal: openModal,
        currentModals: state.currentModals,
    };
});
