function getEnv(name) {
    var env = process.env;
    var ENV = env.REACT_APP_ENV;
    var envKey = name + "_" + ENV;
    return envKey in env ? env[envKey] : env[name];
}
export default {
    env: process.env.REACT_APP_ENV,
    graphqlUrl: getEnv('REACT_APP_GRAPHQL_URL'),
    commitHash: getEnv('REACT_APP_COMMIT_HASH'),
    maxAttachmentSize: getEnv('REACT_APP_MAX_ATTACHMENT_SIZE'),
    version: getEnv('REACT_APP_VERSION'),
    tinymc: getEnv('REACT_APP_TINYMC'),
    sentryDns: getEnv('REACT_APP_SENTRY_DNS'),
    segmentWriteKey: getEnv('REACT_APP_SEGMENT_WRITE_KEY'),
    intercomApp: getEnv('REACT_APP_INTERCOM_APP_ID'),
    stripePublishableKey: getEnv('REACT_APP_STRIPE_PUBLISHABLE_KEY'),
    keycloakClientId: getEnv('REACT_APP_KEYCLOAK_CLIENT_ID'),
    keycloakUrl: getEnv('REACT_APP_KEYCLOAK_URL'),
    keycloakAuthUrl: getEnv('REACT_APP_KEYCLOAK_AUTH_SERVER_URL'),
    keycloakRealm: getEnv('REACT_APP_KEYCLOAK_REALM'),
};
