import { format, parseISO } from 'date-fns';
import { enUS, pl } from 'date-fns/locale';
var locales = { enUS: enUS, pl: pl };
export default function (dateEntry, formatStr) {
    var _a;
    if (formatStr === void 0) { formatStr = 'PP'; }
    var date = typeof dateEntry === 'string' ? parseISO(dateEntry) : dateEntry;
    return format(date, formatStr, {
        locale: locales[(_a = window.__localeId__) !== null && _a !== void 0 ? _a : 'enUS'],
    });
}
