var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserRoleEnum } from 'types/graphql';
import { useUser } from 'context/AuthContext';
var AdminRoute = function (_a) {
    var C = _a.render, rest = __rest(_a, ["render"]);
    var hasRole = useUser().hasRole;
    var userHasRole = hasRole(UserRoleEnum.Admin);
    return (_jsx(Route, __assign({}, rest, { render: function (rProps) {
            return userHasRole ? _jsx(C, __assign({}, rProps), void 0) : _jsx(Redirect, { to: "/no-permissions" }, void 0);
        } }), void 0));
};
export default AdminRoute;
