var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import sharedmessages from 'translations/shared';
import { FormGroup, FormSubHeading, FormHelperText, Input, Button, Icon, Row, Col, } from '@petsly/shared-client';
import styled from 'styled-components';
import messages from './../messages';
var NameField = function (_a) {
    var value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur, push = _a.push, remove = _a.remove;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsxs(FormSubHeading, __assign({ style: { marginBottom: 26 } }, { children: [formatMessage(messages.options), "*", _jsx(AddButton, { children: _jsx(Button, __assign({ size: "small", onClick: function () {
                                push();
                            } }, { children: formatMessage(sharedmessages.add) }), void 0) }, void 0)] }), void 0), value.map(function (entry, idx) { return (_jsx(Row, { children: _jsxs(InLineWrapper, { children: [_jsx(Col, __assign({ md: value.length >= 2 ? 11 : 12 }, { children: _jsx(Input, { name: "options[" + idx + "]", value: entry, onChange: function (ev) {
                                    onChange("options[" + idx + "]", ev.currentTarget.value);
                                }, onBlur: function () {
                                    onBlur("options[" + idx + "]");
                                }, error: touched && !isNil(error) }, void 0) }), void 0), value.length >= 2 && (_jsx(Col, { children: _jsx(RemoveButton, { children: _jsx(Button, __assign({ variant: "secondary", color: "alert", onClick: function () { return remove(idx); } }, { children: _jsx(Icon, { size: 24, name: "remove" }, void 0) }), void 0) }, void 0) }, void 0))] }, void 0) }, void 0)); }), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.value === nextProps.value &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(NameField, shouldRerender);
var RemoveButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 48px;\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"], ["\n  width: 48px;\n  & > button {\n    width: 48px;\n    padding: 11px;\n  }\n"])));
var AddButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: right;\n"], ["\n  float: right;\n"])));
var InLineWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
