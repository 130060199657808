import { defineMessages } from 'react-intl';
export default defineMessages({
    fieldName: {
        id: 'app.components.Drawer.DocumentField.fieldName',
        defaultMessage: 'Name',
    },
    isRequired: {
        id: 'app.components.Drawer.DocumentField.isRequired',
        defaultMessage: 'Is required',
    },
    sendViaEmail: {
        id: 'app.components.Drawer.DocumentField.sendViaEmail',
        defaultMessage: 'Is private',
    },
    type: {
        id: 'app.components.Drawer.DocumentField.type',
        defaultMessage: 'Type',
    },
    options: {
        id: 'app.components.Drawer.DocumentField.options',
        defaultMessage: 'Options',
    },
    info: {
        id: 'app.components.Drawer.DocumentField.info',
        defaultMessage: 'If marked as private it won’t be send to a client',
    },
    header: {
        id: 'app.components.Drawer.DocumentField.header',
        defaultMessage: 'Field Editor',
    },
});
