var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import useModal from 'hooks/useModal';
import getErrorMessage from 'utils/getErrorMessage';
import sharedMessages from 'translations/shared';
var View = function (_a) {
    var isLoading = _a.isLoading, error = _a.error, title = _a.title, description = _a.description, onConfirm = _a.onConfirm, onCancel = _a.onCancel, onSuccess = _a.onSuccess, onError = _a.onError, successMessage = _a.successMessage;
    var closeModal = useModal().closeModal;
    var formatMessage = useIntl().formatMessage;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var handleCancel = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!onCancel) return [3, 2];
                    return [4, onCancel()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    closeModal();
                    return [2];
            }
        });
    }); };
    var handleConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4, onConfirm()];
                case 1:
                    _a.sent();
                    enqueueSnackbar(successMessage || formatMessage(sharedMessages.success), {
                        variant: 'success',
                    });
                    if (onSuccess)
                        onSuccess();
                    closeModal();
                    return [3, 3];
                case 2:
                    err_1 = _a.sent();
                    enqueueSnackbar(err_1.message, { variant: 'error' });
                    if (onError)
                        onError();
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    return (_jsxs(Dialog, __assign({ fullWidth: true, keepMounted: true, open: true, onClose: handleCancel, "aria-labelledby": "confirm-modal" }, { children: [_jsx(DialogTitle, { children: title }, void 0), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: description }, void 0), error && (_jsx(DialogContentText, { children: getErrorMessage(error) }, void 0))] }, void 0), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: handleCancel, startIcon: _jsx(CancelIcon, {}, void 0) }, { children: formatMessage(sharedMessages.cancel) }), void 0), _jsx(Button, __assign({ onClick: handleConfirm, color: "primary", startIcon: isLoading ? _jsx(CircularProgress, {}, void 0) : _jsx(CheckIcon, {}, void 0), disabled: isLoading }, { children: formatMessage(sharedMessages.confirm) }), void 0)] }, void 0)] }), void 0));
};
export default View;
