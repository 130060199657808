var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { formatDuration } from 'date-fns';
import { enUS, pl } from 'date-fns/locale';
var locales = { enUS: enUS, pl: pl };
var formatDistanceLocaleEn = {
    xSeconds: '{{count}}sec',
    xMinutes: '{{count}}min',
    xHours: '{{count}}h',
};
var formatDistanceLocalePl = {
    xSeconds: '{{count}}sek',
    xMinutes: '{{count}}min',
    xHours: '{{count}}g',
};
var shortEnLocale = {
    formatDistance: function (token, count) {
        return formatDistanceLocaleEn[token].replace('{{count}}', count);
    },
};
var shortPlLocale = {
    formatDistance: function (token, count) {
        return formatDistanceLocalePl[token].replace('{{count}}', count);
    },
};
export default function (duration, extend) {
    var _a, _b;
    return formatDuration(duration, __assign({ locale: ((_a = extend === null || extend === void 0 ? void 0 : extend.short) !== null && _a !== void 0 ? _a : false)
            ?
                window.__localeId__ === 'pl'
                    ? shortPlLocale
                    : shortEnLocale
            :
                locales[(_b = window.__localeId__) !== null && _b !== void 0 ? _b : 'enUS'], zero: true }, extend));
}
