var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, DrawerActions, DrawerContent, DrawerHeader, Button, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import EmployeesField from './components/Employees';
import DateViewField from './components/DateView';
var View = function (_a) {
    var handleView = _a.handleView, onEmployeeChange = _a.onEmployeeChange, viewProps = _a.view, employees = _a.employees, resources = _a.resources, messages = _a.messages;
    var _b = __read(useState(resources.map(function (item) {
        return item.id;
    })), 2), employee = _b[0], setEmployee = _b[1];
    var _c = __read(useState({
        view: viewProps,
    }), 2), filterOptions = _c[0], setFilterOptions = _c[1];
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var changeInput = function (name) { return function (value) {
        var _a;
        setFilterOptions(__assign(__assign({}, filterOptions), (_a = {}, _a[name] = value, _a)));
    }; };
    var saveFilters = function () {
        handleView(filterOptions.view);
        onEmployeeChange(employee);
        closeDrawer();
    };
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(sharedMessages.filters) }), void 0), _jsxs(DrawerContent, { children: [_jsx(DateViewField, { handleView: changeInput('view'), view: filterOptions.view, messages: messages }, void 0), _jsx(EmployeesField, { setEmployee: setEmployee, employees: employees, employee: employee, messages: messages }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: saveFilters, fullWidth: true }, { children: formatMessage(sharedMessages.applyFilter) }), void 0) }, void 0)] }), void 0));
};
export default View;
