import { defineMessages } from 'react-intl';
export default defineMessages({
    privacyPolicy: {
        id: 'app.translations.shared.privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    terms: {
        id: 'app.translations.shared.terms',
        defaultMessage: 'Terms of Service',
    },
    allRights: {
        id: 'app.translations.shared.allRights',
        defaultMessage: '© {year} Petsly Global, Inc. All rights reserved.',
    },
    version: {
        id: 'app.translations.shared.version',
        defaultMessage: 'Version',
    },
    chat: {
        id: 'app.translations.shared.chat',
        defaultMessage: 'Chat',
    },
    support: {
        id: 'app.translations.shared.support',
        defaultMessage: 'Support',
    },
    list: {
        id: 'app.translations.shared.list',
        defaultMessage: 'List',
    },
    hiUser: {
        id: 'app.translations.shared.hiUser',
        defaultMessage: 'Hi {name}',
    },
    workspace: {
        id: 'app.translations.shared.workspace',
        defaultMessage: 'Workspace',
    },
    selectedWorkspace: {
        id: 'app.translations.shared.selectedWorkspace',
        defaultMessage: '{name} Workspace',
    },
    changeWorkspace: {
        id: 'app.translations.shared.changeWorkspace',
        defaultMessage: 'Change Workspace',
    },
    freePlan: {
        id: 'app.translations.shared.freePlan',
        defaultMessage: 'Free Plan',
    },
    freePlanInfo: {
        id: 'app.translations.shared.freePlanInfo',
        defaultMessage: 'For the timebeing Petsly is free and it will change in the future with the notice before that.',
    },
    contactSupport: {
        id: 'app.translations.shared.contactSupport',
        defaultMessage: 'Contact Support',
    },
    dashboardMobile: {
        id: 'app.translations.shared.dashboardMobile',
        defaultMessage: 'Dashboard',
    },
    overview: {
        id: 'app.translations.shared.overview',
        defaultMessage: 'Overview',
        description: 'Overview header',
    },
    name: {
        id: 'app.translations.shared.name',
        defaultMessage: 'Name',
        description: 'Patient name header',
    },
    role: {
        id: 'app.translations.shared.role',
        defaultMessage: 'Role',
        description: 'User role',
    },
    firstName: {
        id: 'app.translations.shared.firstName',
        defaultMessage: 'First Name',
        description: 'First name header',
    },
    at: {
        id: 'app.translations.shared.at',
        defaultMessage: 'at',
    },
    upload: {
        id: 'app.translations.shared.upload',
        defaultMessage: 'Upload',
    },
    default: {
        id: 'app.translations.shared.default',
        defaultMessage: 'Default',
    },
    size: {
        id: 'app.translations.shared.size',
        defaultMessage: 'Size',
    },
    fileName: {
        id: 'app.translations.shared.fileName',
        defaultMessage: 'Name',
    },
    lastName: {
        id: 'app.translations.shared.lastName',
        defaultMessage: 'Last Name',
        description: 'Last name header',
    },
    clientName: {
        id: 'app.translations.shared.clientName',
        defaultMessage: 'Name',
        description: 'Client name',
    },
    age: {
        id: 'app.translations.shared.age',
        defaultMessage: 'Age',
    },
    author: {
        id: 'app.translations.shared.author',
        defaultMessage: 'Author',
    },
    ageValue: {
        id: 'app.translations.shared.ageValue',
        defaultMessage: '{years} year(s) {months} month(s)',
    },
    sex: {
        id: 'app.translations.shared.sex',
        defaultMessage: 'Sex',
    },
    document: {
        id: 'app.translations.shared.document',
        defaultMessage: 'Document',
    },
    documents: {
        id: 'app.translations.shared.documents',
        defaultMessage: 'Documents',
    },
    customerCard: {
        id: 'app.translations.shared.customerCard',
        defaultMessage: 'Customer card',
    },
    petCard: {
        id: 'app.translations.shared.petCard',
        defaultMessage: 'Pet card',
    },
    appointmentCard: {
        id: 'app.translations.shared.appointmentCard',
        defaultMessage: 'Appointment card',
    },
    checkInvoice: {
        id: 'app.translations.shared.checkInvoice',
        defaultMessage: 'Check invoice',
    },
    patientName: {
        id: 'app.translations.shared.patientName',
        defaultMessage: 'Patient',
        description: 'Patient name header',
    },
    species: {
        id: 'app.translations.shared.species',
        defaultMessage: 'Species',
        description: 'Species header',
    },
    breed: {
        id: 'app.translations.shared.breed',
        defaultMessage: 'Breed',
        description: 'Breed header',
    },
    owner: {
        id: 'app.translations.shared.owner',
        defaultMessage: 'Owner',
        description: 'Owner header',
    },
    phone: {
        id: 'app.translations.shared.phone',
        defaultMessage: 'Phone',
        description: 'Phone number header',
    },
    workingHours: {
        id: 'app.translations.shared.workingHours',
        defaultMessage: 'Working hours',
    },
    businessPhone: {
        id: 'app.translations.shared.businessPhone',
        defaultMessage: 'Business Phone',
    },
    businessEmail: {
        id: 'app.translations.shared.businessEmail',
        defaultMessage: 'Business Email',
    },
    services: {
        id: 'app.translations.shared.services',
        defaultMessage: 'Services',
        description: 'Services header',
    },
    time: {
        id: 'app.translations.shared.time',
        defaultMessage: 'Time',
        description: 'Time header',
    },
    duration: {
        id: 'app.translations.shared.duration',
        defaultMessage: 'Duration',
        description: "Appointment or service's duration header",
    },
    amount: {
        id: 'app.translations.shared.amount',
        defaultMessage: 'Amount',
        description: "Service's amount header",
    },
    status: {
        id: 'app.translations.shared.status',
        defaultMessage: 'Status',
        description: 'Status header',
    },
    confirmed: {
        id: 'app.translations.shared.confirmed',
        defaultMessage: 'Confirmed',
        description: 'Confirmed appointments header',
    },
    cancelled: {
        id: 'app.translations.shared.cancelled',
        defaultMessage: 'Cancelled',
        description: 'Cancelled appointments header',
    },
    lastUpdate: {
        id: 'app.translations.shared.lastUpdate',
        defaultMessage: 'Last Update',
        description: 'Last update header',
    },
    lastAppointment: {
        id: 'app.translations.shared.lastAppointment',
        defaultMessage: 'Last appointment',
    },
    upcomingAppointment: {
        id: 'app.translations.shared.upcomingAppointment',
        defaultMessage: 'Upcoming appointment',
    },
    id: {
        id: 'app.translations.shared.id',
        defaultMessage: 'ID',
    },
    url: {
        id: 'app.translations.shared.url',
        defaultMessage: 'url',
    },
    sendSMSMessage: {
        id: 'app.translations.shared.sendSMSMessage',
        defaultMessage: 'Send SMS Message',
    },
    message: {
        id: 'app.translations.shared.message',
        defaultMessage: 'Message',
    },
    notes: {
        id: 'app.translations.shared.notes',
        defaultMessage: 'Private notes',
    },
    sharableNotes: {
        id: 'app.translations.shared.sharableNotes',
        defaultMessage: 'Note for clients',
    },
    service: {
        id: 'app.translations.shared.service',
        defaultMessage: 'Service',
    },
    category: {
        id: 'app.translations.shared.category',
        defaultMessage: 'Category',
    },
    facilities: {
        id: 'app.translations.shared.facilities',
        defaultMessage: 'Facilities',
    },
    facility: {
        id: 'app.translations.shared.facility',
        defaultMessage: 'Facility',
    },
    companyDetails: {
        id: 'app.translations.shared.companyDetails',
        defaultMessage: 'Company details',
    },
    clientNotifications: {
        id: 'app.translations.shared.clientNotifications',
        defaultMessage: 'Notifications',
    },
    details: {
        id: 'app.translations.shared.details',
        defaultMessage: 'Details',
        description: 'Details action',
    },
    payment: {
        id: 'app.translations.shared.payment',
        defaultMessage: 'Payment',
        description: 'Payment action',
    },
    password: {
        id: 'app.translations.shared.password',
        defaultMessage: 'Password',
        description: 'Password header',
    },
    description: {
        id: 'app.translations.shared.description',
        defaultMessage: 'Description',
        description: 'Description for entty',
    },
    humanType: {
        id: 'app.translations.shared.humanType',
        defaultMessage: 'Human',
        description: 'Description for human type',
    },
    canineType: {
        id: 'app.translations.shared.canineType',
        defaultMessage: 'Canine',
        description: 'Description for canine type',
    },
    gender: {
        id: 'app.translations.shared.gender',
        defaultMessage: 'Gender',
        description: 'Gender header',
    },
    rate: {
        id: 'app.translations.shared.rate',
        defaultMessage: 'Rate',
    },
    birthDate: {
        id: 'app.translations.shared.birthDate',
        defaultMessage: 'Date of birth',
        description: 'Birth date header',
    },
    passportNumber: {
        id: 'app.translations.shared.passportNumber',
        defaultMessage: 'Passport number',
        description: 'Passport number header',
    },
    maleGender: {
        id: 'app.translations.shared.maleGender',
        defaultMessage: 'Male',
        description: 'Description for male gender',
    },
    femaleGender: {
        id: 'app.translations.shared.femaleGender',
        defaultMessage: 'Female',
        description: 'Description for female gender',
    },
    lookupCode: {
        id: 'app.translations.shared.lookupCode',
        defaultMessage: 'Lookup Code',
        description: 'Lookup code header',
    },
    profile: {
        id: 'app.translations.shared.profile',
        defaultMessage: 'Profile',
        description: 'Profile header',
    },
    notProvided: {
        id: 'app.translations.shared.notProvided',
        defaultMessage: 'Not provided',
    },
    account: {
        id: 'app.translations.shared.account',
        defaultMessage: 'Account',
    },
    personalInfo: {
        id: 'app.translations.shared.personalInfo',
        defaultMessage: 'Personal info',
    },
    notifications: {
        id: 'app.translations.shared.notifications',
        defaultMessage: 'Notifications',
    },
    tools: {
        id: 'app.translations.shared.tools',
        defaultMessage: 'Business',
        description: 'Business header',
    },
    preferences: {
        id: 'app.translations.shared.preferences',
        defaultMessage: 'Preferences',
        description: 'Preferences header',
    },
    authorize: {
        id: 'app.translations.shared.authorize',
        defaultMessage: 'Authorize',
    },
    unauthorize: {
        id: 'app.translations.shared.unauthorize',
        defaultMessage: 'Unauthorize',
    },
    integrations: {
        id: 'app.translations.shared.integrations',
        defaultMessage: 'Integrations',
        description: 'Integrations header',
    },
    integrationsDescription: {
        id: 'app.translations.shared.integrationsDescription',
        defaultMessage: 'Extend your capabilities',
    },
    integrationOn: {
        id: 'app.translations.shared.integrationOn',
        defaultMessage: 'Integration On',
    },
    integrationOff: {
        id: 'app.translations.shared.integrationOff',
        defaultMessage: 'Integration Off',
    },
    googleCalendarIntegration: {
        id: 'app.translations.shared.googleCalendarIntegration',
        defaultMessage: 'Google Calendar',
    },
    emailAddress: {
        id: 'app.translations.shared.emailAddress',
        defaultMessage: 'E-mail',
        description: 'E-mail address header',
    },
    kind: {
        id: 'app.translations.shared.kind',
        defaultMessage: 'Kind',
        description: 'Kind (Canine/Human)',
    },
    from: {
        id: 'app.translations.shared.from',
        defaultMessage: 'from',
    },
    to: {
        id: 'app.translations.shared.to',
        defaultMessage: 'to',
    },
    date: {
        id: 'app.translations.shared.date',
        defaultMessage: 'Date',
        description: 'Date header',
    },
    startDate: {
        id: 'app.translations.shared.startDate',
        defaultMessage: 'Start Date',
        description: 'Start date header',
    },
    endDate: {
        id: 'app.translations.shared.endDate',
        defaultMessage: 'End Date',
        description: 'End date header',
    },
    address: {
        id: 'app.translations.shared.address',
        defaultMessage: 'Address',
        description: 'Address header',
    },
    line1: {
        id: 'app.translations.shared.line1',
        defaultMessage: 'Line 1',
        description: 'Line 1 address header',
    },
    line2: {
        id: 'app.translations.shared.line2',
        defaultMessage: 'Line 2',
        description: 'Line 2 address header',
    },
    line: {
        id: 'app.translations.shared.line',
        defaultMessage: 'Address',
        description: 'Line1 and Line2 adress header combined',
    },
    city: {
        id: 'app.translations.shared.city',
        defaultMessage: 'City',
        description: 'City address header',
    },
    country: {
        id: 'app.translations.shared.country',
        defaultMessage: 'Country',
        description: 'Country address header',
    },
    currency: {
        id: 'app.translations.shared.currency',
        defaultMessage: 'Currency',
    },
    language: {
        id: 'app.translations.shared.language',
        defaultMessage: 'Language',
    },
    timezone: {
        id: 'app.translations.shared.timezone',
        defaultMessage: 'Timezone',
    },
    state: {
        id: 'app.translations.shared.state',
        defaultMessage: 'State',
        description: 'State address header',
    },
    postalCode: {
        id: 'app.translations.shared.postalCode',
        defaultMessage: 'Postal Code',
        description: 'Postal code address header',
    },
    signIn: {
        id: 'app.translations.shared.signIn',
        defaultMessage: 'Sign In',
        description: 'Sign In text for buttons and links',
    },
    signUp: {
        id: 'app.translations.shared.signUp',
        defaultMessage: 'Sign Up',
        description: 'Sign Up text for buttons and links',
    },
    staff: {
        id: 'app.translations.shared.staff',
        defaultMessage: 'Staff',
        description: 'Staff header',
    },
    title: {
        id: 'app.translations.shared.title',
        defaultMessage: 'Title',
    },
    employees: {
        id: 'app.translations.shared.employees',
        defaultMessage: 'Employees',
    },
    employee: {
        id: 'app.translations.shared.employee',
        defaultMessage: 'Employee',
    },
    loading: {
        id: 'app.translations.shared.loading',
        defaultMessage: 'Loading...',
        description: 'Loading page information',
    },
    calendar: {
        id: 'app.translations.shared.calendar',
        defaultMessage: 'Calendar',
        description: 'Calendar header',
    },
    noUpcomingAppointments: {
        id: 'app.translations.shared.noUpcomingAppointments',
        defaultMessage: 'No upcoming reservations',
    },
    noCompletedAppointments: {
        id: 'app.translations.shared.noCompletedAppointments',
        defaultMessage: 'No completed reservations',
    },
    noCanceledAppointments: {
        id: 'app.translations.shared.noCanceledAppointments',
        defaultMessage: 'No canceled reservations',
    },
    noAppointments: {
        id: 'app.translations.shared.noAppointments',
        defaultMessage: 'No reservations',
    },
    noPets: {
        id: 'app.translations.shared.noPets',
        defaultMessage: 'No pets',
    },
    noAttachments: {
        id: 'app.translations.shared.noAttachments',
        defaultMessage: 'No files',
    },
    noInvoices: {
        id: 'app.translations.shared.noInvoices',
        defaultMessage: 'No invoices',
    },
    appointmentsList: {
        id: 'app.translations.shared.appointmentsList',
        defaultMessage: 'Appointments list',
        description: 'Appointments list header',
    },
    appointments: {
        id: 'app.translations.shared.appointments',
        defaultMessage: 'Appointments',
        description: 'Appointments header',
    },
    upcomingAppointments: {
        id: 'app.translations.shared.upcomingAppointments',
        defaultMessage: 'Upcoming Appointments',
    },
    pastAppointments: {
        id: 'app.translations.shared.pastAppointments',
        defaultMessage: 'Past Appointments',
    },
    addToGoogleCalendar: {
        id: 'app.translations.shared.addToGoogleCalendar',
        defaultMessage: 'Add to Google Calendar',
    },
    noData: {
        id: 'app.translations.shared.noData',
        defaultMessage: 'No data',
        description: 'No data information in case there is nothing to show',
    },
    dashboard: {
        id: 'app.translations.shared.dashboard',
        defaultMessage: 'Home',
    },
    content: {
        id: 'app.translations.shared.content',
        defaultMessage: 'Content',
        description: 'Content button/action/header',
    },
    template: {
        id: 'app.translations.shared.template',
        defaultMessage: 'Template',
        description: 'Template button/action/header',
    },
    templateName: {
        id: 'app.translations.shared.templateName',
        defaultMessage: 'Name',
    },
    emailSubject: {
        id: 'app.translations.shared.emailSubject',
        defaultMessage: 'Email subject',
    },
    templates: {
        id: 'app.translations.shared.templates',
        defaultMessage: 'Templates',
        description: 'Templates button/action/header',
    },
    noTemplates: {
        id: 'app.translations.shared.noTemplates',
        defaultMessage: 'There are no record templates!',
    },
    vouchers: {
        id: 'app.translations.shared.vouchers',
        defaultMessage: 'Vouchers',
    },
    record: {
        id: 'app.translations.shared.record',
        defaultMessage: 'Record',
        description: 'Record button/action/header',
    },
    viewRecord: {
        id: 'app.translations.shared.viewRecord',
        defaultMessage: 'View record',
        description: 'View record button/action/header',
    },
    viewRecords: {
        id: 'app.translations.shared.viewRecords',
        defaultMessage: 'View records',
        description: 'View records button/action/header',
    },
    patients: {
        id: 'app.translations.shared.patients',
        defaultMessage: 'Patients',
        description: 'Patients header',
    },
    clients: {
        id: 'app.translations.shared.clients',
        defaultMessage: 'Clients',
        description: 'Clients header',
    },
    sales: {
        id: 'app.translations.shared.sales',
        defaultMessage: 'Sales',
        description: 'Sales header',
    },
    settings: {
        id: 'app.translations.shared.settings',
        defaultMessage: 'Settings',
        description: 'Settings header',
    },
    signOut: {
        id: 'app.translations.shared.signOut',
        defaultMessage: 'Sign out',
        description: 'Sign out header/action/button',
    },
    changeAccount: {
        id: 'app.translations.shared.changeAccount',
        defaultMessage: 'Change account',
    },
    selectFacility: {
        id: 'app.translations.shared.selectFacility',
        defaultMessage: 'Select facility',
        description: 'Select facility header/action/button',
    },
    companyName: {
        id: 'app.translations.shared.companyName',
        defaultMessage: 'Company name',
        description: 'Company name header',
    },
    businessName: {
        id: 'app.translations.shared.businessName',
        defaultMessage: 'Business name',
    },
    clientDetails: {
        id: 'app.translations.shared.clientDetails',
        defaultMessage: 'Client details',
    },
    medicalRecords: {
        id: 'app.translations.shared.medicalRecords',
        defaultMessage: 'Medical records',
    },
    invoices: {
        id: 'app.translations.shared.invoices',
        defaultMessage: 'Invoices',
    },
    invoice: {
        id: 'app.translations.shared.invoice',
        defaultMessage: 'Invoice',
    },
    invoiceNumber: {
        id: 'app.translations.shared.invoiceNumber',
        defaultMessage: 'Invoice Number',
    },
    invoiceThanks: {
        id: 'app.translations.shared.invoiceThanks',
        defaultMessage: 'Thank you for usage of our services.',
    },
    appointment: {
        id: 'app.translations.shared.appointment',
        defaultMessage: 'Appointment',
    },
    tax: {
        id: 'app.translations.shared.tax',
        defaultMessage: 'Tax',
    },
    taxRate: {
        id: 'app.translations.shared.taxRate',
        defaultMessage: 'Tax Rate',
    },
    subtotal: {
        id: 'app.translations.shared.subtotal',
        defaultMessage: 'Sub Total',
    },
    total: {
        id: 'app.translations.shared.total',
        defaultMessage: 'Total',
    },
    totalDue: {
        id: 'app.translations.shared.totalDue',
        defaultMessage: 'Total Due',
    },
    taxId: {
        id: 'app.translations.shared.taxId',
        defaultMessage: 'VAT ID',
    },
    dragAndDrop: {
        id: 'app.translations.shared.dragAndDrop',
        defaultMessage: 'Drag and drop a file here or click',
    },
    legalName: {
        id: 'app.translations.shared.legalName',
        defaultMessage: 'Legal name',
    },
    lastVisit: {
        id: 'app.translations.shared.lastVisit',
        defaultMessage: 'Last visit',
    },
    deathDate: {
        id: 'app.translations.shared.deathDate',
        defaultMessage: 'Date of death',
    },
    chipNumber: {
        id: 'app.translations.shared.chipNumber',
        defaultMessage: 'Chip number',
    },
    tagNumber: {
        id: 'app.translations.shared.tagNumber',
        defaultMessage: 'Tag number',
    },
    weight: {
        id: 'app.translations.shared.weight',
        defaultMessage: 'Weight',
    },
    deceased: {
        id: 'app.translations.shared.deceased',
        defaultMessage: 'deceased',
    },
    informations: {
        id: 'app.translations.shared.informations',
        defaultMessage: 'Informations',
    },
    serviceName: {
        id: 'app.translations.shared.serviceName',
        defaultMessage: "Service's name",
    },
    choose: {
        id: 'app.translations.shared.choose',
        defaultMessage: 'Choose',
    },
    attachments: {
        id: 'app.translations.shared.attachments',
        defaultMessage: 'Attachments',
    },
    pets: {
        id: 'app.translations.shared.pets',
        defaultMessage: 'Pets',
    },
    pet: {
        id: 'app.translations.shared.pet',
        defaultMessage: 'Pet',
    },
    book: {
        id: 'app.translations.shared.book',
        defaultMessage: 'Book',
    },
    petName: {
        id: 'app.translations.shared.petName',
        defaultMessage: 'Name',
    },
    client: {
        id: 'app.translations.shared.client',
        defaultMessage: 'Client',
    },
    dateAndTime: {
        id: 'app.translations.shared.dateAndTime',
        defaultMessage: 'Date and time',
    },
    postAppointmentNotesSent: {
        id: 'app.translations.shared.postAppointmentNotesSent',
        defaultMessage: 'Notes to client has been sent',
    },
    sendNotesToClient: {
        id: 'app.translations.shared.sendNotes',
        defaultMessage: 'Send notes to client',
    },
    isPublic: {
        id: 'app.translations.shared.isPublic',
        defaultMessage: 'Make this facility public',
    },
    isPublicHelper: {
        id: 'app.translations.shared.isPublicHelper',
        defaultMessage: 'Your facility would be visible public on the web',
    },
    publicName: {
        id: 'app.translations.shared.publicName',
        defaultMessage: 'Public name',
    },
    publicNameHelper: {
        id: 'app.translations.shared.publicNameHelper',
        defaultMessage: 'This name would be used in public directory if visible',
    },
    internalName: {
        id: 'app.translations.shared.internalName',
        defaultMessage: 'Internal name',
    },
    internalNameHelper: {
        id: 'app.translations.shared.internalName',
        defaultMessage: 'This name would is used internaly for account users',
    },
    newDocument: {
        id: 'app.translations.shared.newDocument',
        defaultMessage: 'New document',
    },
    official: {
        id: 'app.translations.shared.official',
        defaultMessage: 'Official',
    },
    unofficial: {
        id: 'app.translations.shared.unofficial',
        defaultMessage: 'Unofficial',
    },
    aboutUs: {
        id: 'app.translations.shared.aboutUs',
        defaultMessage: 'About us',
    },
    legalData: {
        id: 'app.translations.shared.legalData',
        defaultMessage: 'Legal data',
    },
    business: {
        id: 'app.translations.shared.business',
        defaultMessage: 'Business',
    },
    widget: {
        id: 'app.translations.shared.widget',
        defaultMessage: 'Widget',
    },
    locations: {
        id: 'app.translations.shared.locations',
        defaultMessage: 'Locations',
    },
    taxRates: {
        id: 'app.translations.shared.taxRates',
        defaultMessage: 'Tax rates',
    },
    displayName: {
        id: 'app.translations.shared.displayName',
        defaultMessage: 'Display name',
    },
    value: {
        id: 'app.translations.shared.value',
        defaultMessage: 'Value',
    },
    activate: {
        id: 'app.translations.shared.activate',
        defaultMessage: 'Activate',
    },
    deactivate: {
        id: 'app.translations.shared.deactivate',
        defaultMessage: 'Deactivate',
    },
    isActive: {
        id: 'app.translations.shared.isActive',
        defaultMessage: 'Is active',
    },
    isActiveHelper: {
        id: 'app.translations.shared.isActiveHelper',
        defaultMessage: 'Is possible to choose',
    },
    issueDate: {
        id: 'app.translations.shared.issueDate',
        defaultMessage: 'Invoice Date',
    },
    saleDate: {
        id: 'app.translations.shared.saleDate',
        defaultMessage: 'Sale Date',
    },
    paymentDate: {
        id: 'app.translations.shared.paymentDate',
        defaultMessage: 'Payment Due',
    },
    price: {
        id: 'app.translations.shared.price',
        defaultMessage: 'Price',
    },
    gross: {
        id: 'app.translations.shared.gross',
        defaultMessage: 'Gross',
    },
    net: {
        id: 'app.translations.shared.net',
        defaultMessage: 'Net',
    },
    quantity: {
        id: 'app.translations.shared.quantity',
        defaultMessage: 'Quantity',
    },
    invoiceItemName: {
        id: 'app.translations.shared.invoiceItemName',
        defaultMessage: 'Name',
    },
    ordinalNumber: {
        id: 'app.translations.shared.ordinalNumber',
        defaultMessage: 'e.g.',
    },
    closed: {
        id: 'app.translations.shared.closed',
        defaultMessage: 'Closed',
    },
    openAt: {
        id: 'app.translations.shared.openAt',
        defaultMessage: 'Open at',
    },
    closeAt: {
        id: 'app.translations.shared.closeAt',
        defaultMessage: 'close at',
    },
    openHours: {
        id: 'app.translations.shared.openHours',
        defaultMessage: 'Open Hours',
    },
    gdprAgreed: {
        id: 'app.translations.shared.gdprAgreed',
        defaultMessage: 'Due to General Data Protection Regulation you must inform your Client about the personal data processing rules. Did you do so?',
    },
    timeframe: {
        id: 'app.translations.shared.timeframe',
        defaultMessage: 'Timeframe',
    },
    noOptions: {
        id: 'app.translations.shared.noOptions',
        defaultMessage: 'No options',
    },
    type: {
        id: 'app.translations.shared.type',
        defaultMessage: 'Type',
    },
    voucherName: {
        id: 'app.translations.shared.voucherName',
        defaultMessage: 'Name',
    },
    location: {
        id: 'app.translations.shared.location',
        defaultMessage: 'Location',
    },
    locationName: {
        id: 'app.translations.shared.locationName',
        defaultMessage: 'Location name',
    },
    filters: {
        id: 'app.translations.shared.filters',
        defaultMessage: 'Filters',
    },
    isDefaultLocation: {
        id: 'app.translations.shared.isDefaultLocation',
        defaultMessage: 'Is default',
    },
    isBookable: {
        id: 'app.translations.shared.isBookable',
        defaultMessage: 'Is bookable',
    },
    isCustomLocation: {
        id: 'app.translations.shared.isCustomLocation',
        defaultMessage: 'Custom location',
    },
    appointmentType: {
        id: 'app.translations.shared.appointmentType',
        defaultMessage: 'Type',
    },
    participants: {
        id: 'app.translations.shared.participants',
        defaultMessage: 'Participants',
    },
    participant: {
        id: 'app.translations.shared.participant',
        defaultMessage: 'Participant',
    },
    participantsNumber: {
        id: 'app.translations.shared.participantsNumber',
        defaultMessage: '{number} Participants',
    },
    categories: {
        id: 'app.translations.shared.categories',
        defaultMessage: 'Categories',
    },
    recipientDetails: {
        id: 'app.translations.shared.recipientDetails',
        defaultMessage: 'Recipient details',
    },
    select: {
        id: 'app.translations.shared.select',
        defaultMessage: 'Select',
    },
    minItems: {
        id: 'app.translations.shared.minItems',
        defaultMessage: 'At least {count} item needed',
    },
    maxItems: {
        id: 'app.translations.shared.maxItems',
        defaultMessage: 'Max {count} items allowed',
    },
    invalidDate: {
        id: 'app.translations.shared.invalidDate',
        defaultMessage: 'Invalid date',
    },
    website: {
        id: 'app.translations.shared.website',
        defaultMessage: 'Website',
    },
    socialMedia: {
        id: 'app.translations.shared.socialMedia',
        defaultMessage: 'Social Media',
    },
    noteToCustomer: {
        id: 'app.translations.shared.noteToCustomer',
        defaultMessage: 'Note to customer',
    },
    isTravellingService: {
        id: 'app.translations.shared.isTravellingService',
        defaultMessage: 'Is the service available for client`s choice location?',
    },
    isOnlineService: {
        id: 'app.translations.shared.isOnlineService',
        defaultMessage: 'Is the service available online?',
    },
    noAppointmentRequests: {
        id: 'app.translations.shared.noAppointmentRequests',
        defaultMessage: 'No appointment requests',
    },
    allowBooking: {
        id: 'app.translations.shared.allowBooking',
        defaultMessage: 'Allow booking',
    },
    allowBookingHelper: {
        id: 'app.translations.shared.allowBookingHelper',
        defaultMessage: 'Do you want to allow bookings through Petsly?',
    },
    employeesForService: {
        id: 'app.translations.shared.employeesForService',
        defaultMessage: 'Employees providing service',
    },
    inbox: {
        id: 'app.translations.shared.inbox',
        defaultMessage: 'Inbox',
    },
    historicNotes: {
        id: 'app.translations.shared.historicNotes',
        defaultMessage: 'Historic Notes',
    },
    create: {
        id: 'app.translations.shared.create',
        defaultMessage: 'Create',
        description: 'Create action',
    },
    edit: {
        id: 'app.translations.shared.edit',
        defaultMessage: 'Edit',
        description: 'Edit action',
    },
    remove: {
        id: 'app.translations.shared.remove',
        defaultMessage: 'Remove',
        description: 'Remove action',
    },
    change: {
        id: 'app.translations.shared.change',
        defaultMessage: 'Change',
        description: 'Change action',
    },
    cancel: {
        id: 'app.translations.shared.cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel action',
    },
    close: {
        id: 'app.translations.shared.close',
        defaultMessage: 'Close',
        description: 'Close action',
    },
    print: {
        id: 'app.translations.shared.print',
        defaultMessage: 'Print',
        description: 'Print action',
    },
    add: {
        id: 'app.translations.shared.add',
        defaultMessage: 'Add',
        description: 'Add action',
    },
    save: {
        id: 'app.translations.shared.save',
        defaultMessage: 'Save',
        description: 'Save action',
    },
    continue: {
        id: 'app.translations.shared.continue',
        defaultMessage: 'Continue',
        description: 'Continue action',
    },
    skip: {
        id: 'app.translations.shared.skip',
        defaultMessage: 'Skip',
        description: 'Skip action',
    },
    previous: {
        id: 'app.translations.shared.previous',
        defaultMessage: 'Previous',
        description: 'Previous action',
    },
    send: {
        id: 'app.translations.shared.send',
        defaultMessage: 'Send',
        description: 'Send action',
    },
    download: {
        id: 'app.translations.shared.download',
        defaultMessage: 'Download',
        description: 'Download action',
    },
    update: {
        id: 'app.translations.shared.update',
        defaultMessage: 'Update',
        description: 'Update action',
    },
    confirm: {
        id: 'app.translations.shared.confirm',
        defaultMessage: 'Confirm',
        description: 'Confirm action',
    },
    view: {
        id: 'app.translations.shared.view',
        defaultMessage: 'View',
        description: 'View action',
    },
    more: {
        id: 'app.translations.shared.more',
        defaultMessage: 'More',
        description: 'More action',
    },
    action: {
        id: 'app.translations.shared.action',
        defaultMessage: 'Action',
        description: 'Action action',
    },
    link: {
        id: 'app.translations.shared.link',
        defaultMessage: 'Link',
        description: 'Link action',
    },
    unlink: {
        id: 'app.translations.shared.unlink',
        defaultMessage: 'Link',
        description: 'Link action',
    },
    success: {
        id: 'app.translations.shared.success',
        defaultMessage: 'Success',
        description: 'Success result',
    },
    required: {
        id: 'app.translations.shared.required',
        defaultMessage: 'Required',
    },
    applyFilter: {
        id: 'app.translations.shared.applyFilter',
        defaultMessage: 'Filter',
    },
    monday: {
        id: 'app.translations.shared.monday',
        defaultMessage: 'Monday',
    },
    tuesday: {
        id: 'app.translations.shared.tuesday',
        defaultMessage: 'Tuesday',
    },
    wednesday: {
        id: 'app.translations.shared.wednesday',
        defaultMessage: 'Wednesday',
    },
    thursday: {
        id: 'app.translations.shared.thursday',
        defaultMessage: 'Thursday',
    },
    friday: {
        id: 'app.translations.shared.friday',
        defaultMessage: 'Friday',
    },
    saturday: {
        id: 'app.translations.shared.saturday',
        defaultMessage: 'Saturday',
    },
    sunday: {
        id: 'app.translations.shared.sunday',
        defaultMessage: 'Sunday',
    },
    wholeDay: {
        id: 'app.translations.shared.wholeDay',
        defaultMessage: 'Whole day',
    },
    addService: {
        id: 'app.translations.shared.addService',
        defaultMessage: 'Add service',
    },
    addInvoice: {
        id: 'app.translations.shared.addInvoice',
        defaultMessage: 'Add Invoice',
    },
    addServiceCategory: {
        id: 'app.translations.shared.addServiceCategory',
        defaultMessage: 'Add service category',
    },
    addAppointment: {
        id: 'app.translations.shared.addAppointment',
        defaultMessage: 'Add appointment',
        description: 'Add appointment button/action',
    },
    submitAppointment: {
        id: 'app.translations.shared.submitAppointment',
        defaultMessage: 'Submit',
        description: 'Submit appointment button/action',
    },
    cancelAppointment: {
        id: 'app.translations.shared.cancelAppointment',
        defaultMessage: 'Cancel',
        description: 'Cancel appointment button/action',
    },
    addClient: {
        id: 'app.translations.shared.addClient',
        defaultMessage: 'Add client',
        description: 'Add new client button/action/header',
    },
    addPet: {
        id: 'app.translations.shared.addPet',
        defaultMessage: 'Add pet',
        description: 'Add new pet button/action/header',
    },
    addEmployee: {
        id: 'app.translations.shared.addEmployee',
        defaultMessage: 'Add employee',
        description: 'Add new staff button/action/header',
    },
    addFacility: {
        id: 'app.translations.shared.addFacility',
        defaultMessage: 'Add facility',
        description: 'Add new facility button/action/header',
    },
    addRecord: {
        id: 'app.translations.shared.addRecord',
        defaultMessage: 'Add record',
        description: 'Add record button/action/header',
    },
    addTemplate: {
        id: 'app.translations.shared.addTemplate',
        defaultMessage: 'Add template',
        description: 'Add template button/action/header',
    },
    addTaxRate: {
        id: 'app.translations.shared.addTaxRate',
        defaultMessage: 'Add tax rate',
        description: 'Add tax rate button/action/header',
    },
    addLocation: {
        id: 'app.translations.shared.addLocation',
        defaultMessage: 'Add location',
        description: 'Add location button/action/header',
    },
    addVoucher: {
        id: 'app.translations.shared.addVoucher',
        defaultMessage: 'Add voucher',
        description: 'Add voucher button/action/header',
    },
    addedFile: {
        id: 'app.translations.shared.addedFile',
        defaultMessage: 'File has been added',
    },
    addedService: {
        id: 'app.translations.shared.addedService',
        defaultMessage: 'Service has been added',
    },
    addedServiceCategory: {
        id: 'app.translations.shared.addedServiceCategory',
        defaultMessage: 'Service category has been added',
    },
    addedAppointment: {
        id: 'app.translations.shared.addedAppointment',
        defaultMessage: 'Appointment has been added',
    },
    addedClient: {
        id: 'app.translations.shared.addedClient',
        defaultMessage: 'Client has been added',
    },
    addedPet: {
        id: 'app.translations.shared.addedPet',
        defaultMessage: 'Pet has been added',
    },
    addedEmployee: {
        id: 'app.translations.shared.addedEmployee',
        defaultMessage: 'Employee has been added',
    },
    addedFacility: {
        id: 'app.translations.shared.addedFacility',
        defaultMessage: 'Facility has been added',
    },
    addedRecord: {
        id: 'app.translations.shared.addedRecord',
        defaultMessage: 'Record has been added',
    },
    addedTemplate: {
        id: 'app.translations.shared.addedTemplate',
        defaultMessage: 'Template has been added',
    },
    addedTaxRate: {
        id: 'app.translations.shared.addedTaxRate',
        defaultMessage: 'Tax rate has been added',
    },
    addedLocation: {
        id: 'app.translations.shared.addedLocation',
        defaultMessage: 'Location has been added',
    },
    addedWebinar: {
        id: 'app.translations.shared.addedWebinar',
        defaultMessage: 'Webinar has been added',
    },
    addedVoucher: {
        id: 'app.translations.shared.addedVoucher',
        defaultMessage: 'Voucher has been added',
    },
    activateUser: {
        id: 'app.translations.shared.activateUser',
        defaultMessage: 'Activate user',
    },
    deactivateUser: {
        id: 'app.translations.shared.deactivateUser',
        defaultMessage: 'Deactivate user',
    },
    removeService: {
        id: 'app.translations.shared.removeService',
        defaultMessage: 'Remove service',
    },
    removeServiceCategory: {
        id: 'app.translations.shared.removeServiceCategory',
        defaultMessage: 'Remove service category',
    },
    removeAppointment: {
        id: 'app.translations.shared.removeAppointment',
        defaultMessage: 'Remove appointment',
        description: 'Remove appointment button/action',
    },
    removeAttachment: {
        id: 'app.translations.shared.removeAttachment',
        defaultMessage: 'Remove attachment',
        description: 'Remove attachment button/action',
    },
    removeClient: {
        id: 'app.translations.shared.removeClient',
        defaultMessage: 'Remove client',
        description: 'Remove client button/action/header',
    },
    removePet: {
        id: 'app.translations.shared.removePet',
        defaultMessage: 'Remove pet',
        description: 'Remove pet button/action/header',
    },
    removeEmployee: {
        id: 'app.translations.shared.removeEmployee',
        defaultMessage: 'Remove employee',
        description: 'Remove employee button/action/header',
    },
    removeVoucher: {
        id: 'app.translations.shared.removeVoucher',
        defaultMessage: 'Remove voucher',
        description: 'Remove voucher button/action/header',
    },
    removeFacility: {
        id: 'app.translations.shared.removeFacility',
        defaultMessage: 'Remove facility',
        description: 'Remove facility button/action/header',
    },
    removeRecord: {
        id: 'app.translations.shared.removeRecord',
        defaultMessage: 'Remove record',
        description: 'Remove record button/action/header',
    },
    removeTemplate: {
        id: 'app.translations.shared.removeTemplate',
        defaultMessage: 'Remove template',
        description: 'Template record button/action/header',
    },
    removeTaxRate: {
        id: 'app.translations.shared.removeTaxRate',
        defaultMessage: 'Remove tax rate',
        description: 'Tax rate record button/action/header',
    },
    removeInvoice: {
        id: 'app.translations.shared.removeInvoice',
        description: 'Tax rate record button/action/header',
    },
    confirmRemoveService: {
        id: 'app.translations.shared.confirmRemoveService',
        defaultMessage: 'Are you sure that you want to remove service?',
    },
    confirmRemoveServiceCategory: {
        id: 'app.translations.shared.confirmRemoveServiceCategory',
        defaultMessage: 'Are you sure that you want to remove service category?',
    },
    confirmRemoveAppointment: {
        id: 'app.translations.shared.confirmRemoveAppointment',
        defaultMessage: 'Are you sure that you want to remove appointment?',
    },
    confirmRemoveAttachment: {
        id: 'app.translations.shared.confirmRemoveAttachment',
        defaultMessage: 'Are you sure that you want to remove attachment?',
    },
    confirmRemoveClient: {
        id: 'app.translations.shared.confirmRemoveClient',
        defaultMessage: 'Are you sure that you want to remove client?',
    },
    confirmRemovePet: {
        id: 'app.translations.shared.confirmRemovePet',
        defaultMessage: 'Are you sure that you want to remove pet?',
    },
    confirmRemoveEmployee: {
        id: 'app.translations.shared.confirmRemoveEmployee',
        defaultMessage: 'Are you sure that you want to remove employee?',
    },
    confirmRemoveVoucher: {
        id: 'app.translations.shared.confirmRemoveVoucher',
        defaultMessage: 'Are you sure that you want to remove voucher?',
    },
    confirmRemoveFacility: {
        id: 'app.translations.shared.confirmRemoveFacility',
        defaultMessage: 'Are you sure that you want to remove facility?',
    },
    confirmRemoveRecord: {
        id: 'app.translations.shared.confirmRemoveRecord',
        defaultMessage: 'Are you sure that you want to remove record?',
    },
    confirmRemoveTemplate: {
        id: 'app.translations.shared.confirmRemoveTemplate',
        defaultMessage: 'Are you sure that you want to remove template?',
    },
    confirmRemoveTaxRate: {
        id: 'app.translations.shared.confirmRemoveTaxRate',
        defaultMessage: 'Are you sure that you want to remove tax rate?',
    },
    confirmRemoveInvoice: {
        id: 'app.translations.shared.confirmRemoveInvoice',
        defaultMessage: 'Are you sure that you want to remove invoice?',
    },
    confirmActivateUser: {
        id: 'app.translations.shared.confirmActivateUser',
        defaultMessage: 'Are you sure that you want to activate user?',
    },
    confirmDeactivateUser: {
        id: 'app.translations.shared.confirmDeactivateUser',
        defaultMessage: 'Are you sure that you want to deactivate user?',
    },
    removedFile: {
        id: 'app.translations.shared.removedFile',
        defaultMessage: 'File has been removed',
    },
    removedService: {
        id: 'app.translations.shared.removedService',
        defaultMessage: 'Service has been removed',
    },
    removedServiceCategory: {
        id: 'app.translations.shared.removedServiceCategory',
        defaultMessage: 'Service category has been removed',
    },
    removedAppointment: {
        id: 'app.translations.shared.removedAppointment',
        defaultMessage: 'Appointment has been removed',
    },
    removedClient: {
        id: 'app.translations.shared.removedClient',
        defaultMessage: 'Client has been removed',
    },
    removedPet: {
        id: 'app.translations.shared.removedPet',
        defaultMessage: 'Pet has been removed',
    },
    removedEmployee: {
        id: 'app.translations.shared.removedEmployee',
        defaultMessage: 'Employee has been removed',
    },
    removedVoucher: {
        id: 'app.translations.shared.removedVoucher',
        defaultMessage: 'Voucher has been removed',
    },
    removedFacility: {
        id: 'app.translations.shared.removedFacility',
        defaultMessage: 'Facility has been removed',
    },
    removedRecord: {
        id: 'app.translations.shared.removedRecord',
        defaultMessage: 'Record has been removed',
    },
    removedTemplate: {
        id: 'app.translations.shared.removedTemplate',
        defaultMessage: 'Template has been removed',
    },
    removedTaxRate: {
        id: 'app.translations.shared.removedTaxRate',
        defaultMessage: 'Tax rate has been removed',
    },
    removedInvoice: {
        id: 'app.translations.shared.removedInvoice',
        defaultMessage: 'Invoice has been removed',
    },
    editInvoice: {
        id: 'app.translations.shared.editInvoice',
        defaultMessage: 'Edit Invoice',
    },
    editService: {
        id: 'app.translations.shared.editService',
        defaultMessage: 'Edit service',
    },
    editServiceCategory: {
        id: 'app.translations.shared.editServiceCategory',
        defaultMessage: 'Edit service category',
    },
    editAppointment: {
        id: 'app.translations.shared.editAppointment',
        defaultMessage: 'Edit appointment',
    },
    editClient: {
        id: 'app.translations.shared.editClient',
        defaultMessage: 'Edit client',
    },
    editPet: {
        id: 'app.translations.shared.editPet',
        defaultMessage: 'Edit pet',
    },
    editEmployee: {
        id: 'app.translations.shared.editEmployee',
        defaultMessage: 'Edit employee',
    },
    editFacility: {
        id: 'app.translations.shared.editFacility',
        defaultMessage: 'Edit facility',
    },
    editRecord: {
        id: 'app.translations.shared.editRecord',
        defaultMessage: 'Edit record',
    },
    editTemplate: {
        id: 'app.translations.shared.editTemplate',
        defaultMessage: 'Edit template',
    },
    editTaxRate: {
        id: 'app.translations.shared.editTaxRate',
        defaultMessage: 'Edit tax rate',
    },
    editLocation: {
        id: 'app.translations.shared.editLocation',
        defaultMessage: 'Edit location',
    },
    editWebinar: {
        id: 'app.translations.shared.editWebinar',
        defaultMessage: 'Edit webinar',
    },
    editVoucher: {
        id: 'app.translations.shared.editVoucher',
        defaultMessage: 'Edit voucher',
    },
    updatedService: {
        id: 'app.translations.shared.updatedService',
        defaultMessage: 'Service has been updated',
    },
    updatedServiceCategory: {
        id: 'app.translations.shared.updatedServiceCategory',
        defaultMessage: 'Service category has been updated',
    },
    updatedAppointment: {
        id: 'app.translations.shared.updatedAppointment',
        defaultMessage: 'Appointment has been updated',
    },
    updatedClient: {
        id: 'app.translations.shared.updatedClient',
        defaultMessage: 'Client has been updated',
    },
    updatedPet: {
        id: 'app.translations.shared.updatedPet',
        defaultMessage: 'Pet has been updated',
    },
    updatedEmployee: {
        id: 'app.translations.shared.updatedEmployee',
        defaultMessage: 'Employee has been updated',
    },
    updatedFacility: {
        id: 'app.translations.shared.updatedFacility',
        defaultMessage: 'Facility has been updated',
    },
    updatedRecord: {
        id: 'app.translations.shared.updatedRecord',
        defaultMessage: 'Record has been updated',
    },
    updatedTemplate: {
        id: 'app.translations.shared.updatedTemplate',
        defaultMessage: 'Template has been updated',
    },
    updatedTaxRate: {
        id: 'app.translations.shared.updatedTaxRate',
        defaultMessage: 'Tax rate has been updated',
    },
    updatedLocation: {
        id: 'app.translations.shared.updatedLocation',
        defaultMessage: 'Location has been updated',
    },
    updatedWebinar: {
        id: 'app.translations.shared.editWebinar',
        defaultMessage: 'Webinar has been updated',
    },
    updatedVoucher: {
        id: 'app.translations.shared.updatedVoucher',
        defaultMessage: 'Voucher has been updated',
    },
    takeAMoment: {
        id: 'app.translations.shared.takeAMoment',
        defaultMessage: "It's gonna take just a moment",
    },
    appointmentCreationNotification: {
        id: 'app.translations.shared.appointmentCreationNotification',
        defaultMessage: 'Appointment creation',
    },
    appointmentUpdateNotification: {
        id: 'app.translations.shared.appointmentUpdateNotification',
        defaultMessage: 'Appointment update',
    },
    appointmentCancelNotification: {
        id: 'app.translations.shared.appointmentCancelNotification',
        defaultMessage: 'Appointment cancel',
    },
    appointmentReminderNotification: {
        id: 'app.translations.shared.appointmentReminderNotification',
        defaultMessage: 'Appointment reminder',
    },
    CAT: {
        id: 'app.translations.shared.CAT',
        defaultMessage: 'Cat',
    },
    DOG: {
        id: 'app.translations.shared.DOG',
        defaultMessage: 'Dog',
    },
    RABBIT: {
        id: 'app.translations.shared.RABBIT',
        defaultMessage: 'Rabbit',
    },
    HORSE: {
        id: 'app.translations.shared.HORSE',
        defaultMessage: 'Horse',
    },
    OTHER: {
        id: 'app.translations.shared.OTHER',
        defaultMessage: 'Other',
    },
    PATIENT_MALE: {
        id: 'app.translations.shared.PATIENT_MALE',
        defaultMessage: 'Male',
    },
    PATIENT_FEMALE: {
        id: 'app.translations.shared.PATIENT_FEMALE',
        defaultMessage: 'Female',
    },
    PET_MALE: {
        id: 'app.translations.shared.PET_MALE',
        defaultMessage: 'Male',
    },
    PET_FEMALE: {
        id: 'app.translations.shared.PET_FEMALE',
        defaultMessage: 'Female',
    },
    APPOINTMENT_TYPE_GROUP_CLIENT: {
        id: 'app.translations.shared.APPOINTMENT_TYPE_GROUP_CLIENT',
        defaultMessage: 'Group',
    },
    APPOINTMENT_TYPE_INDIVIDUAL_CLIENT: {
        id: 'app.translations.shared.APPOINTMENT_TYPE_INDIVIDUAL_CLIENT',
        defaultMessage: 'Individual',
    },
    APPOINTMENT_TYPE_ONLINE: {
        id: 'app.translations.shared.APPOINTMENT_TYPE_ONLINE',
        defaultMessage: 'Online',
    },
    APPOINTMENT_STATUS_UNCONFIRMED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_UNCONFIRMED',
        defaultMessage: 'Unconfirmed',
    },
    APPOINTMENT_STATUS_CONFIRMED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_CONFIRMED',
        defaultMessage: 'Confirmed',
    },
    APPOINTMENT_STATUS_STARTED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_STARTED',
        defaultMessage: 'Started',
    },
    APPOINTMENT_STATUS_ARRIVED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_ARRIVED',
        defaultMessage: 'Arrived',
    },
    APPOINTMENT_STATUS_NO_SHOW: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_NO_SHOW',
        defaultMessage: 'No show',
    },
    APPOINTMENT_STATUS_COMPLETED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_COMPLETED',
        defaultMessage: 'Completed',
    },
    APPOINTMENT_STATUS_CANCELLED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_CANCELLED',
        defaultMessage: 'Cancelled',
    },
    APPOINTMENT_STATUS_RESCHEDULED: {
        id: 'app.translations.shared.APPOINTMENT_STATUS_RESCHEDULED',
        defaultMessage: 'Rescheduled',
    },
    APPOINTMENT_RESULT_TYPE_ALL: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_ALL',
        defaultMessage: 'All',
    },
    APPOINTMENT_RESULT_TYPE_UPCOMING: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_UPCOMING',
        defaultMessage: 'Upcoming',
    },
    APPOINTMENT_RESULT_TYPE_PAST: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_PAST',
        defaultMessage: 'Past',
    },
    APPOINTMENT_RESULT_TYPE_TODAY: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_TODAY',
        defaultMessage: 'Today',
    },
    APPOINTMENT_RESULT_TYPE_YESTERDAY: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_YESTERDAY',
        defaultMessage: 'Yesterday',
    },
    APPOINTMENT_RESULT_TYPE_THIS_WEEK: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_THIS_WEEK',
        defaultMessage: 'This week',
    },
    APPOINTMENT_RESULT_TYPE_LAST_WEEK: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_LAST_WEEK',
        defaultMessage: 'Last week',
    },
    APPOINTMENT_RESULT_TYPE_THIS_MONTH: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_THIS_MONTH',
        defaultMessage: 'This month',
    },
    APPOINTMENT_RESULT_TYPE_LAST_MONTH: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_LAST_MONTH',
        defaultMessage: 'Last month',
    },
    APPOINTMENT_RESULT_TYPE_THIS_YEAR: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_THIS_YEAR',
        defaultMessage: 'This year',
    },
    APPOINTMENT_RESULT_TYPE_LAST_YEAR: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_LAST_YEAR',
        defaultMessage: 'Last year',
    },
    APPOINTMENT_RESULT_TYPE_CUSTOM: {
        id: 'app.translations.shared.APPOINTMENT_RESULT_TYPE_CUSTOM',
        defaultMessage: 'Custom',
    },
    TEMPLATE_TYPE_SHORT_TEXT: {
        id: 'app.translations.shared.TEMPLATE_TYPE_SHORT_TEXT',
        defaultMessage: 'Short text',
    },
    TEMPLATE_TYPE_LONG_TEXT: {
        id: 'app.translations.shared.TEMPLATE_TYPE_LONG_TEXT',
        defaultMessage: 'Long text',
    },
    TEMPLATE_TYPE_CHECKBOX: {
        id: 'app.translations.shared.TEMPLATE_TYPE_CHECKBOX',
        defaultMessage: 'Checkbox',
    },
    TEMPLATE_TYPE_RADIO: {
        id: 'app.translations.shared.TEMPLATE_TYPE_RADIO',
        defaultMessage: 'Radio',
    },
    TEMPLATE_TYPE_SELECT: {
        id: 'app.translations.shared.TEMPLATE_TYPE_SELECT',
        defaultMessage: 'Select',
    },
    TEMPLATE_STATUS_ACTIVE: {
        id: 'app.translations.shared.TEMPLATE_STATUS_ACTIVE',
        defaultMessage: 'Active',
    },
    TEMPLATE_STATUS_INACTIVE: {
        id: 'app.translations.shared.TEMPLATE_STATUS_INACTIVE',
        defaultMessage: 'Inactive',
    },
    INVOICE_STATUS_DRAFT: {
        id: 'app.translations.shared.INVOICE_STATUS_DRAFT',
        defaultMessage: 'Draft',
    },
    INVOICE_STATUS_PAID: {
        id: 'app.translations.shared.INVOICE_STATUS_PAID',
        defaultMessage: 'Paid',
    },
    INVOICE_STATUS_OPEN: {
        id: 'app.translations.shared.INVOICE_STATUS_OPEN',
        defaultMessage: 'Open',
    },
    INVOICE_STATUS_OVERDUE: {
        id: 'app.translations.shared.INVOICE_STATUS_OVERDUE',
        defaultMessage: 'Overdue',
    },
    INVOICE_STATUS_CANCELED: {
        id: 'app.translations.shared.INVOICE_STATUS_CANCELED',
        defaultMessage: 'Canceled',
    },
    INVOICE_STATUS_SENT: {
        id: 'app.translations.shared.INVOICE_STATUS_SENT',
        defaultMessage: 'Sent',
    },
    TENANT_ROLE_ADMIN: {
        id: 'app.translations.shared.TENANT_ROLE_ADMIN',
        defaultMessage: 'Administrator',
    },
    TENANT_ROLE_STAFF: {
        id: 'app.translations.shared.TENANT_ROLE_STAFF',
        defaultMessage: 'Employee',
    },
    WEBINAR_STATUS_PAST: {
        id: 'app.translations.shared.WEBINAR_STATUS_PAST',
        defaultMessage: 'Past',
    },
    BUSINESS_CATEGORY_ANIMAL_PHYSHIOTHERAPY: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_ANIMAL_PHYSHIOTHERAPY',
        defaultMessage: 'Animal physhiotherapy',
    },
    BUSINESS_CATEGORY_BEHAVIORISM: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_BEHAVIORISM',
        defaultMessage: 'Behaviorism',
    },
    BUSINESS_CATEGORY_DOGWALKER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_DOGWALKER',
        defaultMessage: 'Dog walker',
    },
    BUSINESS_CATEGORY_GROOMER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_GROOMER',
        defaultMessage: 'Groomer',
    },
    BUSINESS_CATEGORY_OTHER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_OTHER',
        defaultMessage: 'Other',
    },
    BUSINESS_CATEGORY_PETSITTER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_PETSITTER',
        defaultMessage: 'Petsitter',
    },
    BUSINESS_CATEGORY_PHOTOGRAPHER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_PHOTOGRAPHER',
        defaultMessage: 'Photographer',
    },
    BUSINESS_CATEGORY_SHELTER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_SHELTER',
        defaultMessage: 'Shelter',
    },
    BUSINESS_CATEGORY_TRAINER: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_TRAINER',
        defaultMessage: 'Trainer',
    },
    BUSINESS_CATEGORY_VETERINARY: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_VETERINARY',
        defaultMessage: 'Veterinarian',
    },
    BUSINESS_CATEGORY_LABOLATORY: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_LABOLATORY',
        defaultMessage: 'Labolatory',
    },
    BUSINESS_CATEGORY_KENNEL: {
        id: 'app.translations.shared.BUSINESS_CATEGORY_KENNEL',
        defaultMessage: 'Kennel',
    },
    fields: {
        id: 'app.translations.shared.fields',
        defaultMessage: 'Fields',
    },
    currentVersion: {
        id: 'app.translations.shared.currentVersion',
        defaultMessage: 'Current Version',
    },
    documentTitle: {
        id: 'app.translations.shared.documentTitle',
        defaultMessage: 'Document Title',
    },
    documentCreator: {
        id: 'app.translations.shared.documentCreator',
        defaultMessage: 'Document Creator',
    },
    backToDashboard: {
        id: 'app.translations.shared.backToDashboard',
        defaultMessage: 'Back to Dashboard',
    },
    petDetails: {
        id: 'app.translations.shared.petDetails',
        defaultMessage: 'Pet Details',
    },
    chooseTemplate: {
        id: 'app.translations.shared.chooseTemplate',
        defaultMessage: 'Choose a Template',
    },
    startTyping: {
        id: 'app.translations.shared.startTyping',
        defaultMessage: 'Type...',
    },
    addAttachment: {
        id: 'app.translations.shared.addAttachment',
        defaultMessage: 'Add an Attachment',
    },
    planNotSupported: {
        id: 'app.translations.shared.planNotSupported',
        defaultMessage: 'You have to get higher plan to get access for this.',
    },
    emailNotificationHeader: {
        id: 'app.translations.shared.emailNotificationHeader',
        defaultMessage: 'Text Messages (Email)',
    },
    emailNotificationUpdate: {
        id: 'app.translations.shared.emailNotificationUpdate',
        defaultMessage: 'Email notifications has been updated',
    },
    smsNotificationHeader: {
        id: 'app.translations.shared.smsNotificationHeader',
        defaultMessage: 'Text Messages (SMS)',
    },
    smsNotificationUpdate: {
        id: 'app.translations.shared.smsNotificationUpdate',
        defaultMessage: 'SMS notifications has been updated',
    },
    invalidFile: {
        id: 'app.translations.shared.invalidFIle',
        defaultMessage: "Can't open file",
    },
    fileAdded: {
        id: 'app.translations.shared.fileAdded',
        defaultMessage: 'File has been added',
    },
    fileRemoved: {
        id: 'app.translations.shared.fileRemoved',
        defaultMessage: 'File has been removed',
    },
    fileDialogTitle: {
        id: 'app.translations.shared.fileDialogTitle',
        defaultMessage: 'Upload file',
    },
    fileDropzoneText: {
        id: 'app.translations.shared.fileDropzoneText',
        defaultMessage: 'Drag and drop a file here or click',
    },
    createdBy: {
        id: 'app.translations.shared.createdBy',
        defaultMessage: 'Created by',
    },
    search: {
        id: 'app.translations.shared.search',
        defaultMessage: 'Search',
    },
    active: {
        id: 'app.translations.shared.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'app.translations.shared.inactive',
        defaultMessage: 'Inactive',
    },
    logotype: {
        id: 'app.translations.shared.logotype',
        defaultMessage: 'Logotype',
    },
    changeLogotype: {
        id: 'app.translations.shared.changeLogotype',
        defaultMessage: 'Change logotype',
    },
    addLogotype: {
        id: 'app.translations.shared.addLogotype',
        defaultMessage: 'Add logotype',
    },
    removeLogotype: {
        id: 'app.translations.shared.removeLogotype',
        defaultMessage: 'Remove logotype',
    },
    publicProfile: {
        id: 'app.translations.shared.publicProfile',
        defaultMessage: 'Public profile',
    },
    isPublicProfile: {
        id: 'app.translations.shared.isPublicProfile',
        defaultMessage: 'Do you want to list your profile on marketplace to allow reservations for customers?',
    },
    experimentalFeatures: {
        id: 'app.translations.shared.experimentalFeatures',
        defaultMessage: 'Experimental features?',
    },
    allowTravellingServices: {
        id: 'app.translations.shared.allowTravellingServices',
        defaultMessage: 'Allow travelling services',
    },
    allowOnlineServices: {
        id: 'app.translations.shared.allowOnlineServices',
        defaultMessage: 'Allow online services',
    },
    experimentalFeaturesHelper: {
        id: 'app.translations.shared.experimentalFeaturesHelper',
        defaultMessage: 'Do you want to have access to experimental features?',
    },
    allowTravellingServicesHelper: {
        id: 'app.translations.shared.allowTravellingServicesHelper',
        defaultMessage: 'Do you agree to provide services at a location chosen by the customer?',
    },
    allowOnlineServicesHelper: {
        id: 'app.translations.shared.allowOnlineServicesHelper',
        defaultMessage: 'Do you agree to provide services online?',
    },
    defaultAppointmentStatus: {
        id: 'app.translations.shared.defaultAppointmentStatus',
        defaultMessage: 'Default appointment status',
    },
    personalInfoDescription: {
        id: 'app.translations.shared.personalInfoDescription',
        defaultMessage: 'Provide personal and contact details',
    },
    photo: {
        id: 'app.translations.shared.photo',
        defaultMessage: 'Photo',
    },
    changePhoto: {
        id: 'app.translations.shared.changePhoto',
        defaultMessage: 'Change photo',
    },
    addPhoto: {
        id: 'app.translations.shared.addPhoto',
        defaultMessage: 'Add photo',
    },
    removePhoto: {
        id: 'app.translations.shared.removePhoto',
        defaultMessage: 'Remove photo',
    },
    loginAndSecurity: {
        id: 'app.translations.shared.loginAndSecurity',
        defaultMessage: 'Login and security',
    },
    loginAndSecurityDescription: {
        id: 'app.translations.shared.loginAndSecurityDescription',
        defaultMessage: 'Update your password',
    },
    updatePasswordDescription: {
        id: 'app.translations.shared.updatePasswordDescription',
        defaultMessage: 'Update your password often for better security',
    },
    currentPassword: {
        id: 'app.translations.shared.currentPassword',
        defaultMessage: 'Current password',
    },
    newPassword: {
        id: 'app.translations.shared.newPassword',
        defaultMessage: 'New password',
    },
    confirmPassword: {
        id: 'app.translations.shared.confirmPassword',
        defaultMessage: 'Confirm password',
    },
    preferencesDescription: {
        id: 'app.translations.shared.preferencesDescription',
        defaultMessage: 'Set default language and timezone',
    },
    prefferedLanguage: {
        id: 'app.translations.shared.prefferedLanguage',
        defaultMessage: 'Preffered language',
    },
    langPL: {
        id: 'app.translations.shared.langPL',
        defaultMessage: 'Polish (PL)',
    },
    langEN: {
        id: 'app.translations.shared.langEN',
        defaultMessage: 'English (US)',
    },
    reminders: {
        id: 'app.translations.shared.reminders',
        defaultMessage: 'Reminders',
    },
    items: {
        id: 'app.translations.shared.items',
        defaultMessage: 'Items',
    },
    summary: {
        id: 'app.translations.shared.summary',
        defaultMessage: 'Summary',
    },
    invoiceNotes: {
        id: 'app.translations.shared.invoiceNotes',
        defaultMessage: 'Invoice notes',
    },
    businessProfileHelperCardHeading: {
        id: 'app.translations.shared.businessProfileHelperCardHeading',
        defaultMessage: 'Your profile',
    },
    businessProfileHelperCardDescription: {
        id: 'app.translations.shared.businessProfileHelperCardDescription',
        defaultMessage: 'Tell something about yourself so others would know how you can help them to take care of the animals.',
    },
    all: {
        id: 'app.translations.shared.all',
        defaultMessage: 'All',
    },
    upcoming: {
        id: 'app.translations.shared.upcoming',
        defaultMessage: 'Upcoming',
    },
    bookedAt: {
        id: 'app.translations.shared.bookedAt',
        defaultMessage: 'Booked At',
    },
    of: {
        id: 'app.translations.shared.of',
        defaultMessage: 'of',
    },
    results: {
        id: 'app.translations.shared.results',
        defaultMessage: 'result(s)',
    },
    createIndividualAccount: {
        id: 'app.translations.shared.create.individual.account',
        defaultMessage: 'Create individual account',
    },
    createBusinessAccount: {
        id: 'app.translations.shared.create.business.account',
        defaultMessage: 'Create business account',
    },
    chooseAccount: {
        id: 'app.translations.shared.choose.account',
        defaultMessage: 'Choose account',
    },
    emptyChooseAccount: {
        id: 'app.translations.shared.empty.choose.account',
        defaultMessage: 'No account to choose',
    },
    companyHeader: {
        id: 'app.translations.shared.companyHeader',
        defaultMessage: 'Yay, we love people who cares about pets! Give us the basics about your business.',
    },
    employeesHeader: {
        id: 'app.translations.shared.employeesHeader',
        defaultMessage: 'Do you work alone or manage your dream team? There’s just one last step ahead of you.',
    },
    invoiceProfileHeader: {
        id: 'app.translations.shared.invoiceProfileHeader',
        defaultMessage: 'Nice to have you on board {name}. Provide us a details to invoices given to your clients.',
    },
    addressCopy: {
        id: 'app.translations.shared.addressCopy',
        defaultMessage: 'Copy from previous step',
    },
    servicesHeader: {
        id: 'app.translations.shared.servicesHeader',
        defaultMessage: 'You got it there! On the last step of onboard we kindly ask to give - few details about your services.',
    },
    workingHoursHeader: {
        id: 'app.translations.shared.workingHoursHeader',
        defaultMessage: 'Share the time when you’re available during the week to meet up with our friends.',
    },
    uploadLogotype: {
        id: 'app.translations.shared.uploadLogotype',
        defaultMessage: 'Upload a logotype',
    },
    uploadImage: {
        id: 'app.translations.shared.uploadImage',
        defaultMessage: 'Upload an image',
    },
    businessCategories: {
        id: 'app.translations.shared.businessCategories',
        defaultMessage: 'Business categories',
    },
    editing: {
        id: 'app.translations.shared.editing',
        defaultMessage: 'Editing',
    },
    new: {
        id: 'app.translations.shared.new',
        defaultMessage: 'New',
    },
    addNewField: {
        id: 'app.translations.shared.addNewField',
        defaultMessage: 'Add new field',
    },
    unsupportedField: {
        id: 'app.translations.shared.unsupportedField',
        defaultMessage: 'Unsupported field',
    },
    requiredOption: {
        id: 'app.translations.shared.requiredOption',
        defaultMessage: 'Field is required',
    },
    webinars: {
        id: 'app.translations.shared.webinars',
        defaultMessage: 'Webinars',
    },
    registrations: {
        id: 'app.translations.shared.registrations',
        defaultMessage: 'Registrations',
    },
    yes: {
        id: 'app.translations.shared.yes',
        defaultMessage: 'Yes',
    },
    no: {
        id: 'app.translations.shared.no',
        defaultMessage: 'No',
    },
    free: {
        id: 'app.translations.shared.free',
        defaultMessage: 'Free',
    },
    removeWebinar: {
        id: 'app.translations.shared.removeWebinar',
        defaultMessage: 'Remove webinar',
    },
    confirmRemoveWebinar: {
        id: 'app.translations.shared.confirmRemoveWebinar',
        defaultMessage: 'Are you sure that you want to remove webinar?',
    },
    removedWebinar: {
        id: 'app.translations.shared.removedWebinar',
        defaultMessage: 'Webinar has been removed',
    },
    addWebinar: {
        id: 'app.translations.shared.addWebinar',
        defaultMessage: 'Add webinar',
    },
    newWebinar: {
        id: 'app.translations.shared.newWebinar',
        defaultMessage: 'New webinar',
    },
    eventDate: {
        id: 'app.translations.shared.eventDate',
        defaultMessage: 'Event date ',
    },
    limitInfo: {
        id: 'app.translations.shared.limitInfo',
        defaultMessage: 'For unlimited registraions provide 0',
    },
    paid: {
        id: 'app.translations.shared.paid',
        defaultMessage: 'Paid',
    },
    registrationLimit: {
        id: 'app.translations.shared.registrationLimit',
        defaultMessage: 'Registration limit',
    },
    webinarName: {
        id: 'app.translations.shared.webinarName',
        defaultMessage: 'Name',
    },
    updatedAt: {
        id: 'app.translations.shared.updatedAt',
        defaultMessage: 'Updated At',
    },
    attendants: {
        id: 'app.translations.shared.attendants',
        defaultMessage: 'Attendants',
    },
    attendanceRate: {
        id: 'app.translations.shared.attendanceRate',
        defaultMessage: 'Attendance Rate',
    },
    noLimit: {
        id: 'app.translations.shared.noLimit',
        defaultMessage: 'No limit',
    },
    noEntries: {
        id: 'app.translations.shared.noEntries',
        defaultMessage: 'No entries',
    },
    noSMSCredits: {
        id: 'app.translations.shared.noSMSCredits',
        defaultMessage: 'No SMS credit left',
    },
    fewSMSCredits: {
        id: 'app.translations.shared.fewSMSCredits',
        defaultMessage: '{count} SMS credit left',
    },
    buySMSCredits: {
        id: 'app.translations.shared.buySMSCredits',
        defaultMessage: 'Buy credits here',
    },
    buyMoreSMSCredits: {
        id: 'app.translations.shared.buyMoreSMSCredits',
        defaultMessage: 'Buy more credits here',
    },
    billTo: {
        id: 'app.translations.shared.billTo',
        defaultMessage: 'Bill to',
    },
    photos: {
        id: 'app.translations.shared.photos',
        defaultMessage: 'Photos',
    },
    cover: {
        id: 'app.translations.shared.cover',
        defaultMessage: 'Cover',
    },
    contact: {
        id: 'app.translations.shared.contact',
        defaultMessage: 'Contact',
    },
    basicDetails: {
        id: 'app.translations.shared.basicDetails',
        defaultMessage: 'Basic details',
    },
    appointmentSettings: {
        id: 'app.translations.shared.appointmentSettings',
        defaultMessage: 'Appointment settings',
    },
    customLink: {
        id: 'app.translations.shared.customLink',
        defaultMessage: 'Custom link',
    },
    appointmentRequests: {
        id: 'app.translations.shared.appointmentRequests',
        defaultMessage: 'Appointment requests',
    },
    appointmentRequestsSoon: {
        id: 'app.translations.shared.appointmentRequestsSoon',
        defaultMessage: 'Soon you will be able to accept appointment requests',
    },
    statistics: {
        id: 'app.translations.shared.statistics',
        defaultMessage: 'Statistics',
    },
    todayHours: {
        id: 'app.translations.shared.todayHours',
        defaultMessage: 'Today hours',
    },
    todayAppointments: {
        id: 'app.translations.shared.todayAppointments',
        defaultMessage: 'Today appointments',
    },
    tomorrowAppointments: {
        id: 'app.translations.shared.tomorrowAppointments',
        defaultMessage: 'Tomorrow appointments',
    },
    earnings: {
        id: 'app.translations.shared.earnings',
        defaultMessage: 'earnings',
    },
    lastMonthAppointments: {
        id: 'app.translations.shared.lastMonthAppointments',
        defaultMessage: 'Last 30 days appointments',
    },
    statisticsUpcomingAppointments: {
        id: 'app.translations.shared.statisticsUpcomingAppointments',
        defaultMessage: 'Upcoming appointments',
    },
    statisticsFinishedAppointments: {
        id: 'app.translations.shared.statisticsFinishedAppointments',
        defaultMessage: 'Finished appointments',
    },
    statisticsCanceledAppointments: {
        id: 'app.translations.shared.statisticsCanceledAppointments',
        defaultMessage: 'Canceled appointments',
    },
});
