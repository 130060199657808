var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { default as ReactSelect, components } from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sharedMessages from 'translations/shared';
var View = function (_a) {
    var _b = _a.allOption, allOption = _b === void 0 ? {
        label: 'Select all',
        value: '*',
    } : _b, props = __rest(_a, ["allOption"]);
    var theme = useTheme();
    var formatMessage = useIntl().formatMessage;
    var sharedStyles = {
        control: function (provided, state) { return (__assign(__assign({}, provided), { minHeight: 48, borderRadius: 4, cursor: 'pointer', borderColor: state.isFocused
                ? theme.palette.primary.main
                : provided.borderColor, boxShadow: state.isFocused
                ? "0 0 0 0px " + theme.palette.primary.main
                : provided.boxShadow, '&:hover': {
                borderColor: theme.palette.primary.main,
                boxShadow: state.isFocused
                    ? "0 0 0 0px " + theme.palette.primary.main
                    : provided.boxShadow,
            } })); },
        option: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isSelected ? '#fff' : provided.color, background: state.isSelected
                ? theme.palette.primary.main
                : state.isFocused
                    ? theme.palette.primary.light
                    : provided.background })); },
        valueContainer: function (provided, _state) { return (__assign(__assign({}, provided), { padding: '8px 12px' })); },
        multiValue: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: 25, backgroundColor: theme.palette.primary.main, border: 'none' })); },
        multiValueLabel: function (provided, _state) { return (__assign(__assign({}, provided), { paddingLeft: 8, color: '#fff', fontWeight: 500 })); },
        multiValueRemove: function (provided, _state) { return (__assign(__assign({}, provided), { borderTopRightRadius: 25, borderBottomRightRadius: 25, cursor: 'pointer', opacity: 0.6, marginTop: 1, color: '#fff', fontWeight: 500, '&:hover': {
                opacity: 1,
                backgroundColor: 'inherit',
                color: '#fff',
            } })); },
    };
    var MultiValueRemove = function (props) {
        return (_jsx(components.MultiValueRemove, __assign({}, props, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle }, void 0) }), void 0));
    };
    var CrossIcon = function (props) {
        return (_jsx(components.CrossIcon, __assign({}, props, { children: _jsx(FontAwesomeIcon, { icon: faTimesCircle }, void 0) }), void 0));
    };
    var sharedComponents = {
        MultiValueRemove: MultiValueRemove,
        CrossIcon: CrossIcon,
    };
    if (props.allowSelectAll) {
        return (_jsx(ReactSelect, __assign({}, props, { components: sharedComponents, options: __spreadArray([allOption], __read(props.options), false), onChange: function (selected, event) {
                if (selected !== null && selected.length > 0) {
                    if (selected[selected.length - 1].value === allOption.value) {
                        return props.onChange(__spreadArray([allOption], __read(props.options), false));
                    }
                    var result = [];
                    if (selected.length === props.options.length) {
                        var hasAllOption = selected.findIndex(function (e) { return e.value === allOption.value; });
                        if (hasAllOption) {
                            result = selected.filter(function (option) { return option.value !== allOption.value; });
                        }
                        else if (event.action === 'select-option') {
                            result = __spreadArray([allOption], __read(props.options), false);
                        }
                        return props.onChange(result);
                    }
                }
                return props.onChange(selected);
            }, styles: __assign({ multiValue: function (provided, _state) { return (__assign(__assign({}, provided), { borderRadius: 25, backgroundColor: 'transparent', border: '1px solid rgba(0, 0, 0, 0.23)', '& > div': {
                        padding: '3px 6px 3px 3px',
                    } })); }, multiValueLabel: function (provided, _state) { return (__assign(__assign({}, provided), { padding: 3 })); } }, sharedStyles), hideSelectedOptions: true, noOptionsMessage: function () { return formatMessage(sharedMessages.noOptions); } }), void 0));
    }
    return (_jsx(ReactSelect, __assign({}, props, { styles: __assign(__assign({}, props.styles), sharedStyles), components: sharedComponents, hideSelectedOptions: true, noOptionsMessage: function () { return formatMessage(sharedMessages.noOptions); } }), void 0));
};
export default View;
