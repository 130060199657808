export default (function (error) {
    var errorMessage;
    if (!error) {
        errorMessage = '';
    }
    else if (typeof error === 'string') {
        errorMessage = error;
    }
    else if (error.response && error.response.data) {
        errorMessage = error.response.data;
    }
    else if (error && error.message) {
        errorMessage = error.message;
    }
    else {
        errorMessage = JSON.stringify(error);
    }
    return errorMessage;
});
