var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, Drawer, DrawerActions, DrawerContent, DrawerHeader, } from '@petsly/shared-client';
import { useDrawer } from 'context/DrawerContext';
import sharedMessages from 'translations/shared';
import { UserRoleEnum, } from 'types/graphql';
import format from 'helpers/format';
import getDirectional from 'helpers/getDirectionalFromPhoneNumber';
import NameField from './componens/Name';
import EmailField from './componens/Email';
import PhoneField from './componens/Phone';
import RoleField from './componens/Role';
import WorkingHoursField from './componens/WorkingHours';
var getInitialPhone = function (phoneNumber) {
    if (!phoneNumber)
        return '';
    var directional = getDirectional(phoneNumber);
    return phoneNumber.split(' ').join('').replace("" + directional, '');
};
var INITIAL_WORKING_HOURS = [
    {
        day: 0,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 1,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 2,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 3,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 4,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 5,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
    {
        day: 6,
        start: '',
        end: '',
        validFrom: null,
        validThrough: null,
    },
];
var getWorkingHours = function (workingHours) {
    var entries = workingHours.map(function (e) {
        var start = new Date();
        var startHoursNumbers = __spreadArray([], __read(e.start.split(':')), false).map(function (e) {
            return Number(e);
        });
        start.setHours.apply(start, __spreadArray([], __read(startHoursNumbers), false));
        var end = new Date();
        var endHoursNumbers = __spreadArray([], __read(e.end.split(':')), false).map(function (e) { return Number(e); });
        end.setHours.apply(end, __spreadArray([], __read(endHoursNumbers), false));
        return {
            day: e.day,
            start: start,
            end: end,
        };
    });
    var days = [0, 1, 2, 3, 4, 5, 6];
    days.forEach(function (day) {
        var hasEntries = entries.find(function (e) { return e.day === day; });
        if (hasEntries)
            return;
        entries.push({
            day: day,
            start: null,
            end: null,
        });
    });
    return entries;
};
var View = function (_a) {
    var _b, _c, _d, _e;
    var isProcessing = _a.isProcessing, type = _a.type, user = _a.user, onCreate = _a.onCreate, onUpdate = _a.onUpdate;
    var closeDrawer = useDrawer().closeDrawer;
    var formatMessage = useIntl().formatMessage;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            role: yup
                .mixed()
                .oneOf(Object.values(UserRoleEnum))
                .required(formatMessage(sharedMessages.required)),
            name: yup.string().required(formatMessage(sharedMessages.required)),
            email: yup
                .string()
                .email()
                .required(formatMessage(sharedMessages.required)),
            directional: yup.string(),
            phoneNumber: yup.string().nullable(),
            workingHours: yup
                .array(yup.object().shape({
                day: yup.number(),
                start: yup.string().nullable(),
                end: yup.string().nullable(),
            }))
                .default([])
                .required(formatMessage(sharedMessages.required)),
        });
    }, []);
    var formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        isInitialValid: true,
        initialValues: {
            name: (_b = user === null || user === void 0 ? void 0 : user.name) !== null && _b !== void 0 ? _b : '',
            email: (_d = (_c = user === null || user === void 0 ? void 0 : user.user) === null || _c === void 0 ? void 0 : _c.email) !== null && _d !== void 0 ? _d : '',
            phoneNumber: getInitialPhone(user === null || user === void 0 ? void 0 : user.phoneNumber),
            directional: getDirectional(user === null || user === void 0 ? void 0 : user.phoneNumber),
            role: (_e = user === null || user === void 0 ? void 0 : user.role) !== null && _e !== void 0 ? _e : UserRoleEnum.Staff,
            workingHours: INITIAL_WORKING_HOURS,
        },
        onSubmit: function (values) {
            if (type === 'add') {
                var input = __assign({}, values);
                input.phoneNumber =
                    values.phoneNumber && values.phoneNumber.length > 0
                        ? values.directional + " " + values.phoneNumber
                        : null;
                input.workingHours = values.workingHours.map(function (entry) { return ({
                    day: entry.day,
                    start: entry.start,
                    end: entry.end,
                }); });
                delete input.directional;
                onCreate(input);
            }
            else {
                var email = values.email, phoneNumber = values.phoneNumber, directional = values.directional, rest = __rest(values, ["email", "phoneNumber", "directional"]);
                var input = __assign({ id: user.id }, rest);
                input.phoneNumber =
                    phoneNumber && phoneNumber.length > 0
                        ? directional + " " + phoneNumber
                        : null;
                input.workingHours = values.workingHours
                    .filter(function (e) { return e.start && e.end; })
                    .map(function (entry) { return ({
                    day: entry.day,
                    start: format(entry.start, 'HH:mm'),
                    end: format(entry.end, 'HH:mm'),
                }); });
                onUpdate(input);
            }
        },
    });
    var submitForm = formik.submitForm, setFieldValue = formik.setFieldValue, setFieldTouched = formik.setFieldTouched, touched = formik.touched, errors = formik.errors, values = formik.values;
    useEffect(function () {
        if (!user || !user.workingHours.length)
            return;
        var workingHours = getWorkingHours(user.workingHours);
        setFieldValue('workingHours', workingHours);
    }, [user]);
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: closeDrawer }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(type === 'add'
                    ? sharedMessages.addEmployee
                    : sharedMessages.editEmployee) }), void 0), _jsxs(DrawerContent, { children: [_jsx(NameField, { value: values.name, onChange: function (name) {
                            setFieldValue('name', name);
                        }, onBlur: function () {
                            setFieldTouched('name');
                        }, touched: touched.name, error: errors.name }, void 0), _jsx(EmailField, { value: values.email, onChange: function (email) {
                            setFieldValue('email', email);
                        }, onBlur: function () {
                            setFieldTouched('email');
                        }, touched: touched.email, error: errors.email, disabled: type === 'edit' }, void 0), _jsx(PhoneField, { phoneNumber: values.phoneNumber, directional: values.directional, onChange: function (payload) {
                            if (payload.hasOwnProperty('directional')) {
                                setFieldValue('directional', payload.directional);
                            }
                            if (payload.hasOwnProperty('phoneNumber')) {
                                setFieldValue('phoneNumber', payload.phoneNumber);
                            }
                        }, onBlur: function () {
                            setFieldTouched('phoneNumber');
                        }, touched: touched.phoneNumber, error: errors.phoneNumber }, void 0), _jsx(RoleField, { value: values.role, onChange: function (role) {
                            setFieldValue('role', role);
                        }, onBlur: function () {
                            setFieldTouched('role');
                        }, touched: touched.role, error: errors.role }, void 0), _jsx(WorkingHoursField, { values: values.workingHours, onChange: function (key, value) {
                            setFieldValue(key, value);
                        }, onBlur: function (key) {
                            setFieldTouched(key);
                        }, push: function (day) {
                            return setFieldValue('workingHours', __spreadArray(__spreadArray([], __read(values.workingHours), false), [
                                { day: day, start: '', end: '' },
                            ], false));
                        }, remove: function (idx) {
                            setFieldValue('workingHours', values.workingHours.filter(function (_, index) { return index !== idx; }));
                        }, touched: touched, errors: errors }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: isProcessing
                        ? formatMessage(sharedMessages.loading)
                        : formatMessage(sharedMessages.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
