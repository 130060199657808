var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, SingleSelect, } from '@petsly/shared-client';
import formatDuration from 'helpers/formatDuration';
var DurationField = function (_a) {
    var value = _a.value, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    var timeValues = __spreadArray([], __read(Array(32).keys()), false).map(function (x) { return (x + 1) * 15; });
    var pushed = timeValues.push(1440);
    var durationTime = useMemo(function () {
        return timeValues.map(function (duration) { return (_jsx(_Fragment, { children: duration !== 1440 ? (_jsx("option", __assign({ value: duration }, { children: formatDuration({
                    hours: Math.floor(duration / 60),
                    minutes: duration % 60,
                }) }), duration)) : (_jsx("option", __assign({ value: 1440 }, { children: formatMessage(messages.wholeDay) }), 1440)) }, void 0)); });
    }, []);
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.duration) }, void 0) }, void 0), _jsx(FormGroupContent, { children: _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsx(SingleSelect, __assign({ name: "duration", value: value, onChange: function (ev) {
                                onChange(ev.currentTarget.value);
                            }, onBlur: function () {
                                onBlur();
                            }, error: touched && !isNil(error) }, { children: durationTime }), void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0) }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.value === nextProps.value &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(DurationField, shouldRerender);
