var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, Drawer, DrawerHeader, DrawerContent, DrawerActions, } from '@petsly/shared-client';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { add } from 'date-fns';
import { useDrawer } from 'context/DrawerContext';
import { AppointmentStatusEnum, AppointmentTypeEnum, } from 'types/graphql';
import translations from 'translations/shared';
import TypeField from './components/Type';
import NameField from './components/Name';
import ParticipantsField from './components/Participants';
import LocationField from './components/Location';
import ServicesField from './components/Services';
import DateField from './components/Date';
import NotesField from './components/Notes';
import SharableNotesField from './components/SharableNotes';
var View = function (_a) {
    var clientId = _a.clientId, petId = _a.petId, employeeId = _a.employeeId, startDate = _a.startDate, clients = _a.clients, pets = _a.pets, locations = _a.locations, employees = _a.employees, services = _a.services, isProcessing = _a.isProcessing, onAdd = _a.onAdd, refetch = _a.refetch;
    var formatMessage = useIntl().formatMessage;
    var closeDrawer = useDrawer().closeDrawer;
    var validationSchema = useMemo(function () {
        return yup.object().shape({
            participants: yup
                .array()
                .of(yup.object().shape({
                clientId: yup
                    .string()
                    .required(formatMessage(translations.required)),
                petId: yup
                    .string()
                    .required(formatMessage(translations.required)),
            }))
                .min(1)
                .required(formatMessage(translations.required)),
            type: yup.mixed().required(formatMessage(translations.required)),
            locationId: yup.string().nullable(),
            isCustomLocation: yup.bool().nullable(),
            notes: yup.string().nullable(),
            sharableNotes: yup.string().nullable(),
            name: yup.string().nullable(),
            date: yup.mixed().required(formatMessage(translations.required)),
            services: yup
                .array()
                .of(yup.object().shape({
                employeeId: yup
                    .string()
                    .required(formatMessage(translations.required)),
                serviceId: yup
                    .string()
                    .required(formatMessage(translations.required)),
            }))
                .min(1, formatMessage(translations.minItems, { count: 1 }))
                .required(),
            customLocation: yup
                .object()
                .shape({
                line1: yup.string(),
                line2: yup.string(),
                city: yup.string(),
                state: yup.string(),
                country: yup.string(),
                postalCode: yup.string(),
            })
                .nullable(),
        });
    }, []);
    var initialValues = {
        participants: [
            {
                clientId: '',
                petId: '',
            },
        ],
        locationId: '',
        services: [
            {
                employeeId: '',
                serviceId: '',
            },
        ],
        notes: '',
        sharableNotes: '',
        isCustomLocation: false,
        date: null,
        type: AppointmentTypeEnum.IndividualClient,
        status: AppointmentStatusEnum.Confirmed,
        customLocation: {
            line1: undefined,
            line2: undefined,
            city: undefined,
            state: undefined,
            postalCode: undefined,
            country: 'PL',
        },
    };
    var formik = useFormik({
        validationSchema: validationSchema,
        initialValues: initialValues,
        onSubmit: function (values) {
            var date = values.date;
            var servicesDuration = values.services.reduce(function (acc, _a) {
                var _b, _c;
                var serviceId = _a.serviceId;
                var duration = (_c = (_b = services.find(function (s) { return s.id === serviceId; })) === null || _b === void 0 ? void 0 : _b.duration) !== null && _c !== void 0 ? _c : 0;
                return acc + duration;
            }, 0);
            var startAt = values.date;
            var endAt = add(values.date, { minutes: servicesDuration });
            var locationId = values.locationId && values.locationId.length > 1
                ? values.locationId
                : null;
            var appointment = {
                startAt: startAt,
                endAt: endAt,
                locationId: locationId,
                participants: values.participants,
                type: values.type,
                notes: values.notes,
                sharableNotes: values.sharableNotes,
                isCustomLocation: values.isCustomLocation,
                services: values.services,
            };
            var createAddress = __assign({}, values.customLocation);
            onAdd(appointment, createAddress);
        },
    });
    var setFieldValue = formik.setFieldValue, setValues = formik.setValues, setFieldTouched = formik.setFieldTouched, submitForm = formik.submitForm, values = formik.values, errors = formik.errors, touched = formik.touched;
    useEffect(function () {
        var _a;
        if (locations) {
            var defaultLocation = locations.find(function (l) { return l.isDefaultLocation; });
            if (defaultLocation) {
                initialValues.locationId = defaultLocation.id;
            }
        }
        if (clients && (clientId || petId)) {
            if (clientId) {
                initialValues.participants[0] = {
                    clientId: clientId,
                    petId: '',
                };
            }
            if (clients && petId) {
                var client = clients.find(function (c) { return c.pets.findIndex(function (p) { return p.id === petId; }) >= 0; });
                initialValues.participants[0] = {
                    clientId: (_a = client === null || client === void 0 ? void 0 : client.id) !== null && _a !== void 0 ? _a : '',
                    petId: petId,
                };
            }
        }
        if (startDate) {
            initialValues.date = startDate;
        }
        if (employeeId) {
            initialValues.services[0] = {
                employeeId: employeeId,
                serviceId: '',
            };
        }
        setValues(initialValues);
    }, [locations, clients, employees, setValues]);
    return (_jsxs(Drawer, __assign({ open: true, onDismiss: function () {
            closeDrawer();
        } }, { children: [_jsx(DrawerHeader, __assign({ onClose: closeDrawer }, { children: formatMessage(translations.addAppointment) }), void 0), _jsxs(DrawerContent, { children: [_jsx(TypeField, { value: values.type, touched: touched.type, error: errors.type, onBlur: function () { return setFieldTouched('type'); }, onChange: function (value) {
                            return setFieldValue('type', value);
                        } }, void 0), values.type === AppointmentTypeEnum.GroupClient && (_jsx(NameField, { value: values.name, touched: touched.name, error: errors.name, onBlur: function () { return setFieldTouched('name'); }, onChange: function (value) { return setFieldValue('name', value); } }, void 0)), _jsx(ParticipantsField, { pets: pets, clients: clients, type: values.type, values: values.participants, errors: errors.participants, touched: touched.participants, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); }, push: function () {
                            return setFieldValue('participants', __spreadArray(__spreadArray([], __read(values.participants), false), [
                                { clientId: '', petId: '' },
                            ], false));
                        }, remove: function (idx) {
                            setFieldValue('participants', values.participants.filter(function (_, index) { return index !== idx; }));
                        }, refetch: refetch }, void 0), _jsx(LocationField, { locations: locations, value: values.locationId, isCustom: values.isCustomLocation, customLocation: values.customLocation, errors: {
                            locationId: errors.locationId,
                            isCustomLocation: errors.isCustomLocation,
                            customLocation: errors.customLocation,
                        }, touched: {
                            locationId: touched.locationId,
                            isCustomLocation: touched.isCustomLocation,
                            customLocation: touched.customLocation,
                        }, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); } }, void 0), _jsx(ServicesField, { services: services, employees: employees, values: values.services, errors: errors.services, touched: touched.services, onChange: function (key, value) { return setFieldValue(key, value); }, onBlur: function (key) { return setFieldTouched(key); }, push: function () {
                            return setFieldValue('services', __spreadArray(__spreadArray([], __read(values.services), false), [
                                { serviceId: '', employeeId: '' },
                            ], false));
                        }, remove: function (idx) {
                            setFieldValue('services', values.services.filter(function (_, index) { return index !== idx; }));
                        } }, void 0), _jsx(DateField, { value: values.date, touched: touched.date, error: errors.date, onBlur: function () { return setFieldTouched('date'); }, onChange: function (value) { return setFieldValue('date', value); } }, void 0), _jsx(SharableNotesField, { value: values.sharableNotes, touched: touched.sharableNotes, error: errors.sharableNotes, onBlur: function () { return setFieldTouched('sharableNotes'); }, onChange: function (value) { return setFieldValue('sharableNotes', value); } }, void 0), _jsx(NotesField, { value: values.notes, touched: touched.notes, error: errors.notes, onBlur: function () { return setFieldTouched('notes'); }, onChange: function (value) { return setFieldValue('notes', value); } }, void 0)] }, void 0), _jsx(DrawerActions, { children: _jsx(Button, __assign({ onClick: submitForm, disabled: isProcessing, fullWidth: true }, { children: formatMessage(translations.save) }), void 0) }, void 0)] }), void 0));
};
export default View;
