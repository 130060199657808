var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import messages from 'translations/shared';
import { FormGroup, FormSubHeading, FormGroupHeader, FormGroupContent, FormHelperText, Input, PhoneNumberDirectional, } from '@petsly/shared-client';
var View = function (_a) {
    var phoneNumber = _a.phoneNumber, directional = _a.directional, error = _a.error, touched = _a.touched, onChange = _a.onChange, onBlur = _a.onBlur;
    var formatMessage = useIntl().formatMessage;
    return (_jsxs(FormGroup, __assign({ border: false }, { children: [_jsx(FormGroupHeader, { children: _jsx(FormSubHeading, { children: formatMessage(messages.phone) }, void 0) }, void 0), _jsxs(FormGroupContent, { children: [_jsx("div", __assign({ style: { marginBottom: 16 } }, { children: _jsx(PhoneNumberDirectional, { name: "directional", variant: "large", value: directional, defaultValue: "+48", onChange: function (_a) {
                                var value = _a.value;
                                onChange({ directional: value });
                            }, language: window.__localeId__ === 'pl' ? 'pl' : 'en', placeholder: formatMessage(messages.select) }, void 0) }), void 0), _jsxs("div", __assign({ style: { marginBottom: 16 } }, { children: [_jsx(PhoneWrapper, { children: _jsx(Input, { name: "phoneNumber", value: phoneNumber !== null && phoneNumber !== void 0 ? phoneNumber : '', onChange: function (ev) {
                                        onChange({ phoneNumber: ev.currentTarget.value });
                                    }, onBlur: function () {
                                        onBlur();
                                    }, error: touched && !isNil(error) }, void 0) }, void 0), touched && !isNil(error) && (_jsx(FormHelperText, __assign({ error: true }, { children: error }), void 0))] }), void 0)] }, void 0)] }), void 0));
};
var shouldRerender = function (prevProps, nextProps) {
    return (prevProps.phoneNumber === nextProps.phoneNumber &&
        prevProps.directional === nextProps.directional &&
        prevProps.touched === nextProps.touched &&
        prevProps.error === nextProps.error);
};
export default memo(View, shouldRerender);
var PhoneWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  input[type='number'] {\n    -moz-appearance: textfield;\n  }\n"], ["\n  input::-webkit-outer-spin-button,\n  input::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  input[type='number'] {\n    -moz-appearance: textfield;\n  }\n"])));
var templateObject_1;
